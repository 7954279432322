import { useMemo } from "react";
import { useDpConfig } from "../contexts/dpConfig";
import { LoanProductConfigFlags } from "../contexts/dpConfig/types";
import { LoanProductType } from "./api/types";

export const useLoanProductConfigFlags = (
  loanType?: LoanProductType
): LoanProductConfigFlags & {
  shouldHideFinancialInformation: boolean | undefined;
} => {
  const {
    state: { products },
  } = useDpConfig();

  const loanProductConfig = useMemo(() => {
    console.log("Fetching loan product config for loan type:", loanType);
    const productConfig =
      products.find((product) => product.type === loanType)?.config ??
      products[0].config;

    return productConfig;
  }, [loanType, products]);

  const {
    directDebitRequired,
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
    hideInterestPaymentOnOffer,
    disableSalesStatementsUpload,
    disableBankStatementsUpload,
    disableVATStatementsUpload,
    disableRevenueHistory,
    isPartnerTermsAccepted,
    disbursementAccountProvided,
    showMerchantIdStep,
    enableVendorScreen,
  } = loanProductConfig || {};

  const shouldHideFinancialInformation =
    disableBankStatementsUpload &&
    disableVATStatementsUpload &&
    disableSalesStatementsUpload &&
    !disbursementAccountProvided;

  return {
    directDebitRequired,
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
    hideInterestPaymentOnOffer,
    disableSalesStatementsUpload,
    disableBankStatementsUpload,
    disableVATStatementsUpload,
    disableRevenueHistory,
    isPartnerTermsAccepted,
    disbursementAccountProvided,
    shouldHideFinancialInformation,
    showMerchantIdStep,
    enableVendorScreen,
  };
};
