import {
  FileObject,
  TRADE_LICENSE_CONFIG,
  MOA_CONFIG,
  POA_CONFIG,
} from "./service";
import { CardVariantType } from "../../../components/CardList/CardList";
import { CardList, Dropzone } from "../../../components";
import { DocumentIcon, PdfIcon } from "../../../assets/icons";
import { MediaPurpose } from "../../../hooks/api/types";
import { formatBytes } from "../../../utils";

type Props = {
  label: string;
  setDocuments: (docs: FileObject[]) => void;
  documents: FileObject[];
  handleFileClose: (fileName: string, docId?: string) => void;
  uploadedDocs?: string[];
  type: MediaPurpose;
};

export const UploadMultipleDocs: React.FC<Props> = ({
  label,
  documents,
  setDocuments,
  handleFileClose,
  type,
}) => {
  let config;
  if (type === MediaPurpose.TRADE_LICENSE) {
    config = MOA_CONFIG;
  } else if (type === MediaPurpose.POWER_OF_ATTORNEY) {
    config = POA_CONFIG;
  } else {
    config = TRADE_LICENSE_CONFIG; // Default configuration
  }

  const handleUpload = async (files: File[]) => {
    if (!files) return;

    const updatedDocs = [...documents];

    files.forEach((file: File) => {
      let isFileAlreadyAdded = updatedDocs.some(
        (existingFile) => existingFile.file.name === file.name
      );
      if (!isFileAlreadyAdded) {
        updatedDocs.push({ id: "", file });
      }
    });

    setDocuments([...updatedDocs]);
  };
  return (
    <>
      <div className="cx-bg-white cx-border cx-border-gray-300 cx-rounded-lg cx-px-4 cx-py-4">
        <div className="cx-flex items-center">
          <div className="cx-w-6 cx-h-6 cx-shrink-0 cx-text-brand-primary-regular">
            <DocumentIcon width="100%" height="100%" />
          </div>
          <p className="cx-text-lg cx-pl-2">{label}</p>
        </div>

        <div className="cx-mt-4">
          <Dropzone
            variant="simple"
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: config.accept,
              maxFiles: config.maxFiles,
              maxSize: config.maxSize, // 100MB to byte
            }}
            onDrop={async (files) => {
              if (!files) return;
              handleUpload(files);
            }}
          />
        </div>

        <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
          {documents.map((st, index: number) => (
            <div className="cx-mb-2" key={index}>
              <CardList
                variant={CardVariantType.UPLOADED}
                title={st.file.name || type}
                fullWidth
                icon={
                  <PdfIcon
                    width="100%"
                    height="100%"
                    className="cx-text-brand-primary-regular"
                  />
                }
                inputProps={{ accept: ".pdf,application/pdf" }}
                truncateTitleLength={35}
                fileNewDesign={true}
                handleFileClose={handleFileClose}
                description={st?.file?.size && formatBytes(st.file.size, 2)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UploadMultipleDocs;
