import { useEffect, useState } from "react";
import {
  Applicant,
  CompanyShareholder,
  IndividualShareholder,
  useLoanApplication,
} from "../../../../contexts";
import { Button } from "../../../../components";
import { PlusIcon } from "../../../../assets/icons";
import ShareholderInfo from "../ShareholderInfo";
import ShareholderModal from "../../OwnersInformation/ShareholderModal";
import { ShareholderFields } from "../../OwnersInformation/service";
import {
  useDeleteCompanyShareholder,
  useDeleteIndividualShareholder,
} from "../../../../hooks";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {
  CompanyShareholderType,
  ShareholderEntityType,
} from "../../../../hooks/api/types";
import ApplicantInformationModal from "../../OwnersInformation/ApplicantInformationModal";
import { SumSubKycModal } from "../../OwnersInformation";
import ShareHolderDialogModal from "../ShareHolderDialogModal";

const OwnersInformationReviewSection = () => {
  const [shareholderModalOpen, setShareholderModalOpen] = useState(false);
  const [applicantInformationModalOpen, setApplicantInformationModalOpen] =
    useState(false);

  const [kycModalOpen, setKycModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [shareHolderMessageModal, setShareHolderMessageModal] = useState(false);
  const [currentShareholder, setCurrentShareholder] = useState<
    IndividualShareholder | CompanyShareholder | null
  >(null);

  const {
    state: { applicant, individualShareholders, companyShareholders },
    actions: { update, refetch },
  } = useLoanApplication();
  const { mutateAsync: deleteIndividualShareholder } =
    useDeleteIndividualShareholder();
  const { mutateAsync: deleteCompanyShareholder } =
    useDeleteCompanyShareholder();

  useEffect(() => {
    if (!individualShareholders?.some((s) => s.hasPOA)) {
      setShareHolderMessageModal(true);
    }
  }, [individualShareholders]);

  const handleDeleteIndividualShareholder = async (id: string) => {
    await deleteIndividualShareholder(id);
    // Locally update the state after deleting from the API
    await update(
      {
        individualShareholders: individualShareholders.filter(
          (shareholder) => shareholder.id !== id
        ),
      },
      { local: true }
    );
  };

  const handleDeleteCompanyShareholder = async (id: string) => {
    await deleteCompanyShareholder(id);
    // Locally update the state after deleting from the API
    await update(
      {
        companyShareholders: companyShareholders.filter(
          (shareholder) => shareholder.id !== id
        ),
      },
      { local: true }
    );
  };

  const handleApplicantUpdate = async (values: Applicant) => {
    if (
      ((applicant.firstName !== values.firstName ||
        applicant.lastName !== values.lastName) &&
        (applicant.isStakeholder || applicant.hasPOA)) ||
      (!applicant.isStakeholder && values.isStakeholder) ||
      (!applicant.hasPOA && values.hasPOA)
    ) {
      await refetch();
      setKycModalOpen(true);
    }
    setApplicantInformationModalOpen(false);
  };

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Shareholder Information</div>
        <Button
          label={
            <div className="cx-flex cx-items-center">
              <PlusIcon className="cx-mr-2" />
              <div>Add shareholder</div>
            </div>
          }
          slim={true}
          onClick={() => {
            setCurrentShareholder(null);
            setShareholderModalOpen(true);
          }}
        />
      </div>
      <div
        className="cx-w-full cx-grid cx-gap-x-4"
        style={{
          gridTemplateColumns: `repeat(${Math.min(
            individualShareholders.length + companyShareholders.length,
            4
          )}, 1fr)`,
        }}
      >
        {applicant && (
          <div className="cx-col-span-1">
            <ShareholderInfo
              title="Your information"
              name={`${applicant.firstName} ${applicant.lastName}`}
              email={applicant.email}
              phoneNumber={`${applicant.countryCode}${applicant.localNumber}`}
              onEdit={() => {
                setApplicantInformationModalOpen(true);
              }}
            />
          </div>
        )}
        {individualShareholders
          .filter(
            (shareholder) =>
              shareholder.type === CompanyShareholderType.SHAREHOLDER
          )
          .map((shareholder, idx) => (
            <div key={shareholder.email} className="cx-col-span-1">
              <ShareholderInfo
                title={`Shareholder ${idx + 1}`}
                name={`${shareholder.firstName} ${shareholder.lastName}`}
                email={shareholder.email}
                phoneNumber={`${shareholder.countryCode}${shareholder.localNumber}`}
                onEdit={() => {
                  setCurrentShareholder(shareholder);
                  setShareholderModalOpen(true);
                }}
                onDelete={() => {
                  setCurrentShareholder(shareholder);
                  setConfirmationModal(true);
                }}
              />
            </div>
          ))}
        {companyShareholders.map((shareholder, idx) => (
          <div key={shareholder.email} className="cx-col-span-1">
            <ShareholderInfo
              title={`Shareholder ${individualShareholders.length + idx + 1}`}
              name={shareholder.name}
              email={shareholder.email}
              phoneNumber={`${shareholder.countryCode}${shareholder.localNumber}`}
              onEdit={() => {
                setCurrentShareholder(shareholder);
                setShareholderModalOpen(true);
              }}
              onDelete={() => {
                setCurrentShareholder(shareholder);
                setConfirmationModal(true);
              }}
            />
          </div>
        ))}
      </div>

      <ShareholderModal
        isOpen={shareholderModalOpen}
        onClose={() => setShareholderModalOpen(false)}
        onCancel={() => setShareholderModalOpen(false)}
        onSubmit={() => setShareholderModalOpen(false)}
        shareholder={currentShareholder as ShareholderFields}
      />
      <ApplicantInformationModal
        isOpen={applicantInformationModalOpen}
        onClose={() => setApplicantInformationModalOpen(false)}
        onCancel={() => setApplicantInformationModalOpen(false)}
        onSubmit={handleApplicantUpdate}
      />
      <SumSubKycModal
        isOpen={kycModalOpen}
        onClose={() => setKycModalOpen(false)}
        onSubmit={() => setKycModalOpen(false)}
      />

      <DeleteConfirmationModal
        isOpen={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onSubmit={() => {
          if (!currentShareholder) return;

          if (
            currentShareholder?.__entityType ===
            ShareholderEntityType.INDIVIDUAL
          ) {
            handleDeleteIndividualShareholder(currentShareholder.id!);
          } else {
            handleDeleteCompanyShareholder(currentShareholder.id!);
          }
          setConfirmationModal(false);
        }}
      />
      <ShareHolderDialogModal
        isOpen={shareHolderMessageModal}
        onClose={() => setShareHolderMessageModal(false)}
      />
    </div>
  );
};

export default OwnersInformationReviewSection;
