import { useContext } from "react";
import { useFormik } from "formik";
import { StepperContext, useLoanOffer } from "../../contexts";
import { Combobox, InputField, StepNextButton } from "../../components";
import {
  SelectBankAccountFields,
  SELECT_BANK_ACCOUNT_INITIAL_VALUES,
  ADD_ACCOUNT_VALIDATION_SCHEMA,
} from "./service";
import { banks } from "../../constants/banks.json";
import {
  useSaveDirectDebitBankAccount,
  useUpdateLoanOfferStage,
} from "../../hooks";
import {
  DirectDebitBankAccountSourceType,
  LoanOfferStage,
} from "../../hooks/api/types";
import { getCopyText } from "./constants";

const LoanOfferAddAccount = () => {
  const { nextStep, gotoStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanOffer();
  const isDirectDebitRequired = state.loanConfig?.directDebitRequired;

  const { mutateAsync: saveBankAccount, isLoading: savingBankAccount } =
    useSaveDirectDebitBankAccount();
  const { mutateAsync: updateStage } = useUpdateLoanOfferStage();

  const bankOptions = banks.map((e, index) => ({
    value: `${index + 1}`,
    label: e,
  }));

  const saveBankAccountDetails = () => {
    const bankName = banks[Number(values.bankName) - 1];
    return saveBankAccount({
      bankName: bankName,
      iban: values.bankIban,
      accountId: "",
      accountName: "",
      loanOfferId: state.id,
      sourceType: DirectDebitBankAccountSourceType.MANUAL,
    });
  };

  const updateLoanOfferStage = () => {
    return updateStage({
      id: state.id,
      stage: isDirectDebitRequired
        ? LoanOfferStage.SELECT_SIGN_MODE
        : LoanOfferStage.COMPLETED,
    });
  };

  const handleFinalSteps = () => {
    isDirectDebitRequired ? nextStep() : gotoStep([3, 0]);
  };

  const handleError = (err: any) => {
    console.log(err);
    setFieldError("bankIban", err?.message || "Something went wrong");
  };

  const { values, touched, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<SelectBankAccountFields>({
      initialValues: {
        ...SELECT_BANK_ACCOUNT_INITIAL_VALUES,
        bankName: state.bankName,
        bankIban: state.bankIban || "AE",
      },
      validationSchema: ADD_ACCOUNT_VALIDATION_SCHEMA,
      onSubmit: (values) => {
        update({ bankName: values.bankName, bankIban: values.bankIban })
          .then(() => saveBankAccountDetails())
          .then(() => updateLoanOfferStage())
          .then(() => handleFinalSteps())
          .catch(handleError);
      },
    });

  return (
    <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        {getCopyText(
          "ADD_ACCOUNT",
          "directDebitRequired",
          !isDirectDebitRequired
        )!()}
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        {getCopyText(
          "LOAN_TRANSFER_ADD",
          "directDebitRequired",
          !isDirectDebitRequired
        )!()}
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-py-10">
            <Combobox
              placeholder="Bank name"
              options={bankOptions}
              inputProps={{
                name: "bankName",
                value: bankOptions.find((e) => e.label === values.bankName),
              }}
              onChange={handleChange("bankName")}
              error={
                touched.bankName && !!errors.bankName ? errors.bankName : ""
              }
            />
            <div className="cx-pt-4">
              <InputField
                inputProps={{
                  name: "bankIban",
                  placeholder: "IBAN",
                  value: values.bankIban,
                  onChange: handleChange,
                }}
                error={
                  touched.bankIban && !!errors.bankIban ? errors.bankIban : ""
                }
              />
            </div>
          </div>
          <StepNextButton
            label="Next"
            loading={savingBankAccount}
            disabled={savingBankAccount}
          />
        </form>
      </div>
    </div>
  );
};

export default LoanOfferAddAccount;
