import { LoanApplicationStepper } from "../LoanApplicationSteps/Stepper";
import LoanApplicationFlowFooter from "./Footer";
import LoanApplicationForm from "./Form";
import LoanApplicationFlowHeader from "./Header";
import { useDpConfig } from "../../contexts/dpConfig";

const LoanApplicationFlow = () => {
  const {
    state: { settings },
  } = useDpConfig();
  return (
    <LoanApplicationStepper>
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full cx-h-full cx-min-h-screen">
        <LoanApplicationFlowHeader />
        <LoanApplicationForm />
        {!settings.hideFooter && <LoanApplicationFlowFooter />}
      </div>
    </LoanApplicationStepper>
  );
};

export { LoanApplicationFlow };
