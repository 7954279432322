import { useContext, useEffect, useState } from "react";
import { StepNextButton, StepPrevButton } from "../../../components";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { useDeleteMedia, useMedia } from "../../../hooks";
import {
  LoanApplicationStage,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import { FileObject } from "./service";
import { ArrowLeftIcon } from "../../../assets/icons";
import { useDpConfig } from "../../../contexts/dpConfig";
import UploadMultipleDocs from "./UploadMultipleDocs";
import { CustomFile } from "../FinancialInformation/service";

interface DocumentsUploadProps {
  loanApplicationId?: string;
  onSubmit?: (vatStatementIds: string[]) => void;
}
const DocumentsUpload: React.FC<DocumentsUploadProps> = ({ onSubmit }) => {
  const { upload, get } = useMedia();
  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();
  const { previousStep, nextStep } = useContext(StepperContext);

  const [uploading, setUploading] = useState(false);
  const [tradeLicensesDoc, setTradeLicenseDoc] = useState<FileObject[]>([]);
  const [moaDocs, setMoaDocs] = useState<FileObject[]>([]);
  const [poaDocs, setPoaDocs] = useState<FileObject[]>([]);
  const [docsToDelete, setDocsToDelete] = useState<string[]>([]);

  useEffect(() => {
    if (state && state.docTradeLicenses.length > 0) {
      getMediaFromDocumentIds(state.docTradeLicenses, setTradeLicenseDoc);
    }

    if (state && state.docMOAs.length > 0) {
      getMediaFromDocumentIds(state.docMOAs, setMoaDocs);
    }

    if (state && state.docPOAs.length > 0) {
      getMediaFromDocumentIds(state.docPOAs, setPoaDocs);
    }
  }, []);

  const handleSubmit = async () => {
    const docToDeletePromises = docsToDelete.map((docId) => {
      return deleteMediaById(docId);
    });
    const tradeLicensesUploadPromises = tradeLicensesDoc.map(async (doc) => {
      if (doc.uploaded) return doc.id;

      const id = await upload({
        file: doc.file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.TRADE_LICENSE,
        loanApplicationId: state.id,
      });
      doc.id = id;
      return id;
    });

    const moaDocsUploadPromises = moaDocs.map(async (doc) => {
      if (doc.uploaded) return doc.id;
      const id = await upload({
        file: doc.file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.MEMORANDUM_OF_ASSOCIATION,
        loanApplicationId: state.id,
      });
      doc.id = id;
      return id;
    });

    const poaDocsUploadPromises = poaDocs.map(async (doc) => {
      if (doc.uploaded) return doc.id;
      const id = await upload({
        file: doc.file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.POWER_OF_ATTORNEY,
        loanApplicationId: state.id,
      });
      doc.id = id;
      return id;
    });

    await Promise.all([
      ...tradeLicensesUploadPromises,
      ...moaDocsUploadPromises,
      ...poaDocsUploadPromises,
      ...docToDeletePromises,
    ]);

    await update({
      stage: disableLOCFlow
        ? LoanApplicationStage.OWNER_INFORMATION
        : LoanApplicationStage.CALCULATOR,
    });

    nextStep();
    setUploading(false);
  };

  const handleFileClose = async (fileName: string, docId?: string) => {
    const removedDoc = tradeLicensesDoc.find(
      (fileObj) => fileObj.file.name === fileName
    );

    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete([...docsToDelete, removedDoc.id]);
    }

    const updatedList = tradeLicensesDoc.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setTradeLicenseDoc(updatedList);
  };

  const handleFileCloseMOA = async (fileName: string, docId?: string) => {
    const removedDoc = moaDocs.find(
      (fileObj) => fileObj.file.name === fileName
    );
    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete([...docsToDelete, removedDoc.id]);
    }
    const updatedList = moaDocs.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setMoaDocs(updatedList);
  };

  const handleFileClosePOA = async (fileName: string, docId?: string) => {
    const removedDoc = poaDocs.find(
      (fileObj) => fileObj.file.name === fileName
    );
    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete([...docsToDelete, removedDoc.id]);
    }
    const updatedList = poaDocs.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setPoaDocs(updatedList);
  };

  const getMediaFromDocumentIds = (
    documentIds: string[],
    setDocs: React.Dispatch<React.SetStateAction<FileObject[]>>
  ) => {
    const retreivedDocs: FileObject[] = [];

    documentIds.forEach((documentId) => {
      get({ id: documentId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedDocs.push({ id: res.media.id, file, uploaded: true });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDocs(retreivedDocs);
        });
    });
  };

  return (
    <div>
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-pb-10">
        Upload the following documents
      </div>
      <div className="cx-space-y-4">
        <UploadMultipleDocs
          type={MediaPurpose.TRADE_LICENSE}
          label="Trade License"
          setDocuments={setTradeLicenseDoc}
          documents={tradeLicensesDoc}
          handleFileClose={handleFileClose}
          uploadedDocs={state.docTradeLicenses}
        ></UploadMultipleDocs>

        <UploadMultipleDocs
          type={MediaPurpose.MEMORANDUM_OF_ASSOCIATION}
          label="Memorandum of Association"
          setDocuments={setMoaDocs}
          documents={moaDocs}
          handleFileClose={handleFileCloseMOA}
          uploadedDocs={state.docMOAs}
        />

        <UploadMultipleDocs
          type={MediaPurpose.POWER_OF_ATTORNEY}
          label="Power of Attorney"
          setDocuments={setPoaDocs}
          documents={poaDocs}
          handleFileClose={handleFileClosePOA}
          uploadedDocs={state.docPOAs}
        />
      </div>

      <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-mt-10">
        <div className="cx-w-1/4">
          <StepPrevButton
            label={<ArrowLeftIcon />}
            type="button"
            onClick={previousStep}
          />
        </div>
        <div className="cx-w-3/4">
          <StepNextButton
            type="button"
            onClick={() => {
              setUploading(true);
              handleSubmit();
            }}
            disabled={!(tradeLicensesDoc.length > 0 && moaDocs.length > 0)}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentsUpload;
