import { useMemo, FC } from "react";
import { DateTime } from "luxon";
import { LoanPaymentStatus, useLoanDashboard } from "../../contexts";
import { formatAmount } from "../../utils";
import { ArrowLeftIcon } from "../../assets/icons";
import {
  LoanDashboardFormCard,
  LoanDashboardProgress,
} from "./LoanDashboardForm";
import LoanPaymentScheduleTable from "./LoanPaymentScheduleTable";
import { useLoanApplicationPayments } from "../../hooks";
import { useLoanApplicationPaymentsEnabled } from "../../hooks/useLoanApplicationPaymentsEnabled";
import { LoanProductType } from "../../hooks/api/types";
import LoanPaymentSchedule from "./LoanPaymentScheduleBuyNowPayLater/LoanPaymentSchedule";

const LoanPaymentScheduleForm: FC = () => {
  const {
    state: { loans, currentLoanApplicationId: loanId },
    actions: { update },
  } = useLoanDashboard();

  const [loan, _] = useMemo(() => {
    const _loanInd = loans.findIndex((e) => e.id === loanId);
    if (_loanInd > -1) {
      const _loan = loans[_loanInd];
      return [_loan, _loanInd];
    }
    return [undefined, _loanInd];
  }, [loans, loanId]);

  const loanPaymentsEnabled = useLoanApplicationPaymentsEnabled(loan);

  const { data: loanPayments = [] } = useLoanApplicationPayments(
    loanId,
    loanPaymentsEnabled
  );

  const { amount, paidAmount, remainingAmount } = useMemo(() => {
    // either use the values returned in the loan, or calculate them based on the loan payments.
    if (loan?.amount && loan?.paidAmount && loan?.remainingAmount) {
      return {
        amount: loan.amount,
        paidAmount: loan.paidAmount,
        remainingAmount: loan.remainingAmount,
      };
    }

    return loanPayments.reduce(
      (acc, item) => {
        const amountToAdd = item.amount;
        const paidAmountToAdd =
          item?.status === LoanPaymentStatus.PAID ? item.amount : 0;
        const remainingAmountToAdd =
          item?.status !== LoanPaymentStatus.PAID ? item.amount : 0;

        return {
          amount: acc.amount + amountToAdd,
          paidAmount: acc.paidAmount + paidAmountToAdd,
          remainingAmount: acc.remainingAmount + remainingAmountToAdd,
        };
      },
      { amount: 0, paidAmount: 0, remainingAmount: 0 }
    );
  }, [loanPayments, loan]);

  const completion = useMemo(() => {
    return Math.round((paidAmount / amount) * 10000) / 100;
  }, [amount, paidAmount]);

  const loanInput = useMemo(() => {
    if (loan?.loanType === LoanProductType.FIXED_TERM) {
      return loan?.fixedTermLoan;
    } else if (loan?.loanType === LoanProductType.INVOICE_DISCOUNTING) {
      return loan?.invoiceDiscountingLoan?.invoices?.[0];
    } else if (loan?.loanType === LoanProductType.PAYABLE_FINANCING) {
      return loan?.payableFinancingLoan?.invoices?.[0];
    } else if (loan?.loanType === LoanProductType.REVENUE_BASED_FINANCING) {
      return loan?.revenueBasedLoan;
    } else if (loan?.loanType === LoanProductType.POS_REVENUE_BASED_FINANCING) {
      return loan?.posRevenueBasedLoan;
    } else if (loan?.loanType === LoanProductType.BNPL) {
      return loan?.buyNowPayLaterLoan;
    }
    return 0;
  }, [loan]);

  console.log("DEBUG output values: ", {
    amount,
    remainingAmount,
    completion,
    loanInput,
  });

  return (
    <div className="cx-grow cx-w-full">
      <div className="cx-flex cx-flex-row cx-justify-center cx-p-6 cx-border-stroke-primary cx-border-b">
        <div className="cx-w-full cx-max-w-[960px] cx-flex cx-flex-row cx-justify-between">
          <div className="cx-flex cx-flex-row cx-items-center cx-gap-x-4">
            <div
              className="cx-cursor-pointer"
              onClick={() => {
                update({
                  currentLoanApplicationId: "",
                  showPaymentSchedule: false,
                });
              }}
            >
              <ArrowLeftIcon />
            </div>
            <div className="cx-text-text-primary cx-font-semibold cx-text-lg">
              {loan?.loanProduct?.displayName ?? ""}
            </div>
          </div>
          <div className="sm:cx-flex cx-flex-row cx-items-center cx-gap-x-2">
            <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
              {`Loan #${loan?.externalId}`}
            </div>
            <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
            <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
              {`${loan?.loanOfferInterestRate || 0}% Interest`}
            </div>
            <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
            <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
              Issued on{" "}
              {DateTime.fromISO(loan?.createdAt ?? "").toFormat("dd LLL yyyy")}
            </div>
          </div>
        </div>
      </div>
      {loan ? (
        <div className="cx-grow cx-w-full cx-max-w-[960px] cx-mx-auto">
          <div className="cx-w-full cx-flex cx-flex-row cx-justify-center cx-my-10 cx-max-h-[120px] cx-overflow-clip">
            <LoanDashboardProgress value={completion} size="large" />
          </div>
          <div className="cx-w-full sm:cx-flex cx-flex-wrap cx-items-center cx-py-4 cx-px-2 cx-mb-6 sm:cx-divide-x sm:cx-divide-neutral-light cx-rounded-xl cx-border-stroke-primary cx-border">
            <LoanDashboardFormCard label="Original loan amount">
              <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
                <div className="cx-text-text-primary cx-font-medium cx-text-xl">
                  AED
                </div>
                <div className="cx-text-text-primary cx-font-bold cx-text-xl">
                  {formatAmount(loanInput?.loanAmount)}
                </div>
              </div>
            </LoanDashboardFormCard>
            <LoanDashboardFormCard label="Outstanding balance">
              <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
                <div className="cx-text-text-primary cx-font-medium cx-text-xl">
                  AED
                </div>
                <div className="cx-text-text-primary cx-font-bold cx-text-xl">
                  {formatAmount(remainingAmount)}
                </div>
              </div>
            </LoanDashboardFormCard>
            <LoanDashboardFormCard label="Duration">
              <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
                <div className="cx-text-text-primary cx-font-bold cx-text-xl">
                  {loanInput?.duration}
                </div>
                <div className="cx-text-text-primary cx-font-medium cx-text-xl">
                  {loanInput?.durationType}
                </div>
              </div>
            </LoanDashboardFormCard>
          </div>
          {loan.loanType === LoanProductType.BNPL ? (
            <LoanPaymentSchedule loan={loan} />
          ) : (
            <LoanPaymentScheduleTable loan={loan} />
          )}
        </div>
      ) : (
        <div className="cx-grow cx-w-full cx-max-w-[960px]">
          <div className="cx-text-text-primary cx-font-semibold cx-text-lg cx-mr-4">
            Invalid Loan Selection
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanPaymentScheduleForm;
