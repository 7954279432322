import {
  ChangeEvent,
  MutableRefObject,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import Webcam from "react-webcam";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Dropzone,
  StepNextButton,
  StepPrevButton,
} from "../../components";
import { SignModeType, StepperContext, useLoanOffer } from "../../contexts";
import {
  LoanOfferSignInputFields,
  LOAN_OFFER_SIGN_INPUT_INITIAL_VALUES,
  LOAN_OFFER_SIGN_INPUT_VALIDATION_SCHEMA,
} from "./service";
import {
  useApproveLoanOffer,
  useDownloadDirectDebitFilledForm,
  useExtractSignatureStroke,
  useMedia,
} from "../../hooks";
import { MediaPurpose, MediaType } from "../../hooks/api/types";
import { downloadBlob } from "../../utils";
import { ArrowLeftIcon } from "../../assets/icons";
import {
  ValidSignatureSample,
  InvalidSignatureSample,
} from "../../assets/images";

const SIGNATURE_WIDTH = 300;

const LoanOfferSignInput = () => {
  const [dropzoneMedia, setDropzoneMedia] = useState<File | null>(null);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [screenshot, setScreenshot] = useState<string | null>(null);

  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSignatureMatched, setIsSignatureMatched] = useState<boolean>(false);
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
  const [isStoreSignature, setIsStoreSignature] = useState<boolean>(false);
  const [uploadSignatureError, setUploadSignatureError] = useState<
    string | undefined
  >(undefined);

  const { nextStep, previousStep } = useContext(StepperContext);
  const {
    state: { signMode, signImgUrl, id, loanApplicationId, shareholderId },
    actions: { update },
  } = useLoanOffer();

  const signCanvas = useRef<SignatureCanvas>();
  const webcamRef = useRef<Webcam>();

  const { upload, isUploading: isUploadingMedia } = useMedia();

  const { mutateAsync: approveLoanOffer, isLoading: isApproving } =
    useApproveLoanOffer();

  const {
    mutateAsync: extractSignatureStroke,
    isLoading: isLoadingSignatureStroke,
  } = useExtractSignatureStroke();

  const { mutateAsync: downloadForm } = useDownloadDirectDebitFilledForm();

  const {
    touched,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
    isSubmitting,
    validateForm,
    setTouched,
    submitForm,
  } = useFormik<LoanOfferSignInputFields>({
    initialValues: {
      ...LOAN_OFFER_SIGN_INPUT_INITIAL_VALUES,
      signImgUrl,
    },
    validationSchema: LOAN_OFFER_SIGN_INPUT_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      await update({
        signImgUrl: values.signImgUrl,
      });
      try {
        if (signMode === SignModeType.MOUSE_TRACKPAD) {
          const blob = await dataUrlToBlob(previewUrl as string);
          const file = blobToFile(blob, "signature.png", "image/png");
          const mediaId = await upload({
            file,
            type: MediaType.IMAGE,
            purpose: MediaPurpose.SIGNATURE,
            loanApplicationId,
          });
          await extractSignatureStroke({
            mediaId,
            shareholderId,
          });
        }
        await approveLoanOffer({
          shareholderSignMode: signMode,
          id: id,
        });
        nextStep();
      } catch (err: any) {
        console.log(err);
        setFieldError(
          "signImgUrl",
          err?.message || "Something went wrong, refresh and try again"
        );
      }
    },
  });

  useEffect(() => {
    if (signMode === SignModeType.PRINT_SCAN) {
      downloadDirectDebitForm();
    }
  }, [signMode]);

  const handleClear = () => {
    if (signCanvas.current) {
      signCanvas.current.clear();
    }
  };

  const resizeImage = (imgStr: string) => {
    const img = new Image();
    img.src = imgStr;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const width = SIGNATURE_WIDTH;
      const height = (img.height * SIGNATURE_WIDTH) / img.width;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
        handleChange("signImgUrl")(canvas.toDataURL());
      } else {
        handleChange("signImgUrl")(imgStr);
      }
    };
  };

  const dataUrlToBlob = (dataUrl: string) => {
    return fetch(dataUrl).then((res) => res.blob());
  };

  const blobToFile = (blob: Blob, fileName: string, fileType: string): File => {
    return new File([blob], fileName, { type: fileType });
  };

  const getImageUrl = () => {
    if (signCanvas.current) {
      const imgSrc = signCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      resizeImage(imgSrc);
    }
  };

  const captureScreenshot = () => {
    if (webcamRef.current) {
      const img = webcamRef.current.getScreenshot();
      if (img) {
        setScreenshot(img);
        handleChange("signImgUrl")(img);
        return img;
      }
    }
  };

  const handleTryAgain = () => {
    setIsPreviewMode(false);
    setPreviewUrl(null);
    setDropzoneMedia(null);
  };

  const handleSignatureSubmit = async () => {
    setTouched({
      signImgUrl: true,
    });
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      if (signMode === SignModeType.PRINT_SCAN) {
        submitForm();
      } else if (signMode === SignModeType.MOUSE_TRACKPAD) {
        if (signCanvas.current) {
          const imgSrc = signCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png");
          setPreviewUrl(imgSrc);
          setIsPreviewMode(true);
        }
      } else if (signMode === SignModeType.CAMERA) {
        if (screenshot) {
          const blob = await dataUrlToBlob(screenshot);
          const file = blobToFile(blob, "signature.png", "image/png");
          const mediaId = await upload({
            file,
            type: MediaType.IMAGE,
            purpose: MediaPurpose.SIGNATURE,
            loanApplicationId,
          });
          const { signedUrl } = await extractSignatureStroke({
            mediaId,
            shareholderId,
          });
          setPreviewUrl(signedUrl);
          setIsPreviewMode(true);
        }
      } else if (dropzoneMedia) {
        setIsPreviewMode(true);
      }
    } catch (err: any) {
      console.log(err);
      setFieldError(
        "signImgUrl",
        err?.message || "Something went wrong, refresh and try again"
      );
    }
  };

  const downloadDirectDebitForm = async () => {
    const res = await downloadForm();
    downloadBlob(res, "filled-direct-debit-form.pdf");
  };

  const handleSignatureMatchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSignatureMatched(event.target.checked);
  };

  const handleAcknowledgedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAcknowledged(event.target.checked);
  };

  const requiresSignatureAndAcknowledgment =
    (!isSignatureMatched || !isAcknowledged) &&
    signMode === SignModeType.PRINT_SCAN;

  const isDropzoneMediaEmpty =
    dropzoneMedia === null &&
    (signMode === SignModeType.UPLOAD_SIGNATURE ||
      signMode === SignModeType.PRINT_SCAN);

  const getSignModeLabel = (isPreviewMode: boolean, signMode: SignModeType) => {
    if (!isPreviewMode) {
      if (signMode === SignModeType.UPLOAD_SIGNATURE) {
        return "Upload a photo of your signature";
      } else if (signMode === SignModeType.PRINT_SCAN) {
        return "Manual signature on form";
      } else if (signMode === SignModeType.MOUSE_TRACKPAD) {
        return "Sign using mouse or on trackpad";
      } else {
        return `Sign using ${signMode.toLowerCase()}`;
      }
    } else {
      return "Review signature";
    }
  };

  return (
    <div className="cx-max-w-[960px] cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        {getSignModeLabel(isPreviewMode, signMode)}
      </div>
      {signMode === SignModeType.PRINT_SCAN && !isPreviewMode && (
        <>
          <div className="cx-text-text-secondary cx-text-center">
            Download of direct debit form should start automatically, otherwise{" "}
            <span
              className="cx-text-brand-primary-regular cx-cursor-pointer"
              onClick={downloadDirectDebitForm}
            >
              click here
            </span>
            {""} to download.
          </div>
          <div className="cx-text-text-secondary cx-text-center">
            Next, please add your signature and upload the form.
          </div>
        </>
      )}
      {isPreviewMode && (
        <div className="cx-text-text-secondary cx-text-center">
          Please review your signature and make sure it matches your bank
          signature.
        </div>
      )}
      <div className="cx-w-full cx-max-w-[400px]">
        <form onSubmit={handleSubmit}>
          {!isPreviewMode ? (
            <>
              <div className="cx-w-full cx-rounded-lg cx-overflow-hidden cx-my-10">
                {signMode === SignModeType.MOUSE_TRACKPAD && (
                  <div className="cx-rounded-base cx-bg-background-default">
                    <SignatureCanvas
                      ref={signCanvas as MutableRefObject<SignatureCanvas>}
                      canvasProps={{
                        width: 400,
                        height: 170,
                      }}
                      onEnd={() => getImageUrl()}
                      backgroundColor="white"
                      penColor="blue"
                    />
                    <div
                      className="cx-w-full cx-p-4 cx-text-text-brand cx-font-semibold cx-text-center cx-cursor-pointer cx-border-t"
                      onClick={handleClear}
                    >
                      Try again
                    </div>
                    {touched.signImgUrl && !!errors.signImgUrl && (
                      <div className="cx-text-xs cx-py-1 cx-text-text-error">
                        {errors.signImgUrl}
                      </div>
                    )}
                  </div>
                )}
                {signMode === SignModeType.CAMERA && (
                  <div className="cx-bg-background-default">
                    <div className="cx-w-full cx-h-[174px]">
                      {!!screenshot ? (
                        <img src={screenshot} alt="Signature" />
                      ) : (
                        <Webcam
                          ref={webcamRef as RefObject<Webcam>}
                          audio={false}
                          screenshotFormat="image/jpeg"
                          screenshotQuality={1}
                          videoConstraints={{
                            height: 174,
                            width: 400,
                            facingMode: { ideal: "environment" },
                          }}
                        />
                      )}
                    </div>
                    <div className="cx-flex cx-items-center">
                      <div
                        className="cx-w-full cx-p-4 cx-text-text-brand cx-font-semibold cx-text-center cx-cursor-pointer cx-bg-background-default"
                        onClick={captureScreenshot}
                      >
                        Capture
                      </div>
                      <div className="cx-h-[40px] cx-w-[1px] cx-border"></div>
                      <div
                        className="cx-w-full cx-p-4 cx-text-text-brand cx-font-semibold cx-text-center cx-cursor-pointer cx-bg-background-default"
                        onClick={() => setScreenshot(null)}
                      >
                        Try again
                      </div>
                    </div>
                  </div>
                )}
                {signMode === SignModeType.PRINT_SCAN && (
                  <>
                    <Dropzone
                      accept={{ ["application/pdf"]: [".pdf"] }}
                      variant={
                        uploadingMedia
                          ? "uploading"
                          : dropzoneMedia
                          ? "uploaded"
                          : "simple"
                      }
                      extensions={["pdf"]}
                      onDrop={async (file) => {
                        setUploadingMedia(true);
                        await upload({
                          file: file[0],
                          type: MediaType.DOCUMENT,
                          purpose: MediaPurpose.DIRECT_DEBIT_SIGNED_FORM,
                          loanApplicationId,
                        });
                        setDropzoneMedia(file[0]);
                        setUploadingMedia(false);
                      }}
                    />
                    <div className="cx-pt-10">
                      <Checkbox
                        label="I confirm that the above signature matches my bank signature."
                        labelClassnames="cx-text-sm"
                        checked={isSignatureMatched}
                        onChange={handleSignatureMatchChange}
                      />
                      <Checkbox
                        label="I acknowledge that direct debit form may be updated to include additional information for payment processing."
                        labelClassnames="cx-text-sm cx-pt-5"
                        checked={isAcknowledged}
                        onChange={handleAcknowledgedChange}
                      />
                    </div>
                  </>
                )}
                {signMode === SignModeType.UPLOAD_SIGNATURE && (
                  <div>
                    <Dropzone
                      accept={{
                        "image/png": [".png"],
                        "image/jpg": [".jpg", ".jpeg"],
                      }}
                      variant={
                        uploadingMedia
                          ? "uploading"
                          : dropzoneMedia
                          ? "uploaded"
                          : "simple"
                      }
                      error={uploadSignatureError}
                      extensions={["png", "jpg", "jpeg"]}
                      onDrop={async (file) => {
                        setUploadingMedia(true);
                        setUploadSignatureError(undefined);
                        try {
                          const mediaId = await upload({
                            file: file[0],
                            type: MediaType.IMAGE,
                            purpose: MediaPurpose.SIGNATURE,
                            loanApplicationId,
                          });
                          setDropzoneMedia(file[0]);
                          const { signedUrl } = await extractSignatureStroke({
                            mediaId,
                            shareholderId,
                          });
                          setPreviewUrl(signedUrl);
                          setUploadingMedia(false);
                        } catch (err: any) {
                          setUploadSignatureError(
                            err?.message +
                              ". Please try again by uploading a clearer image of your signature on a plain white background to avoid signature rejection."
                          );
                          setUploadingMedia(false);
                        }
                      }}
                    />
                    <div className="cx-text-text-secondary cx-text-center cx-mt-10">
                      Please provide a clear image of your signature on a plain
                      white background to avoid signature rejection
                    </div>
                    <div className="cx-flex cx-justify-between cx-mt-10">
                      <img
                        src={InvalidSignatureSample}
                        alt="signature-sample-2"
                      />
                      <img
                        src={ValidSignatureSample}
                        alt="signature-sample-1"
                      />
                    </div>
                  </div>
                )}
              </div>
              {touched.signImgUrl && errors.signImgUrl && (
                <div className="cx-w-full cx-pb-10">
                  <div className="cx-text-xs cx-py-1 cx-text-text-error">
                    {errors.signImgUrl}
                  </div>
                </div>
              )}
              <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
                <div className="cx-w-1/4">
                  <StepPrevButton
                    label={<ArrowLeftIcon />}
                    type="button"
                    onClick={() => {
                      setIsSignatureMatched(false);
                      setIsAcknowledged(false);
                      previousStep();
                    }}
                  />
                </div>
                <div className="cx-w-3/4">
                  <StepNextButton
                    type="button"
                    label="Next"
                    disabled={
                      isApproving ||
                      isLoadingSignatureStroke ||
                      isSubmitting ||
                      requiresSignatureAndAcknowledgment ||
                      isDropzoneMediaEmpty
                    }
                    loading={
                      isApproving ||
                      isLoadingSignatureStroke ||
                      isUploadingMedia
                    }
                    onClick={handleSignatureSubmit}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="cx-w-full cx-bg-background-default cx-rounded-lg cx-overflow-hidden cx-my-10 cx-h-52 cx-p-2 cx-relative">
                <img
                  src={previewUrl ?? ""}
                  className="cx-w-full cx-h-full"
                  alt="Signature"
                />
                <div
                  className="cx-w-full cx-p-4 cx-text-text-brand cx-font-semibold cx-text-center cx-cursor-pointer cx-absolute cx-bottom-0"
                  onClick={handleTryAgain}
                >
                  Try again
                </div>
              </div>
              <div className="cx-pb-10">
                <Checkbox
                  label="I confirm that the above signature matches my bank signature."
                  labelClassnames="cx-text-sm "
                  checked={isStoreSignature}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setIsStoreSignature(event.target.checked);
                  }}
                />
              </div>
              <div className="cx-flex cx-flex-col cx-gap-4">
                <Button
                  label="Submit"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting || !isStoreSignature}
                  {...(isSubmitting && { loader: "left" })}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoanOfferSignInput;
