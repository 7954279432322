import { Footer } from "../../components";
import info from "../../constants/info.json";

const LoanApplicationFlowFooter = () => {
  return (
    <div className={"cx-w-full"}>
      <Footer text={info.footer} />
    </div>
  );
};

export default LoanApplicationFlowFooter;
