import { FC, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import {
  Button,
  DatePicker,
  Divider,
  InputField,
  Slider,
} from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import {
  getInvoiceDiscountingValidationSchema,
  InvoiceDiscountingCalculatorFields,
} from "./service";
import {
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { roundTo, toTitleCase } from "../../utils";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ArrowLeftIcon } from "../../assets/icons";
import { useLoanMetrics, useMedia } from "../../hooks";

import "./InvoiceDiscountingLoan.css";
import TermsAndConditions from "./TermsAndConditions";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

const DUMMY_INVOICE_NAME = "dummy-invoice.pdf";

const PAGE_COPY: {
  [key: string]: {
    default: (...args: any[]) => string;
    repayableByDP: (...args: any[]) => string;
  };
} = {
  TITLE: {
    default: () => "Total received amount",
    repayableByDP: () => "Total payment amount",
  },
  SUBTITLE: {
    default: (interestRate: number) =>
      `Pay as low as ${interestRate}% monthly interest*.`,
    repayableByDP: (interestRate: number) =>
      `Pay from ${interestRate}% monthly fees*.`,
  },
  LOAN_AMOUNT: {
    default: () => "Loan amount",
    repayableByDP: () => "Invoice amount",
  },
  LOAN_DURATION: {
    default: () => "Loan duration",
    repayableByDP: () => "Invoice due date",
  },
};

function getCopyText(key: string, repayableByDPFlag?: boolean) {
  return PAGE_COPY[key][repayableByDPFlag ? "repayableByDP" : "default"];
}

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const InvoiceDiscountingLoanCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const [isPartnerTermsAccepted, setIsPartnerTermsAccepted] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const {
    state: { invoiceDiscountingLoan, constraints },
    actions: { setInvoiceDiscountingLoan },
  } = useLoanCalculator();
  const {
    state: { dpTermsUrl },
  } = useDpConfig();
  const { nextStep } = useStepper();
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const {
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
    disableSalesStatementsUpload,
  } = useLoanProductConfigFlags(LoanProductType.INVOICE_DISCOUNTING);
  const {
    state: { id, invoiceFiles, sourceId, stage, loanType },
    actions: { update, refetch },
  } = useLoanApplication();
  const { upload } = useMedia();

  const {
    invoiceFinanceProduct: {
      flags: { repayableByDP },
    },
    totalInvoiceFinancePaymentForSME,
  } = useLoanMetrics();
  const { enableVendorScreen } = useLoanProductConfigFlags(loanType);

  const [currentInvoice] = invoiceDiscountingLoan.invoices;

  const [invoiceDueDate, setInvoiceDueDate] = useState<Date | null>(
    repayableByDP
      ? currentInvoice?.invoiceDueDate ??
          DateTime.fromJSDate(new Date())
            .plus({
              days: constraints.invoiceDiscountingLoan?.duration?.initial,
            })
            .toJSDate()
      : null
  );

  const { values, errors, setFieldValue, handleSubmit } =
    useFormik<InvoiceDiscountingCalculatorFields>({
      enableReinitialize: true,
      initialValues: {
        loanAmount:
          currentInvoice && !isNaN(currentInvoice.amount)
            ? currentInvoice.amount
            : constraints.invoiceDiscountingLoan?.amount?.initial,
        duration:
          currentInvoice && !isNaN(currentInvoice.duration)
            ? currentInvoice.duration
            : constraints.invoiceDiscountingLoan?.duration?.initial,
        discountRate:
          currentInvoice && !isNaN(currentInvoice.discountRate)
            ? currentInvoice.discountRate
            : constraints.invoiceDiscountingLoan?.discount?.initial,
      },
      validationSchema: getInvoiceDiscountingValidationSchema({
        loanAmount: {
          min: constraints.invoiceDiscountingLoan?.amount?.min,
          max: constraints.invoiceDiscountingLoan?.amount?.max,
          unit: constraints.invoiceDiscountingLoan?.amount?.unit ?? "AED",
        },
        duration: {
          min: constraints.invoiceDiscountingLoan?.duration?.min,
          max: constraints.invoiceDiscountingLoan?.duration?.max,
        },
        discount: {
          min: constraints.invoiceDiscountingLoan?.discount?.min,
          max: constraints.invoiceDiscountingLoan?.discount?.max,
        },
      }),
      onSubmit: async () => {
        // this is because if u don't select loan product manually at product selection screen and go with pre selected loan product type
        // then loanType in loan application context never gets updated with right loan product (not even at loan calculator)
        await update(
          {
            loanType: LoanProductType.INVOICE_DISCOUNTING,
          },
          {
            local: true,
          }
        );
        if (repayableByDP) {
          currentInvoice.invoiceDueDate = invoiceDueDate!;
        }
        setInvoiceDiscountingLoan({
          invoices: [currentInvoice],
        });
        // if invoice upload is disabled, we need to update the loan application with a dummy invoice file
        if (disableInvoiceUpload) {
          await update(
            {
              invoiceFiles: [new File([], DUMMY_INVOICE_NAME)],
              //if its legacy flow, we update local state else we update the loan application on BE as well
            },
            { local: disableLOCFlow }
          );
          // if LOC flow is disabled, we go to next step
          if (disableLOCFlow) {
            nextStep();
            // if sales statements upload is not disabled we are on sales statement page, we go to next step
            !disableSalesStatementsUpload && nextStep();
            // if sales invoice upload is not disabled we are on sales invoice page, we go to next step
            !disableSalesInvoiceUpload && nextStep();
          }
        } else {
          //else we go to invoice upload page
          nextStep();
        }
      },
    });

  useEffect(() => {
    //if legacy flow is enabled, and invoice file upload is disabled, we need to update the loan application with a dummy invoice file
    if (!disableLOCFlow) {
      if (invoiceFiles.length > 0) {
        if (disableInvoiceUpload) {
          handleInvoiceBasedLoanUpdate(id).then(() => {
            update({
              stage: sourceId
                ? enableVendorScreen
                  ? LoanApplicationStage.VENDOR_INFORMATION
                  : LoanApplicationStage.REVIEW
                : LoanApplicationStage.OWNER_INFORMATION,
            });
          });
        } else {
          //else we go to invoice upload page
          stage !== LoanApplicationStage.REVIEW && nextStep();
        }
      }
    }
  }, [invoiceFiles]);

  useEffect(() => {
    if (!invoiceDiscountingLoan.invoices?.length) {
      setInvoiceDiscountingLoan({
        invoices: currentInvoice
          ? [currentInvoice]
          : [
              {
                amount: constraints.invoiceDiscountingLoan?.amount?.initial,
                duration: constraints.invoiceDiscountingLoan?.duration?.initial,
                discountRate:
                  constraints.invoiceDiscountingLoan?.discount?.initial,
                interestRate: constraints.invoiceDiscountingLoan.interestRate,
                invoiceId: "",
                durationType: constraints.invoiceDiscountingLoan.duration
                  .unit as LoanDurationType,
                invoiceDueDate: new Date(),
                invoiceIssuedDate: new Date(),
                invoiceNo: "",
                vendorName: "",
              },
            ],
      });
    }
  }, [values, invoiceDiscountingLoan.invoices?.length]);

  async function handleInvoiceBasedLoanUpdate(loanApplicationId: string) {
    setLoading(true);
    const invoicePromises = invoiceFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.INVOICE,
        loanApplicationId: loanApplicationId,
      })
    );
    const invoiceIds = await Promise.all(invoicePromises);
    if (invoiceIds.length !== 0) {
      const invoices = invoiceDiscountingLoan.invoices.map(
        (invoice, index) => ({
          ...invoice,
          invoiceId: invoiceIds[index],
          discountRate: invoice.discountRate,
          ...(invoice.invoiceDueDate && {
            invoiceDueDate: invoice.invoiceDueDate,
          }),
        })
      );
      await update({
        id: loanApplicationId,
        loanType: LoanProductType.INVOICE_DISCOUNTING,
        invoices,
      });
      await refetch();
    }
    setLoading(false);
  }

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }

    setFieldValue(name, num, true);

    const validation: any = {
      loanAmount: constraints.invoiceDiscountingLoan.amount,
      duration: constraints.invoiceDiscountingLoan.duration,
      discountRate: constraints.invoiceDiscountingLoan.discount,
    };

    if (num > validation[name].max || num < validation[name].min) {
      return null;
    }

    return num;
  };

  const handleLoanAmountInputChange = (value: string) => {
    const val = handleInputChange("loanAmount", value);
    if (val !== null) {
      currentInvoice.amount = val;
      currentInvoice.interestRate =
        constraints.invoiceDiscountingLoan.interestRate;
      setInvoiceDiscountingLoan({
        invoices: [currentInvoice],
      });
    }
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);
    currentInvoice.amount = value;
    currentInvoice.interestRate =
      constraints.invoiceDiscountingLoan.interestRate;
    setInvoiceDiscountingLoan({
      invoices: [currentInvoice],
    });
  };

  const handleDurationInputChange = (value: string, date?: Date) => {
    const val = handleInputChange("duration", value);
    if (val !== null) {
      currentInvoice.duration = val;
      currentInvoice.interestRate =
        constraints.invoiceDiscountingLoan.interestRate;
      if (date) {
        currentInvoice.invoiceDueDate = date;
      }
      setInvoiceDiscountingLoan({
        invoices: [currentInvoice],
      });
    }
  };

  const handleDurationSliderChange = (value: number) => {
    setFieldValue("duration", value);
    currentInvoice.duration = value;
    currentInvoice.interestRate =
      constraints.invoiceDiscountingLoan.interestRate;
    setInvoiceDiscountingLoan({
      invoices: [currentInvoice],
    });
  };

  const handleDiscountRateInputChange = (value: string) => {
    const val = handleInputChange("discountRate", value);
    if (val !== null) {
      currentInvoice.discountRate = val;
      currentInvoice.interestRate =
        constraints.invoiceDiscountingLoan.interestRate;
      setInvoiceDiscountingLoan({
        invoices: [currentInvoice],
      });
    }
  };

  const hideDiscountRate = useMemo(
    () =>
      constraints.invoiceDiscountingLoan?.discount?.min === 100 &&
      constraints.invoiceDiscountingLoan?.discount?.max === 100,
    [
      constraints.invoiceDiscountingLoan?.discount?.min,
      constraints.invoiceDiscountingLoan?.discount?.max,
    ]
  );

  const disableDiscountRate = useMemo(
    () =>
      constraints.invoiceDiscountingLoan?.discount?.min ===
      constraints.invoiceDiscountingLoan?.discount?.max,
    [
      constraints.invoiceDiscountingLoan?.discount?.min,
      constraints.invoiceDiscountingLoan?.discount?.max,
    ]
  );

  const handleTermsChange = (checked: boolean) => {
    setIsPartnerTermsAccepted(checked);
  };

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
        <div className="cx-font-medium">
          {getCopyText("TITLE", repayableByDP)()}
        </div>
        <div className="cx-text-3xl cx-font-bold">
          {constraints.invoiceDiscountingLoan?.amount?.unit}{" "}
          {totalInvoiceFinancePaymentForSME &&
            roundTo(totalInvoiceFinancePaymentForSME, 2).toLocaleString()}
        </div>
        <div className="cx-text-sm cx-text-text-secondary">
          {getCopyText(
            "SUBTITLE",
            repayableByDP
          )(roundTo((currentInvoice?.interestRate / 12) * 100, 2))}
        </div>
      </div>
      <Divider />
      <div className="cx-flex cx-flex-row cx-w-full cx-gap-6">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">
            {getCopyText("LOAN_AMOUNT", repayableByDP)()}
          </div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.invoiceDiscountingLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          {!repayableByDP && (
            <div className="">
              <Slider
                initial={values.loanAmount}
                min={constraints.invoiceDiscountingLoan?.amount?.min}
                max={constraints.invoiceDiscountingLoan?.amount?.max}
                unit={constraints.invoiceDiscountingLoan?.amount?.unit}
                value={values.loanAmount}
                label=""
                onChange={(value) => handleLoanAmountSliderChange(value)}
                renderThumb={false}
                interval={1000}
              />
            </div>
          )}
          {errors.loanAmount && (
            <div className="cx-text-xs cx-py-1 cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">
            {getCopyText("LOAN_DURATION", repayableByDP)()}
          </div>
          <div>
            {repayableByDP ? (
              <DatePicker
                selectedDate={invoiceDueDate!}
                min={DateTime.fromJSDate(new Date())
                  .plus({
                    days: constraints.invoiceDiscountingLoan?.duration?.min,
                  })
                  .toJSDate()}
                max={DateTime.fromJSDate(new Date())
                  .plus({
                    days: constraints.invoiceDiscountingLoan?.duration?.max,
                  })
                  .toJSDate()}
                onChange={(date) => {
                  const now = DateTime.fromJSDate(new Date());
                  const invoiceDate = DateTime.fromJSDate(date);
                  const duration = invoiceDate.diff(now, "days").as("days");
                  setInvoiceDueDate(date);
                  handleDurationInputChange(
                    Math.ceil(duration).toString(),
                    date
                  );
                }}
                className="!cx-py-2 cx-font-bold"
              />
            ) : (
              <InputField
                inputProps={{
                  name: "duration",
                  value: values.duration,
                  onChange: (e) => handleDurationInputChange(e.target.value),
                }}
                inputClassName="cx-text-right !cx-p-2 cx-font-bold"
                prepend={toTitleCase(
                  constraints.invoiceDiscountingLoan?.duration?.unit
                )}
              />
            )}
          </div>
          {!repayableByDP && (
            <div className="">
              <Slider
                initial={values.duration}
                min={constraints.invoiceDiscountingLoan?.duration?.min}
                max={constraints.invoiceDiscountingLoan?.duration?.max}
                unit={constraints.invoiceDiscountingLoan?.duration?.unit}
                label=""
                onChange={(value) => handleDurationSliderChange(value)}
                renderThumb={false}
              />
            </div>
          )}
        </div>
      </div>
      {/* <Hint
        text={`${getCopyText(
          "LOAN_AMOUNT",
          repayableByDP
        )()} should be exclusive of VAT.`}
        rounded
        icon
        className="cx-w-full -cx-mt-6"
      /> */}
      <Divider />
      {!hideDiscountRate && (
        <div className="cx-flex cx-flex-col">
          <div className="cx-flex cx-items-center cx-text-text-secondary cx-text-sm cx-gap-2">
            <div className="cx-flex-1">Borrow up to </div>
            <InputField
              inputProps={{
                name: "discountRate",
                value: values.discountRate,
                onChange: (e) => handleDiscountRateInputChange(e.target.value),
                disabled: disableDiscountRate,
              }}
              className="cx-max-w-[88px]"
              inputClassName="cx-py-2"
              append={toTitleCase(
                constraints.invoiceDiscountingLoan?.discount?.unit
              )}
              error={errors.discountRate}
              hideErrorMessage
            />
            <div> of your invoice</div>
          </div>
          {errors.discountRate && (
            <div className="cx-text-xs cx-py-1 cx-text-text-error cx-text-center">
              {errors.discountRate}
            </div>
          )}
        </div>
      )}
      <div className="cx-w-[400px]">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Continue"
            arrow="right"
            loader={loading ? "right" : undefined}
            fullWidth
            onClick={handleSubmit}
            disabled={!isPartnerTermsAccepted && !!dpTermsUrl}
          />
        )}
      </div>
      <Divider />
      <div className="cx-block">
        <TermsAndConditions
          onTermsChange={handleTermsChange}
          loanType={LoanProductType.INVOICE_DISCOUNTING}
        />
      </div>
    </div>
  );
};

export default InvoiceDiscountingLoanCalculator;
