import { Divider } from "../../../../components";
import { useLoanApplication } from "../../../../contexts";
import { toTitleCase } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import EditButton from "../EditButton";
import { BusinessInformationModal } from "../BusinessInformationModal";
import { useState } from "react";
import { useDpConfig } from "../../../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../../../hooks/useLoanProductConfigFlags";

const BusinessInformationReviewSection = () => {
  const { state } = useLoanApplication();
  const { additionalData } = useDpConfig().state.settings;

  const { disableRevenueHistory } = useLoanProductConfigFlags(state.loanType!);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">Business Information</div>
        <EditButton onClick={() => setIsEditModalOpen(true)} />
        <BusinessInformationModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          disableRevenueHistory={disableRevenueHistory ?? true}
        />
      </div>
      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={state.businessName}
            label={"Company Name"}
            disabled
          />
          <Divider />
          <LoanReviewField
            value={state.businessYearEstabilished}
            label={"Year company established"}
            disabled
          />
          <Divider />
          {additionalData?.map((field) => (
            <div key={field.key}>
              <Divider />
              <LoanReviewField
                value={
                  state.additionalDPMetadata
                    ? state.additionalDPMetadata[field.key]
                    : ""
                }
                label={field.displayName}
                disabled
              />
            </div>
          ))}
          <Divider />
          <LoanReviewField
            value={`
              ${state.businessFloor} - ${state.businessUnit} 
              ${state.businessBuilding}
              ${state.businessArea} 
              ${state.businessEmirate}
              `}
            label={"Office Address"}
          />
          <Divider mobileOnly />
        </div>
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={state.tradeLicenseNumber}
            label={"Trade license number"}
            disabled
          />
          <Divider />
          <LoanReviewField
            // TODO: this is a temp fix. we should use the actual "value"
            value={toTitleCase(state.businessType)}
            label={"Business type"}
            disabled
          />
          <Divider />
          <LoanReviewField
            // TODO: this is a temp fix. we should use the actual "value"
            value={toTitleCase(state.licensingAuthorityId) ?? ""}
            label={"Licensing Authority"}
            disabled
          />
          <Divider />
          {!disableRevenueHistory && (
            <LoanReviewField
              value={`AED ${state.annualRevenue.toLocaleString()}`}
              label={"Annual revenue"}
            />
          )}
          {!disableRevenueHistory && !!state.taxRegistrationNumber && (
            <>
              <Divider />
              <LoanReviewField
                value={state.taxRegistrationNumber}
                label={"Tax registration number"}
              />
            </>
          )}
          {!disableRevenueHistory && !!state.lastYearPaidTax && (
            <>
              <Divider />
              <LoanReviewField
                value={
                  state.lastYearPaidTax
                    ? `AED ${state.lastYearPaidTax.toLocaleString()}`
                    : ""
                }
                label={"Last year paid tax"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessInformationReviewSection;
