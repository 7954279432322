import { useMemo } from "react";
import ReactSlider from "react-slider";

type Props = {
  label: string;
  initial: number;
  unit: string;
  min: number;
  max: number;
  interval?: number;
  steps?: number[];
  onChange: (value: number) => void;
  renderThumb?: boolean;
  value?: number;
};

const Slider = ({
  min,
  max,
  label,
  interval = 1,
  steps,
  unit,
  initial,
  onChange,
  renderThumb = true,
  value,
}: Props) => {
  const handleChange = (value: number) => {
    if (steps) {
      const closestValue = steps.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
      onChange(closestValue);
    } else {
      onChange(value);
    }
  };
  return (
    <div className="cx--mr-5">
      <label className="cx-font-semibold">{label}</label>
      <ReactSlider
        onChange={handleChange}
        min={min}
        max={max}
        defaultValue={initial}
        {...(value ? { value } : {})}
        step={steps ? 1 : interval}
        className={renderThumb ? "cx-mt-11" : ""}
        // className="cx-horizontal-slider"
        renderThumb={(props, state) => {
          return renderThumb ? (
            <div
              {...props}
              className="cx-flex cx-flex-col cx-items-center cx-outline-none cx-cursor-pointer cx--translate-y-3.5 cx--translate-x-2.5 cx-group"
            >
              <div className="cx--mt-7 cx-bg-brand-primary-regular cx-rounded-full cx-text-sm cx-text-white cx-h-8 cx-w-8 cx-flex cx-items-center cx-justify-center">
                {state.value}
              </div>
              <div className="cx-h-1 cx-w-1 cx-bg-brand-primary-regular cx-rotate-45 cx-z-10 cx--mt-[3px]"></div>
              <div className="cx-h-4 cx-w-4 cx-mt-1 cx-rounded-full cx-bg-brand-primary-regular cx-border-2 cx-border-white cx-shadow-md"></div>
            </div>
          ) : (
            <div
              {...props}
              className="cx-flex cx-flex-col cx-items-center cx-outline-none cx-cursor-pointer cx--translate-y-3.5 cx--translate-x-2.5 cx-group"
            >
              <div className="cx-h-4 cx-w-4 cx-mt-2 cx-rounded-full cx-bg-brand-primary-regular cx-border-2 cx-border-white cx-shadow-md cx-ml-2"></div>
            </div>
          );
        }}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={`cx-mr-5 cx-h-1.5 cx-rounded-full cx-cursor-pointer ${
              state.index === 0
                ? "cx-bg-brand-primary-regular"
                : "cx-bg-background-tertiary"
            }`}
          ></div>
        )}
      />
      <div
        className={`cx-flex cx-flex-row cx-justify-between cx-items-center cx-text-xs cx-text-text-tertiary cx-font-semibold ${
          renderThumb ? "cx-mt-14" : "cx-mt-3"
        } cx-mr-5`}
      >
        <div className="cx-mt-3">
          {min?.toLocaleString("en-AE")} {unit}
        </div>
        <div className="cx-mt-3">
          {max?.toLocaleString("en-AE")} {unit}
        </div>
      </div>
    </div>
  );
};

export default Slider;
