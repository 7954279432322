import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { StepConfig, StepperContextValue } from "./types";
import StepperContextReducer from "./reducer";

type Props = {
  stepConfig: StepConfig;
  children?: ReactNode;
};

export const StepperContext = createContext<StepperContextValue>({
  loading: true,
  steps: [],
  currentStep: [0, 0],
  nextStep: () => {},
  previousStep: () => {},
  gotoStep: () => {},
  // completeStep: () => {},
  reset: () => {},
  jumpToAfterGoTo: null,
  skipIllustrationSteps: false,
  setSkipIllustrationSteps: () => {},
  setLoading: () => {},
});

export const STEPPER_CONTEXT_INITIAL_STATE: StepperContextValue = {
  loading: true,
  steps: [],
  currentStep: [0, 0],
  nextStep: () => {},
  previousStep: () => {},
  gotoStep: () => {},
  reset: () => {},
  skipIllustrationSteps: false,
  setSkipIllustrationSteps: () => {},
  setLoading: () => {},
};

export const StepperContextProvider: React.FC<Props> = ({
  stepConfig,
  children,
}) => {
  const [state, dispatch] = useReducer(StepperContextReducer, {
    ...STEPPER_CONTEXT_INITIAL_STATE,
    steps: [...stepConfig],
    currentStep: [0, 0],
  });

  const actions = useMemo(
    () => ({
      nextStep: () => dispatch({ type: "NEXT_STEP" }),
      previousStep: () => dispatch({ type: "PREVIOUS_STEP" }),
      gotoStep: (
        stepIndices: [number, number],
        jumpToAfterGoTo?: [number, number]
      ) =>
        dispatch({
          type: "GOTO_STEP",
          payload: { stepIndices, jumpToAfterGoTo },
        }),
      reset: () => dispatch({ type: "RESET" }),
      setSkipIllustrationSteps: (skip: boolean) =>
        dispatch({ type: "SET_SKIP_ILLUSTRATION_STEPS", payload: skip }),
      setLoading: (loading: boolean) =>
        dispatch({ type: "SET_LOADING", payload: loading }),
    }),
    [dispatch]
  );

  const contextValue = useMemo(() => {
    return { ...state, ...actions };
  }, [state, actions]);

  useEffect(() => {
    dispatch({ type: "UPDATE_STEPS", payload: stepConfig });
  }, [stepConfig]);

  return (
    <StepperContext.Provider value={contextValue}>
      {children}
    </StepperContext.Provider>
  );
};

export * from "./types";
