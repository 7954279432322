import {
  LoanDashboardFormCard,
  LoanDashboardProgress,
} from "../LoanDashboardForm";
import { formatAmount } from "../../../utils";
import { LoanApplication, LoanPaymentStatus } from "../../../contexts";
import { FC, useMemo } from "react";
import {
  useInvoiceFinanceSettings,
  useLoanApplicationPayments,
} from "../../../hooks";
import {
  LoanApplicationStatus,
  LoanApplicationStatusDashboardMappingToText,
  LoanProductType,
} from "../../../hooks/api/types";
import { useLoanApplicationPaymentsEnabled } from "../../../hooks/useLoanApplicationPaymentsEnabled";

type LoanDetailsProps = {
  loan: LoanApplication;
};

export const LoanDetails: FC<LoanDetailsProps> = ({ loan }) => {
  const loanApplicationPaymentsEnabled =
    useLoanApplicationPaymentsEnabled(loan);

  const { data: loanPayments = [] } = useLoanApplicationPayments(
    loan.id,
    loanApplicationPaymentsEnabled
  );

  const { repayableByDPFlag } = useInvoiceFinanceSettings();

  const { amount, paidAmount, remainingAmount } = useMemo(() => {
    // either use the values returned in the loan, or calculate them based on the loan payments.
    if (loan?.amount && loan?.paidAmount && loan?.remainingAmount) {
      return {
        amount: loan.amount,
        paidAmount: loan.paidAmount,
        remainingAmount: loan.remainingAmount,
      };
    }

    return loanPayments.reduce(
      (acc, item) => {
        const amountToAdd = item.amount;
        const paidAmountToAdd =
          item?.status === LoanPaymentStatus.PAID ? item.amount : 0;
        const remainingAmountToAdd =
          item?.status !== LoanPaymentStatus.PAID ? item.amount : 0;

        return {
          amount: acc.amount + amountToAdd,
          paidAmount: acc.paidAmount + paidAmountToAdd,
          remainingAmount: acc.remainingAmount + remainingAmountToAdd,
        };
      },
      { amount: 0, paidAmount: 0, remainingAmount: 0 }
    );
  }, [loanPayments, loan]);

  const completion = useMemo(() => {
    return Math.round((paidAmount / amount) * 10000) / 100;
  }, [amount, paidAmount]);

  const loanInput = useMemo(() => {
    if (loan?.loanType === LoanProductType.FIXED_TERM) {
      return loan?.fixedTermLoan;
    } else if (loan?.loanType === LoanProductType.INVOICE_DISCOUNTING) {
      return loan?.invoiceDiscountingLoan?.invoices?.[0];
    } else if (loan?.loanType === LoanProductType.PAYABLE_FINANCING) {
      return loan?.payableFinancingLoan?.invoices?.[0];
    } else if (loan?.loanType === LoanProductType.REVENUE_BASED_FINANCING) {
      return loan?.revenueBasedLoan;
    } else if (loan?.loanType === LoanProductType.POS_REVENUE_BASED_FINANCING) {
      return loan?.posRevenueBasedLoan;
    } else if (loan?.loanType === LoanProductType.BNPL) {
      return loan?.buyNowPayLaterLoan;
    }
    return 0;
  }, [loan]);

  const showProgress = useMemo(() => {
    return (
      loan.status === LoanApplicationStatus.ACTIVE &&
      !(
        loan.loanType == LoanProductType.FIXED_TERM ||
        loan.loanType == LoanProductType.INVOICE_DISCOUNTING ||
        loan.loanType == LoanProductType.PAYABLE_FINANCING
      )
    );
  }, [loan]);

  const showRemainingAmount = useMemo(() => {
    return !(
      (loan.loanType == LoanProductType.PAYABLE_FINANCING ||
        loan.loanType == LoanProductType.INVOICE_DISCOUNTING) &&
      repayableByDPFlag
    );
  }, [loan.loanType, repayableByDPFlag]);
  return (
    <div className="sm:cx-flex cx-flex-wrap cx-items-center cx-py-4 cx-px-2 sm:cx-divide-x sm:cx-divide-neutral-light">
      <LoanDashboardFormCard
        label={LoanApplicationStatusDashboardMappingToText[loan.status] ?? "Requested Amount"}
      >
        <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
          <div className="cx-text-text-primary cx-font-medium cx-text-xl">
            AED
          </div>
          <div className="cx-text-text-primary cx-font-bold cx-text-xl">
            {formatAmount(
              loanInput?.loanAmount * (loanInput?.discountRate ?? 1)
            )}
          </div>
        </div>
      </LoanDashboardFormCard>
      {loan.loanType === LoanProductType.POS_REVENUE_BASED_FINANCING && (
        <LoanDashboardFormCard label="Upfront fee">
          <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
            <div className="cx-text-text-primary cx-font-medium cx-text-xl">
              AED
            </div>
            <div className="cx-text-text-primary cx-font-bold cx-text-xl">
              {formatAmount(loanInput?.totalAmount - loanInput?.loanAmount)}
            </div>
          </div>
        </LoanDashboardFormCard>
      )}
      {showRemainingAmount && (
        <LoanDashboardFormCard label="Remaining loan amount">
          <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
            <div className="cx-text-text-primary cx-font-medium cx-text-xl">
              AED
            </div>
            <div className="cx-text-text-primary cx-font-bold cx-text-xl">
              {formatAmount(remainingAmount)}
            </div>
          </div>
        </LoanDashboardFormCard>
      )}
      {loan.loanType !== LoanProductType.POS_REVENUE_BASED_FINANCING && (
        <LoanDashboardFormCard label="Duration">
          <div className="cx-grow cx-flex cx-items-center cx-justify-center cx-gap-x-1">
            <div className="cx-text-text-primary cx-font-bold cx-text-xl">
              {loanInput?.duration}
            </div>
            <div className="cx-text-text-primary cx-font-medium cx-text-xl">
              {loanInput?.durationType}
            </div>
          </div>
        </LoanDashboardFormCard>
      )}
      {showProgress && <LoanDashboardProgress value={completion} />}
    </div>
  );
};

export default LoanDetails;
