import { useContext, useState } from "react";
import { useFormik } from "formik";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../contexts";
import { Button, CardList, Dropzone, StepNextButton } from "../../components";
import {
  LOAN_UPLOAD_SALES_STATEMENT_VALIDATION_SCHEMA,
  LOAN_UPLOAD_STATEMENTS_INITIAL_VALUES,
  SALES_STATEMENT_CONFIG,
  UploadStatementsFields,
} from "./service";
import { ArrowLeftIcon, PdfIcon } from "../../assets/icons";
import { CardVariantType } from "../../components/CardList/CardList";
import { formatBytes } from "../../utils";
import {
  LoanApplicationStage,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { useMedia } from "../../hooks";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

const UploadSalesStatement = () => {
  const [salesStatements, setSalesStatements] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { revenueBasedLoan: revenueBasedLoanCalculatorState, constraints },
  } = useLoanCalculator();
  const {
    state: { revenueBasedLoan, id, sourceId, loanType },
    actions: { update },
  } = useLoanApplication();
  const { enableVendorScreen } = useLoanProductConfigFlags(loanType);

  const { upload } = useMedia();

  const { previousStep, nextStep } = useContext(StepperContext);
  const { disableSalesInvoiceUpload } = useLoanProductConfigFlags(loanType);
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();

  async function handleRevenueBasedLoanUpdate(
    id: string,
    salesStatementFiles: File[]
  ) {
    const salesStatementPromises = salesStatementFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.SALES_STATEMENT,
        loanApplicationId: id,
      })
    );
    const salesStatementIds = await Promise.all(salesStatementPromises);
    const salesStatements = salesStatementIds.map((_statement, index) => ({
      documentId: salesStatementIds[index],
    }));
    await update(
      {
        id,
        loanType: LoanProductType.REVENUE_BASED_FINANCING,
        revenueBasedLoan: {
          ...revenueBasedLoanCalculatorState,
          salesStatements: salesStatements,
        },
        additionalDPMetadata: {
          userConstraintsConfig: JSON.stringify(
            constraints.userConstraintsConfig
          ),
        },
      },
      {
        local: disableLOCFlow,
      }
    );
  }

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm } =
    useFormik<UploadStatementsFields>({
      initialValues: {
        ...LOAN_UPLOAD_STATEMENTS_INITIAL_VALUES,
        salesStatements: revenueBasedLoan.salesStatements.map(
          (salesStatement) => new File([], salesStatement.documentId + ".pdf")
        ),
      },
      validationSchema: LOAN_UPLOAD_SALES_STATEMENT_VALIDATION_SCHEMA,
      onSubmit: async (values) => {
        try {
          //if we are in loc flow mode
          if (!disableLOCFlow) {
            setLoading(true);
            await handleRevenueBasedLoanUpdate(id, values.salesStatements);
            setLoading(false);
            if (sourceId) {
              await update({
                stage: enableVendorScreen
                  ? LoanApplicationStage.VENDOR_INFORMATION
                  : LoanApplicationStage.REVIEW,
              });
            } else {
              await update({ stage: LoanApplicationStage.OWNER_INFORMATION });
            }
          } else {
            //if legacy flow
            await update(
              {
                salesStatementFiles: values.salesStatements,
              },
              { local: true }
            );
            !disableSalesInvoiceUpload && nextStep();
            nextStep();
          }
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleUpload = (files: File[]) => {
    if (!files) return;

    const updatedStatements = [...values.salesStatements];

    files.forEach((file: File) => {
      let isFileAlreadyAdded = updatedStatements.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        updatedStatements.push(file);
      }
    });
    setUploading(true);
    setFieldValue("salesStatements", [...updatedStatements]);
    setSalesStatements([...updatedStatements]);
    setUploading(false);
  };

  const handleFileClose = async (fileName: string) => {
    setSalesStatements((prev) => {
      return prev.filter((salesStatement) => fileName !== salesStatement.name);
    });
    values.salesStatements = values.salesStatements.filter(
      (saleStatement) => fileName !== saleStatement.name
    );
    await validateForm();
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-8 cx-max-w-[960px]">
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-gap-2">
        <div className="cx-text-3xl cx-font-bold cx-text-center">
          Upload sales statement
        </div>
        <div className="cx-text-text-secondary cx-text-center">
          Please upload the sales statement for the last 12 months from your
          e-commerce platform
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mt-4">
          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: SALES_STATEMENT_CONFIG.accept,
              maxFiles: SALES_STATEMENT_CONFIG.maxFiles,
              maxSize: SALES_STATEMENT_CONFIG.maxSize, // 100MB to byte
            }}
            onDrop={async (files) => {
              if (!files) return;
              handleUpload(files);
            }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
            {salesStatements.map((salesStatement: File, index: number) => (
              <div className="cx-mb-2">
                <CardList
                  variant={CardVariantType.UPLOADED}
                  title={salesStatement.name}
                  description={
                    touched.salesStatements &&
                    values.salesStatements.length &&
                    !!errors.salesStatements?.[index]
                      ? (errors.salesStatements[index] as string)
                      : formatBytes(salesStatement.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  inputProps={{ accept: ".pdf,application/pdf" }}
                  error={
                    touched.salesStatements &&
                    !!errors.salesStatements?.[index] &&
                    !!values.salesStatements.length
                  }
                  handleFileClose={handleFileClose}
                  truncateTitleLength={35}
                  fileNewDesign={true}
                />
              </div>
            ))}
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={() => {
                  previousStep();
                  previousStep();
                }}
                fullWidth
              />
            </div>
            <div className="cx-w-3/4">
              <StepNextButton
                type="button"
                label="Next"
                loading={loading}
                disabled={
                  uploading || loading || !values.salesStatements?.length
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadSalesStatement;
