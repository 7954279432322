import { ButtonLoaderIcon } from "../../assets/icons";
import { useLoanOffer } from "../../contexts";
import { useStepper } from "../../views/LoanApplicationSteps/Stepper";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { datadogRum } from "@datadog/browser-rum";

const LoanOfferForm = () => {
  const {
    state: {
      loading,
      loanApplicationId,
      shareholderId,
      stage,
      isMainApplicant,
      totalShareholders,
    },
  } = useLoanOffer();
  const { currentStep, steps } = useStepper();
  const postHog = usePostHog();

  useEffect(() => {
    if (!loading && loanApplicationId) {
      postHog.reset();
      postHog.identify(loanApplicationId, {
        loanApplicationId,
      });
      datadogRum.clearUser();
      datadogRum.setUser({
        id: loanApplicationId,
        loanApplicationId,
      });
    }
  }, [loading, loanApplicationId]);

  useEffect(() => {
    if (!loading && loanApplicationId && currentStep) {
      postHog.capture(
        `Loan Offer Microflow: Moved to step ${steps[currentStep[0]].name}`,
        {
          shareholderId: shareholderId,
          loanApplicationId: loanApplicationId,
          stage: stage,
          isMainApplicant: isMainApplicant,
          totalShareholders: totalShareholders,
        }
      );
      datadogRum.addAction(
        `Loan Offer Microflow: Moved to step ${steps[currentStep[0]].name}`,
        {
          shareholderId: shareholderId,
          loanApplicationId: loanApplicationId,
          stage: stage,
          isMainApplicant: isMainApplicant,
          totalShareholders: totalShareholders,
        }
      );
    }
  }, [currentStep, loanApplicationId, loading]);

  return (
    <>
      {loading ? (
        <div className="cx-animate-spin cx-text-brand-primary-regular cx-w-10 cx-h-10">
          <ButtonLoaderIcon width="100%" height="100%" />
        </div>
      ) : (
        steps[currentStep[0]].subSteps[currentStep[1]].component
      )}
    </>
  );
};

export default LoanOfferForm;
