export const roundTo = (num: number, dig: number) => {
  return Math.round(num * Math.pow(10, dig)) / Math.pow(10, dig);
};

export function calculateCompoundInterest(
  amount: number,
  timeInYears: number,
  interestRatePerPeriod: number
) {
  return amount * Math.pow(1 + interestRatePerPeriod / 365, 365 * timeInYears);
}

export function calculateTotalPayment(
  amount: number,
  days: number,
  interestRate: number
) {
  return amount * Math.pow(1 + interestRate / 365, 365 * days);
}

export function calculateTotalPaymentForRecieveableFinancing(
  amount: number,
  days: number,
  interestRate: number
) {
  return amount * Math.pow(1 - interestRate / 365, 365 * days);
}

export function calculateTotalPaymentForBuyNowPayLater(
  amount: number,
  duration: number,
  interestRate: number
) {
  const numInstallments = duration + 1;
  const emiWithoutInterest = amount / numInstallments;
  const chargeablePrincipalAmount = amount - emiWithoutInterest;
  const totalInterest =
    (chargeablePrincipalAmount * interestRate * duration) / 12;
  const totalPayment = amount + totalInterest;
  return totalPayment;
}

export function calculateMonthlyPaymentForBuyNowPayLater(
  amount: number,
  duration: number,
  interestRate: number
) {
  const totalPayment = calculateTotalPaymentForBuyNowPayLater(
    amount,
    duration,
    interestRate
  );
  const numInstallments = duration + 1;
  return totalPayment / numInstallments;
}

export function calculateTotalPaymentForPosRevenueBasedFinancing(
  amount: number,
  factorRate: number
) {
  return amount * factorRate;
}

/**
 *
 * @param n {number} number
 * @returns {string} word format of the given number
 * @description source: https://stackoverflow.com/a/63109585
 */
export const number2words = (n: number): string => {
  // BEGIN-NOSCAN
  let string = n.toString();
  let units;
  let tens;
  let scales;
  let start;
  let end;
  let chunks;
  let chunksLen;
  let chunk;
  let ints;
  let i;
  let word;
  let words;
  let and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split("").reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
  // END-NOSCAN
};
