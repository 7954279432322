import { FC, useState } from "react";
import { Button } from "../../../../components";
import { PlusIcon } from "../../../../assets/icons";
import { useLoanApplication } from "../../../../contexts";
import { getVendorNature, getVendorTitle } from "../../VendorInformation/utils";
import { LoanProductType, Vendor } from "../../../../hooks/api/types";
import { useDeleteVendor, useGetApplicationVendors } from "../../../../hooks";
import VendorCard from "../../VendorInformation/VendorCard";
import VendorInformationModal from "../../VendorInformation/VendorInformationModal";

type Props = {};

const VendorReviewSection: FC<Props> = ({}) => {
  const {
    state: { loanType, id },
  } = useLoanApplication();
  const { data: vendors, refetch } = useGetApplicationVendors({
    loanApplicationId: id,
    pageSize: Number.MAX_SAFE_INTEGER,
  });
  const { mutateAsync: deleteVendor } = useDeleteVendor();

  const [focusedVendor, setFocusedVendor] = useState<Vendor | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10 ">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full cx-mb-6">
        <div className="cx-text-2xl cx-font-bold">{`${getVendorTitle(
          loanType as LoanProductType
        )} Information`}</div>
        <Button
          label={
            <div className="cx-flex cx-items-center">
              <PlusIcon className="cx-mr-2" />
              <div>{`Add ${getVendorNature(loanType as LoanProductType)}`}</div>
            </div>
          }
          slim={true}
          onClick={() => {
            setFocusedVendor(undefined);
            setIsModalOpen(true);
          }}
        />
      </div>
      <div className={"cx-flex cx-flex-row cx-flex-wrap cx-justify-between"}>
        {vendors?.data.map((vendor) => {
          return (
            <VendorCard
              vendor={vendor}
              onDelete={async () => {
                await deleteVendor({
                  loanApplicationId: id,
                  loanVendorId: vendor.loanVendorId,
                });
                await refetch();
              }}
              onEdit={() => {
                setFocusedVendor(vendor);
                setIsModalOpen(true);
              }}
              className={"cx-w-[428px]"}
            />
          );
        })}
      </div>
      <VendorInformationModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setFocusedVendor(undefined);
        }}
        onAddVendor={() => {
          refetch();
        }}
        vendorState={focusedVendor}
      />
    </div>
  );
};

export default VendorReviewSection;
