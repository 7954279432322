import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Combobox,
  InputField,
  NumericInputField,
  StepNextButton,
  Divider,
  Button,
} from "../../../components";
import {
  LicensingAuthorityType,
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../../contexts";
import {
  ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER,
  InformationFormFields,
  INFORMATION_FORM_INITIAL_VALUES,
  TAX_PERCENTAGE_MULTIPLIER,
  LicensingAuthorityFormField,
  getEffectiveInformationFormValidationSchema,
} from "./service";
import { LoanApplicationStage } from "../../../hooks/api/types";
import {
  useValidateLoanApplicationUniqueness,
  useGetLicensingAuthorities,
  useInitiateCopy,
  useSendOTPByLoanApplicationId,
  useMedia,
} from "../../../hooks";
import { useDpConfig } from "../../../contexts/dpConfig";
import { ExistingProfileIcon } from "../../../assets/icons";
import { useStepper } from "../Stepper";
import { updateLoansStateForLegacyFlow } from "../../LoanCalculator/service";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";

const BusinessInformationForm = () => {
  const {
    state,
    actions: { update, refetch },
  } = useLoanApplication();
  const { state: calculatorState } = useLoanCalculator();
  const { nextStep } = useContext(StepperContext);
  const { mutateAsync: getLicensingAuthorities } = useGetLicensingAuthorities();

  const { mutateAsync: validateLoanApplicationUniqueness } =
    useValidateLoanApplicationUniqueness();
  const { mutateAsync: initiateCopy } = useInitiateCopy();
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const { disableRevenueHistory } = useLoanProductConfigFlags(state.loanType);
  const { gotoStep } = useStepper();

  const [licensingAuthorities, setLicensingAuthorities] = useState<
    Array<LicensingAuthorityFormField>
  >([]);
  const [profileAlreadyExists, setProfileAlreadyExists] =
    useState<boolean>(false);
  const [isSamePhoneNumber, setIsSamePhoneNumber] = useState(false); // true if the phone entered earlier is same as duplicate loan application found with same TL and licensing authority
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [
    loanApplicationIdFromExistingProfile,
    setLoanApplicationIdFromExistingProfile,
  ] = useState("");
  const { mutateAsync: sendOTPByLoanApplicationId } =
    useSendOTPByLoanApplicationId();
  const { upload } = useMedia();

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik<InformationFormFields>({
    initialValues: {
      ...INFORMATION_FORM_INITIAL_VALUES,
      tradeLicenseNumber: state.tradeLicenseNumber,
      annualRevenue: state.annualRevenue,
      taxRegistrationNumber: state.taxRegistrationNumber,
      lastYearPaidTax: state.lastYearPaidTax,
      licensingAuthorityId: state.licensingAuthorityId,
      ...(disableRevenueHistory
        ? { annualRevenue: 0, taxRegistrationNumber: "", lastYearPaidTax: 0 }
        : {}),
    },
    validationSchema: getEffectiveInformationFormValidationSchema(
      disableRevenueHistory!
    ),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        isDuplicateApplication,
        isPhoneNumberMatching,
        sourceId: loanApplicationIdFromExistingProfile,
        phoneNumber,
      } = await validateLoanApplicationUniqueness({
        tradeLicenseNumber: values.tradeLicenseNumber,
        licensingAuthority: values.licensingAuthorityId,
        loanId: state.id,
      });

      if (isDuplicateApplication) {
        setProfileAlreadyExists(isDuplicateApplication);
        setIsSamePhoneNumber(isPhoneNumberMatching);
        setLoanApplicationIdFromExistingProfile(
          loanApplicationIdFromExistingProfile
        );
        setMaskedPhoneNumber(phoneNumber);
      } else {
        update({
          tradeLicenseNumber: values.tradeLicenseNumber,
          annualRevenue: values.annualRevenue,
          taxRegistrationNumber: values.taxRegistrationNumber,
          lastYearPaidTax: values.lastYearPaidTax,
          licensingAuthorityId: values.licensingAuthorityId,
          stage: LoanApplicationStage.COMPANY_INFORMATION,
        })
          .then(() => {
            nextStep();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  });

  const shouldApplyTax = useMemo(() => {
    return values.annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
  }, [values.annualRevenue]);

  useEffect(() => {
    setFieldValue(
      "lastYearPaidTax",
      shouldApplyTax ? values.annualRevenue * TAX_PERCENTAGE_MULTIPLIER : 0
    );
  }, [shouldApplyTax, values.annualRevenue]);

  useEffect(() => {
    const initialize = async () => {
      const res = await getLicensingAuthorities();
      const _authorities = res.map((e: LicensingAuthorityType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setLicensingAuthorities(_authorities);
    };
    initialize().catch((err) => {
      console.log(err);
    });
  }, []);

  const copyFromExistingProfile = async () => {
    if (loanApplicationIdFromExistingProfile) {
      await initiateCopy({
        loanApplicationId: state.id,
        refId: loanApplicationIdFromExistingProfile,
      });
      if (!disableLOCFlow) {
        const { loanType } = await update({
          id: state.id,
          sourceId: loanApplicationIdFromExistingProfile,
          stage: LoanApplicationStage.CALCULATOR,
        });
        // this is needed because FE moves to calculator before refetching is done
        // and loanType is still undefined which is needed to load correct config on calculator screen
        if (loanType) {
          await update(
            {
              loanType: loanType,
            },
            {
              local: true,
            }
          );
        }
      } else {
        // make updates for legacy flow
        await updateLoansStateForLegacyFlow(
          state.id,
          calculatorState,
          update,
          upload,
          state.invoiceFiles,
          state.salesStatementFiles,
          state.salesInvoiceFiles
        );
      }
      await refetch();
    }
  };

  const goToOtpForm = async () => {
    await sendOTPByLoanApplicationId({
      loanApplicationId: loanApplicationIdFromExistingProfile,
    });
    await update(
      {
        sourceId: loanApplicationIdFromExistingProfile,
        localNumber: maskedPhoneNumber,
      },
      {
        local: true,
      }
    ); // update sourceId from which we copy if otp is successfull

    if (!disableLOCFlow) {
      // if we are in LOC flow
      gotoStep([0, 3]); // Otp form step
    } else {
      gotoStep([1, 2]); // Otp form step
    }
  };

  if (profileAlreadyExists) {
    return (
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-max-w-[600px] cx-w-full">
        <ExistingProfileIcon />
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
          Existing Profile Available!
        </div>
        <div className="cx-text-text-secondary cx-font-normal cx-text-base cx-text-center cx-mb-10">
          We've found an existing profile for you with the current trade license
          and economic zone. You can update the trade license number to proceed,
          or simply continue with your existing profile to submit your request.
        </div>
        <div className="cx-flex cx-flex-row cx-gap-4 cx-w-full">
          <Button
            label="Add new trade license number"
            outlined
            fullWidth
            onClick={() => {
              setProfileAlreadyExists(false);
              setIsSamePhoneNumber(false);
              setLoanApplicationIdFromExistingProfile("");
            }}
          />
          <Button
            label="Continue with existing profile"
            fullWidth
            onClick={async () => {
              if (isSamePhoneNumber) {
                // continue by copying data from previous application
                await copyFromExistingProfile();
              } else {
                // previous number entered does not match with profile found so ask for OTP again
                await goToOtpForm();
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Tell us about your business
      </div>
      <div className="cx-max-w-[400px] cx-w-full">
        <form onSubmit={handleSubmit}>
          <div className="cx-mb-4">
            <InputField
              label="Trade license number"
              tick={touched.tradeLicenseNumber && !errors.tradeLicenseNumber}
              error={
                touched.tradeLicenseNumber && !!errors.tradeLicenseNumber
                  ? errors.tradeLicenseNumber
                  : ""
              }
              inputProps={{
                name: "tradeLicenseNumber",
                value: values.tradeLicenseNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              hint="Please add your trade license number exactly as it appears on your license, including any special characters."
            />
          </div>
          <div className="">
            <Combobox
              label="Licensing Authority"
              placeholder="Select licensing authority"
              options={licensingAuthorities}
              inputProps={{
                name: "licensingAuthorityId",
                value: licensingAuthorities.find(
                  (e) => e.value === values.licensingAuthorityId
                ),
                onBlur: () => setFieldTouched("licensingAuthorityId"),
              }}
              onChange={handleChange("licensingAuthorityId")}
              error={
                touched.licensingAuthorityId && !!errors.licensingAuthorityId
                  ? errors.licensingAuthorityId
                  : ""
              }
            />
          </div>
          <div className="cx-my-4">
            {!disableRevenueHistory && (
              <NumericInputField
                label="Previous year annual revenue"
                isValid={touched.annualRevenue && !errors.annualRevenue}
                value={values.annualRevenue}
                error={
                  touched.annualRevenue && !!errors.annualRevenue
                    ? errors.annualRevenue
                    : ""
                }
                setValue={(value) => {
                  setFieldValue("annualRevenue", value);
                }}
              />
            )}
          </div>
          {shouldApplyTax && (
            <>
              <div className="cx-my-4">
                <InputField
                  label="Tax registration number"
                  tick={
                    touched.taxRegistrationNumber &&
                    !errors.taxRegistrationNumber
                  }
                  error={
                    touched.taxRegistrationNumber &&
                    !!errors.taxRegistrationNumber
                      ? errors.taxRegistrationNumber
                      : ""
                  }
                  inputProps={{
                    name: "taxRegistrationNumber",
                    value: values.taxRegistrationNumber,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
              <div className="cx-mb-4">
                <NumericInputField
                  label="VAT Paid in the Previous Year"
                  isValid={touched.lastYearPaidTax && !errors.lastYearPaidTax}
                  value={values.lastYearPaidTax}
                  error={
                    touched.lastYearPaidTax && !!errors.lastYearPaidTax
                      ? errors.lastYearPaidTax
                      : ""
                  }
                  setValue={(value) => {
                    setFieldValue("lastYearPaidTax", value);
                  }}
                  onBlur={() => setFieldTouched("lastYearPaidTax")}
                />
              </div>
            </>
          )}
          <div className="cx-my-10">
            <Divider />
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton loading={state.updating} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessInformationForm;
