import { convertBnplMonthsToInstallments } from "../../utils";

const PAGE_COPY: {
  [key: string]: {
    default: (...args: any[]) => string;
    repayableByDP: (...args: any[]) => string;
  };
} = {
  LOAN_TYPE: {
    default: () => "Loan Type",
    repayableByDP: () => "Loan Type",
  },
  INTEREST_LABEL: {
    default: () => "Indicative interest rate",
    repayableByDP: () => "Indicative monthly fee",
  },
  REPAYMENT_LABEL: {
    default: () => "Total repayment amount",
    repayableByDP: () => "Total payable amount",
  },
  DURATION_LABEL: {
    default: () => "Duration",
    repayableByDP: () => "Invoice date",
  },
  DURATION: {
    default: (duration: number, durationType: string) =>
      `${duration} ${durationType}`,
    repayableByDP: (invoiceDate: string) => invoiceDate,
  },
  DURATION_LABEL_BNPL: {
    default: () => "No. of installments",
    repayableByDP: () => "No. of installments",
  },
  DURATION_BNPL: {
    default: (duration: number) =>
      `${convertBnplMonthsToInstallments(duration)} installments`,
    repayableByDP: () => "",
  },
  AMOUNT_LABEL: {
    default: () => "Loan amount",
    repayableByDP: () => "Invoice amount",
  },
};

export function getCopyText(key: string, repayableByDPFlag?: boolean) {
  return PAGE_COPY[key][repayableByDPFlag ? "repayableByDP" : "default"];
}
