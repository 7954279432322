import { useContext } from "react";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { LoanApplicationStage } from "../../../hooks/api/types";
import SumSubKyc from "./SumSubKyc";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";

const OwnersKycForm = () => {
  const { nextStep } = useContext(StepperContext);
  const {
    state: { loanType },
    actions: { update },
  } = useLoanApplication();
  const { enableVendorScreen } = useLoanProductConfigFlags(loanType);

  const { shouldHideFinancialInformation } =
    useLoanProductConfigFlags(loanType);

  const handleNext = async () => {
    try {
      await update({
        stage: shouldHideFinancialInformation
          ? enableVendorScreen
            ? LoanApplicationStage.VENDOR_INFORMATION
            : LoanApplicationStage.REVIEW
          : LoanApplicationStage.FINANCIAL_INFORMATION,
      });
      nextStep();
    } catch (err) {
      console.log(err);
    }
  };

  return <SumSubKyc onSubmit={handleNext} />;
};

export default OwnersKycForm;
