import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../contexts";
import {
  UploadInvoiceFields,
  LOAN_UPLOAD_INVOICE_INITIAL_VALUES,
  LOAN_UPLOAD_INVOICE_VALIDATION_SCHEMA,
  handleInvoiceDiscountingLoanUpdate,
  handlePayableFinancingLoanUpdate,
} from "./service";
import { useLoanMetrics, useMedia } from "../../hooks";
import { LoanApplicationStage, LoanProductType } from "../../hooks/api/types";
import UploadInvoiceForm from "./UploadInvoiceForm";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

const LoanUploadInvoice = () => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repayableByDP, setRepayableByDP] = useState(false);
  const [
    invoiceDiscountingRepayableByDPFlag,
    setInvoiceDiscountingRepayableByDPFlag,
  ] = useState(false);
  const [
    payableFinancingRepayableByDPFlag,
    setPayableFinancingRepayableByDPFlag,
  ] = useState(false);

  const {
    state: { id, invoiceFiles, sourceId, loanType },
    actions: { update },
  } = useLoanApplication();

  const { state: calculatorState } = useLoanCalculator();
  const {
    disableSalesInvoiceUpload,
    disableSalesStatementsUpload,
    enableVendorScreen,
  } = useLoanProductConfigFlags(loanType);
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();

  const { upload } = useMedia();

  const { nextStep, previousStep } = useContext(StepperContext);

  const { invoiceFinanceProduct, payableFinancingProduct } = useLoanMetrics();

  const {
    state: { payableFinancingLoan, invoiceDiscountingLoan },
    actions: { setPayableFinancingLoan, setInvoiceDiscountingLoan },
  } = useLoanCalculator();

  useEffect(() => {
    setInvoiceDiscountingRepayableByDPFlag(
      invoiceFinanceProduct?.flags?.repayableByDP
    );
    setPayableFinancingRepayableByDPFlag(
      payableFinancingProduct?.flags?.repayableByDP
    );
  }, [invoiceFinanceProduct, payableFinancingProduct]);

  useEffect(() => {
    setRepayableByDP(
      invoiceDiscountingRepayableByDPFlag || payableFinancingRepayableByDPFlag
    );
  }, [invoiceDiscountingRepayableByDPFlag, payableFinancingRepayableByDPFlag]);

  async function updateLoanApplication() {
    if (calculatorState.type === LoanProductType.INVOICE_DISCOUNTING) {
      await handleInvoiceDiscountingLoanUpdate(
        id,
        invoiceFiles,
        calculatorState,
        update,
        upload,
        setLoading
      );
    } else if (calculatorState.type === LoanProductType.PAYABLE_FINANCING) {
      await handlePayableFinancingLoanUpdate(
        id,
        invoiceFiles,
        calculatorState,
        update,
        upload,
        setLoading
      );
    }
  }

  useEffect(() => {
    //only run if in LOC flow mode
    if (!disableLOCFlow) {
      if (invoiceFiles.length) {
        setLoading(true);
        updateLoanApplication()
          .then(async () => {
            if (sourceId) {
              if (enableVendorScreen)
                await update({
                  stage: LoanApplicationStage.VENDOR_INFORMATION,
                });
              else {
                await update({ stage: LoanApplicationStage.REVIEW });
              }
            } else {
              await update({ stage: LoanApplicationStage.OWNER_INFORMATION });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [invoiceFiles, disableLOCFlow]);

  const formik = useFormik<UploadInvoiceFields>({
    initialValues: {
      ...LOAN_UPLOAD_INVOICE_INITIAL_VALUES,
      invoiceAmount:
        loanType === LoanProductType.PAYABLE_FINANCING
          ? payableFinancingLoan.invoices[0].amount
          : invoiceDiscountingLoan.invoices[0].amount,
    },
    validationSchema: LOAN_UPLOAD_INVOICE_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      if (loanType === LoanProductType.PAYABLE_FINANCING) {
        setPayableFinancingLoan({
          invoices: payableFinancingLoan.invoices.map((invoice) => {
            return {
              ...invoice,
              invoiceDueDate: values.invoiceDueDate
                ? values.invoiceDueDate
                : new Date(),
              invoiceIssuedDate: values.invoiceIssuedDate
                ? values.invoiceIssuedDate
                : new Date(),
              invoiceNo: values.invoiceNo ? values.invoiceNo : "",
              vendorName: values.vendorName,
            };
          }),
        });
      }
      if (loanType === LoanProductType.INVOICE_DISCOUNTING) {
        setInvoiceDiscountingLoan({
          invoices: invoiceDiscountingLoan.invoices.map((invoice) => {
            return {
              ...invoice,
              invoiceDueDate: values.invoiceDueDate
                ? values.invoiceDueDate
                : new Date(),
              invoiceIssuedDate: values.invoiceIssuedDate
                ? values.invoiceIssuedDate
                : new Date(),
              invoiceNo: values.invoiceNo ? values.invoiceNo : "",
              vendorName: values.vendorName,
            };
          }),
        });
      }
      try {
        await update(
          {
            loanType: calculatorState.type,
            invoiceFiles: values.invoices,
          },
          { local: true }
        );
        if (!sourceId) {
          !disableSalesStatementsUpload && nextStep();
          !disableSalesInvoiceUpload && nextStep();
          nextStep();
          nextStep();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleUpload = (file: File) => {
    setUploading(true);
    formik.setFieldValue("invoices", [file]);
    setUploading(false);
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-8 cx-max-w-[960px]">
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-gap-2">
        <div className="cx-text-3xl cx-font-bold cx-text-center">
          Upload invoice
        </div>
        <div className="cx-text-text-secondary cx-text-center">
          Please note the invoicing party must be the same as the entity
          applying for the loan, and all invoices must be in AED.
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <UploadInvoiceForm
          formik={formik}
          handleUpload={handleUpload}
          uploading={uploading || loading}
          repayableByDP={repayableByDP}
          previousStep={previousStep}
          onReviewScreen={false}
        />
      </div>
    </div>
  );
};

export default LoanUploadInvoice;
