import { BulletIcon, CardList } from "../../components";
import { HeavyCheckIcon } from "../../assets/icons";
import { useLoanOffer } from "../../contexts";

const nextSteps = [
  {
    name: "Signature review",
    description: "Bank to review and approve your direct debit form.",
  },
  {
    name: "Receive and sign loan contract",
    description: "Last step, we will email your loan contract to sign.",
  },
];

const LoanOfferReview = () => {
  const {
    state: { loanConfig },
  } = useLoanOffer();
  const isDirectDebitRequired = loanConfig?.directDebitRequired;

  const filteredSteps = isDirectDebitRequired ? nextSteps : nextSteps.slice(1); // Removes the first step if directDebitRequired is false

  return (
    <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
      <HeavyCheckIcon />
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-my-2">
        All good!
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        {isDirectDebitRequired
          ? `Direct Debit is submitted to your loan application.`
          : `You've successfully accepted the loan offer.`}
        <br />
        Here is what to expect next:
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-w-full cx-py-10 cx-flex cx-flex-col cx-gap-y-6">
          {filteredSteps.map((step, index) => (
            <CardList
              key={step.name}
              title={step.name}
              description={step.description}
              icon={<BulletIcon text={String(index + 1)} />}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoanOfferReview;
