import { FC, useContext } from "react";
import { Divider, InputField, StepNextButton } from "../../../components";
import { InfoIcon } from "../../../assets/icons";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { useGetLoanApplicationManualAccounts } from "../../../hooks";
import { useDpConfig } from "../../../contexts/dpConfig";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";

type ManualAccountsFields = {
  accountName: string;
  iban: string;
  currency: string;
  bankName: string;
};

const ManualAccounts: FC = () => {
  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { enableVendorScreen } = useLoanProductConfigFlags(state.loanType);

  const { nextStep } = useContext(StepperContext);
  const { data: manualAccounts, isLoading } =
    useGetLoanApplicationManualAccounts(
      // For now there will be only one account ...but the api returns an array of account incase we allow multiple accounts. when that happens this design will change
      state.id ?? ""
    );

  const { showMerchantIdStep } = useLoanProductConfigFlags(state.loanType);
  const {
    state: { name: dpName },
  } = useDpConfig();

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full ">
      <div className="cx-max-w-[400px] cx-w-full">
        <div className="cx-text-3xl cx-font-bold cx-text-center cx-mb-3">
          Bank account details
        </div>

        {manualAccounts?.accounts && manualAccounts?.accounts.length > 0 ? (
          <div>
            <p className="cx-text-center cx-text-text-secondary cx-mb-7">
              Your loan amount will be disbursed in this account
            </p>

            <div className="cx-mb-4">
              <InputField
                label="Account name"
                inputProps={{
                  name: "accountName",
                  value: manualAccounts.accounts[0].name,
                  disabled: true,
                }}
              />
            </div>
            <div className="cx-mb-4">
              <InputField
                label="IBAN"
                inputProps={{
                  name: "iban",
                  value: manualAccounts.accounts[0].iban,
                  disabled: true,
                }}
              />
            </div>
            <div className="cx-mb-4">
              <InputField
                label="Currency"
                inputProps={{
                  name: "currency",
                  value: manualAccounts.accounts[0].currency,
                  disabled: true,
                }}
              />
            </div>
            <div className="cx-mb-4">
              <InputField
                label="Bank name"
                inputProps={{
                  name: "bankName",
                  value: manualAccounts.accounts[0].bankName,
                  disabled: true,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="cx-text-text-secondary cx-text-center cx-w-full cx-bg-brand-primary-lighter cx-p-12">
            <div>
              Your payments will be transferred to the account which is linked
              with {dpName}
            </div>
          </div>
        )}

        <div className="cx-my-6">
          <Divider />
        </div>

        <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
          <StepNextButton
            label="Next"
            onClick={async () => {
              // If the next step is merchant id, we should not update loan stage to reivew
              if (!showMerchantIdStep) {
                await update({
                  stage: enableVendorScreen
                    ? LoanApplicationStage.VENDOR_INFORMATION
                    : LoanApplicationStage.REVIEW,
                });
              }
              nextStep();
            }}
          />
        </div>

        <div className="cx-text-text-secondary cx-pt-4 cx-flex cx-items-center cx-ml-4">
          <InfoIcon width={20} height={18} stroke="red" />
          <div className="cx-ml-2 cx-pt-3">
            In case your account details are incorrect please email us at
            <a
              href="mailto:support@crediblex.io"
              className="cx-text-interaction-button-primary-default cx-underline"
            >
              {" "}
              support@crediblex.io
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualAccounts;
