import { useEffect, useState } from "react";
import { PlusIcon } from "../../../assets/icons";
import { Button, InputField, Modal } from "../../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useDeleteMastercardMerchantIds,
  useGetMastercardMerchantIds,
  useSaveMastercardMerchantIds,
  useUpdateMastercardMerchantIds,
} from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import EditButton from "./EditButton";
import { DeleteButton } from "./ShareholderInfo";

const validationSchema = Yup.object({
  merchantId: Yup.string()
    .required("Merchant ID is required")
    .max(15, "Merchant ID must be at most 15 characters"),
});

export const ModalTypes = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
} as const;

export type ModalType = (typeof ModalTypes)[keyof typeof ModalTypes];

type MerchantId = {
  id: string; // this is merchant data entity id (need to use it for update)
  merchantId: string;
};

export const MerchantIdInformationSection = () => {
  const [merchantIdModalOpen, setMerchantIdModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalTypes.ADD); // 'add', 'edit', or 'delete'
  const [currentMerchantId, setCurrentMerchantId] = useState<MerchantId | null>(
    null
  ); // For edit or delete
  const [merchantIds, setMerchantIds] = useState<MerchantId[]>([]);

  const {
    state: { id },
  } = useLoanApplication();
  const { mutateAsync: getMastercardMerchantIds } =
    useGetMastercardMerchantIds();
  const { mutateAsync: saveMastercardMerchantIds, isLoading } =
    useSaveMastercardMerchantIds();
  const { mutateAsync: deleteMastercardMerchantIds } =
    useDeleteMastercardMerchantIds();
  const { mutateAsync: updateMastercardMerchantIds } =
    useUpdateMastercardMerchantIds();

  // Formik for managing merchant ID input
  const formik = useFormik({
    initialValues: { merchantId: "" },
    validationSchema,
    onSubmit: (values) => {
      if (modalType === ModalTypes.DELETE && currentMerchantId !== null) {
        handleDeleteMerchantId();
      } else if (modalType === ModalTypes.ADD) {
        saveMastercardMerchantIds({
          merchantIds: [values.merchantId],
          loanApplicationId: id,
        })
          .then(() => {
            getMerchantIds();
          })
          .catch((error) => {
            console.error("Error saving Merchant IDs:", error);
          });
      } else if (modalType === ModalTypes.EDIT && currentMerchantId !== null) {
        updateMastercardMerchantIds({
          loanApplicationId: id,
          merchantDataId: currentMerchantId.id,
          merchantId: values.merchantId,
        })
          .then(() => {
            setMerchantIds(
              merchantIds.map((item) =>
                item.merchantId === currentMerchantId.merchantId
                  ? {
                      ...item,
                      merchantId: values.merchantId,
                    }
                  : item
              )
            );
          })
          .catch((error) => {
            console.error("Error saving Merchant IDs:", error);
          });
      }
      setMerchantIdModalOpen(false);
      formik.resetForm();
    },
  });

  const handleDeleteMerchantId = async () => {
    try {
      if (currentMerchantId === null) return;

      await deleteMastercardMerchantIds({
        merchantIds: [currentMerchantId.merchantId],
        loanApplicationId: id,
      });

      setMerchantIds(
        merchantIds.filter(
          (item) => item.merchantId !== currentMerchantId.merchantId
        )
      );
    } catch (error) {
      console.error("Error deleting Merchant IDs:", error);
    }
  };

  const openModal = (
    type: ModalType,
    selectedMerchantItem: null | MerchantId = null
  ) => {
    setModalType(type);
    setCurrentMerchantId(selectedMerchantItem);
    setMerchantIdModalOpen(true);
    if (selectedMerchantItem) {
      formik.setValues({ merchantId: selectedMerchantItem.merchantId });
    }
  };

  const getMerchantIds = async () => {
    const res: MerchantId[] = await getMastercardMerchantIds(id);
    setMerchantIds(res);
  };

  useEffect(() => {
    getMerchantIds();
  }, []);

  const modalTitle = (() => {
    switch (modalType) {
      case ModalTypes.ADD:
        return "Add new Merchant ID";
      case ModalTypes.EDIT:
        return "Edit Merchant ID";
      case ModalTypes.DELETE:
        return "Delete Merchant ID";
      default:
        return "Merchant ID";
    }
  })();

  const getButtonLabel = (type: ModalType): string => {
    switch (type) {
      case ModalTypes.ADD:
        return "Add";
      case ModalTypes.DELETE:
        return "Delete Merchant ID";
      case ModalTypes.EDIT:
        return "Save Changes";
      default:
        return "";
    }
  };

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">
          Merchant ID’s Information
        </div>
        <Button
          label={
            <div className="cx-flex cx-items-center">
              <PlusIcon className="cx-mr-2" />
              <div>Add Merchant ID</div>
            </div>
          }
          slim={true}
          onClick={() => openModal("add")}
        />
      </div>
      <div className="cx-grid cx-grid-cols-2 cx-gap-4 cx-w-full cx-mt-4">
        {merchantIds.map((item) => (
          <div
            key={item.merchantId}
            className="cx-w-full cx-gap-x-4 cx-border cx-border-gray-300 cx-rounded-lg cx-flex cx-items-center cx-justify-between cx-p-4"
          >
            <div className="cx-font-medium cx-text-gray-800">
              {item.merchantId}
            </div>
            <div className="cx-flex cx-items-center cx-space-x-2">
              <EditButton onClick={() => openModal("edit", item)} />
              <DeleteButton onClick={() => openModal("delete", item)} />
            </div>
          </div>
        ))}
      </div>

      <Modal
        className="cx-w-full"
        isOpen={merchantIdModalOpen}
        onClose={() => setMerchantIdModalOpen(false)}
        title={modalTitle}
        subtitle={
          modalType === ModalTypes.DELETE
            ? "Are you sure you want to delete this merchant ID?"
            : ""
        }
      >
        <div className="cx-w-full">
          <form onSubmit={formik.handleSubmit}>
            <InputField
              label="Merchant ID"
              inputProps={{
                name: "merchantId",
                value: formik.values.merchantId,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                readOnly: modalType === ModalTypes.DELETE,
                disabled: modalType === ModalTypes.DELETE,
              }}
              tick={formik.touched.merchantId && !formik.errors.merchantId}
              error={
                formik.touched.merchantId && formik.errors.merchantId
                  ? formik.errors.merchantId
                  : ""
              }
            />
            <div className="cx-flex cx-flex-col cx-mt-4">
              {modalType === ModalTypes.DELETE ? (
                <Button
                  label={getButtonLabel(modalType)}
                  type="submit"
                  danger
                />
              ) : (
                <Button
                  label={getButtonLabel(modalType)}
                  type="submit"
                  disabled={
                    !(formik.touched.merchantId && !formik.errors.merchantId) ||
                    isLoading
                  }
                />
              )}
              <Button
                label="Cancel"
                fullWidth={true}
                secondary={true}
                onClick={() => setMerchantIdModalOpen(false)}
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
