import { useContext, useEffect } from "react";
import { BulletIcon, CardList, StepNextButton } from "../../components";
import {
  useApproveLoanOffer,
  useDirectDebitBankAccounts,
  useUpdateLoanOfferStage,
} from "../../hooks";
import { calculateNextStage } from "./LoanOfferStart";
import { StepperContext, useLoanOffer } from "../../contexts";
import { STEP_STAGE_NAME_MAP } from ".";
import { LoanOfferStage } from "../../hooks/api/types";

const steps = [
  {
    name: "Bank account information",
    description: "Provide bank account information for repayments.",
  },
  {
    name: "Bank account signature",
    description: "Provider signature to authorize bank direct debit.",
  },
  {
    name: "Central bank review",
    description: "Await approval from the central bank.",
  },
];

const SetupDirectDebitIntro = () => {
  const { mutateAsync: getBankAccounts, data: bankAccounts } =
    useDirectDebitBankAccounts();
  const { mutateAsync: approveLoanOffer } = useApproveLoanOffer();
  const { mutateAsync: updateLoanOfferStage } = useUpdateLoanOfferStage();
  const {
    state: { id: loanOfferId, isMainApplicant, loanConfig },
  } = useLoanOffer();
  const { gotoStep } = useContext(StepperContext);

  const handleSetup = async () => {
    /**
     * if the direct Debit flow is not Required, go straight to complete.
     *
     * If the shareholder is the main applicant:
     *  - If the shareholder has bank accounts, go to select bank account
     *  - If the shareholder does not have bank accounts, go to add bank account
     * If the shareholder is not the main applicant:
     *  - Go to select sign mode
     *
     */

    const updatedStage = calculateNextStage(
      isMainApplicant,
      !!bankAccounts?.length,
      loanConfig?.directDebitRequired
    );

    const nextStepToProceed = STEP_STAGE_NAME_MAP[updatedStage];

    if (updatedStage === LoanOfferStage.COMPLETED) {
      await approveLoanOffer({ id: loanOfferId });
    }

    await updateLoanOfferStage({
      id: loanOfferId,
      stage: updatedStage,
    });

    gotoStep(nextStepToProceed);
  };

  useEffect(() => {
    getBankAccounts(loanOfferId);
  }, []);

  return (
    <div className="cx-grow cx-w-full cx-max-w-[960px] cx-flex cx-flex-col cx-items-center cx-justify-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-my-2">
        Set up direct debit
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        Setting up direct debit from your bank account ensures repayments paid
        on time.
        <br />
        Here's what you need to do:
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-w-full cx-py-10 cx-flex cx-flex-col cx-gap-y-6">
          {steps.map((step, index) => (
            <CardList
              key={step.name}
              title={step.name}
              description={step.description}
              icon={<BulletIcon text={String(index + 1)} />}
            />
          ))}
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <StepNextButton label="Set up" onClick={handleSetup} />
      </div>
    </div>
  );
};

export default SetupDirectDebitIntro;
