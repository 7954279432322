import { Button, Modal } from "../../../components";
import { CalendarIcon, InfoIcon, TickIcon } from "../../../assets/icons";
import { formatISOString, isFuture } from "../../../utils";
import { LoanApplication, useLoanDashboard } from "../../../contexts";
import { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  AdditionalInfo,
  AdditionalInfoType,
  MediaPurpose,
  ShareholderEntityType,
  LoanApplicationStatus,
  LoanProductType,
} from "../../../hooks/api/types";
import {
  useUpdateCompanyShareholder,
  useUpdateIndividualShareholder,
  useUpdateLoanAdditionalInfo,
} from "../../../hooks";
import MissingDocumentsUpload from "./MissingInformation/MissingDocumentUpload";
import AddShareholder from "../../LoanApplicationSteps/OwnersInformation/AddShareholder";
import FinanceInformationSelect from "./MissingInformation/FinanceInformationSelect";
import LinkBankAccount from "./MissingInformation/LinkBankAccount";
import { ShareholderFields } from "../../LoanApplicationSteps/OwnersInformation/service";
import { AWAITABLE_STATUSES } from "./CONSTANTS";
import { useDpConfig } from "../../../contexts/dpConfig";
import { LoanProduct } from "../../../contexts/dpConfig/types";

type LoanCardActionsProps = {
  loan: LoanApplication;
};

const nextPaymentCopyMapping = new Map([
  [
    LoanProductType.REVENUE_BASED_FINANCING.toString(),
    "The next payment is due on",
  ],
  [LoanProductType.PAYABLE_FINANCING.toString(), "Your payment due date is"],
  [LoanProductType.INVOICE_DISCOUNTING.toString(), "Your payment due date is"],
  [LoanProductType.FIXED_TERM.toString(), "Your payment due date is"],
]);

const getNextPaymentCopy = (loan: LoanApplication) => {
  return (
    <div>
      {nextPaymentCopyMapping.get(loan.loanType)}{" "}
      {formatISOString(loan?.nextPaymentDate || new Date().toISOString())}
    </div>
  );
};

const loanInfoRequiredActionMap: Map<AdditionalInfoType, string> = new Map([
  [
    AdditionalInfoType.SHAREHOLDERS_CONTACTS,
    "Please add email address and phone number of Shareholder Name.",
  ],
  [AdditionalInfoType.DOC_TLN, "Please upload your latest Trade License."],
  [AdditionalInfoType.DOC_POA, "Please upload your latest Power of Attorney."],
  [
    AdditionalInfoType.DOC_MOA,
    "Please upload your latest Memorandum of Association.",
  ],
  [AdditionalInfoType.DOC_VAT, "Please upload your latest VAT Statements."],
  [
    AdditionalInfoType.DOC_BANK,
    "Please upload your latest bank statement or connect your bank account for fast approval.",
  ],
  [AdditionalInfoType.DOC_SALES_STATEMENT, "Please provide sales statements."],
  [
    AdditionalInfoType.DOC_POS_SALES_STATEMENT,
    "Please provide sales statements.",
  ],
  [AdditionalInfoType.DOC_OTHER, "Please provide additional documents."],
  [AdditionalInfoType.DOC_SALES_INVOICE, "Please provide sales Invoices."],
]);

const dontDisplayAdditionalInfo = [AdditionalInfoType.SHAREHOLDERS_AECB];

const getRequiredDocumentMessage = (info: AdditionalInfo) => {
  if (info.type == AdditionalInfoType.DOC_OTHER) return info.hint;
  return loanInfoRequiredActionMap.get(info.type);
};

function getProductByType(
  products: LoanProduct[],
  loanProductType: LoanProductType
): LoanProduct | undefined {
  return products.find((product) => product.type === loanProductType);
}

export const LoanCardActions: FC<LoanCardActionsProps> = ({ loan }) => {
  const {
    state,
    actions: { update, refetch },
  } = useLoanDashboard();

  const {
    state: { products, name: dpName },
  } = useDpConfig();

  const product = useMemo(() => {
    return getProductByType(products, loan.loanType as LoanProductType);
  }, [products, loan.loanType]);
  const disablePaymentScheduleFlag = !!product?.config?.disablePaymentSchedule;

  const showPaymentProcessThroughDPText =
    product?.uiLocalizationData?.smeDashboard
      ?.showPaymentProcessThroughDPText ?? false;
  const { mutateAsync: updateCompanyShareholder } =
    useUpdateCompanyShareholder();
  const { mutateAsync: updateIndividualShareholder } =
    useUpdateIndividualShareholder();

  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionModalContent, setActionModalContent] = useState<any>();
  const handleOpenSchedule = useCallback(
    (loanId: string) => {
      update({ currentLoanApplicationId: loanId, showPaymentSchedule: true });
    },
    [update]
  );
  const handleOpenPaymentHistory = useCallback(
    (loanId: string) => {
      update({ currentLoanApplicationId: loanId, showPaymentHistory: true });
    },
    [update]
  );
  const handleOpenTransactionsHistoryForPosRB = async (
    currentLoanId: string
  ) => {
    await update({
      currentLoanApplicationId: currentLoanId,
      showPaymentDetails: false,
      showPaymentHistory: false,
      showTransactionHistory: true,
    });
  };
  const { mutateAsync: updateInfo } = useUpdateLoanAdditionalInfo();

  const showScheduleButton = useMemo(() => {
    return (
      !disablePaymentScheduleFlag &&
      !(
        loan.loanType === LoanProductType.PAYABLE_FINANCING ||
        loan.loanType === LoanProductType.INVOICE_DISCOUNTING ||
        loan.loanType === LoanProductType.POS_REVENUE_BASED_FINANCING
      ) &&
      loan.status === LoanApplicationStatus.ACTIVE
    );
  }, [disablePaymentScheduleFlag, loan]);

  const showPaymentHistoryButton = useMemo(() => {
    return (
      loan.status === LoanApplicationStatus.ACTIVE &&
      loan.loanType == LoanProductType.REVENUE_BASED_FINANCING
    );
  }, [loan]);

  const showTransactionsHistoryButtonForPosRbf = useMemo(() => {
    return (
      loan.status === LoanApplicationStatus.ACTIVE &&
      loan.loanType == LoanProductType.POS_REVENUE_BASED_FINANCING
    );
  }, [loan]);

  const handleShareholderModalSubmit = async (
    data: ShareholderFields,
    info: AdditionalInfo
  ) => {
    if (info.userId) {
      if (data.__entityType === ShareholderEntityType.INDIVIDUAL) {
        await updateIndividualShareholder({
          id: info.userId.toString(),
          ...data,
        });
        await updateMissingInfo(info);
      } else {
        await updateCompanyShareholder({
          id: info.userId.toString(),
          ...data,
        });
        await updateMissingInfo(info);
      }
      refetch();
      setActionModalOpen(false);
    }
  };

  const handleModalSubmit = (
    requestedDocumentRes: AdditionalInfo[],
    loanApplicationId: string
  ) => {
    const updatedLoans = state.loans.map((loan) =>
      loan.id === loanApplicationId
        ? { ...loan, additionalInfo: requestedDocumentRes }
        : loan
    );
    update({ loans: updatedLoans });
    setActionModalOpen(false);
  };

  const handleFinanceInformationSelect = (
    selected: "connect" | "upload" | null,
    info: AdditionalInfo
  ) => {
    let modalContent = null;
    setActionModalOpen(false);
    switch (selected) {
      case "connect":
        modalContent = (
          <LinkBankAccount
            loanApplicationId={info.loanApplicationId}
            onCancel={() => setActionModalOpen(false)}
            onSubmit={() => setActionModalOpen(false)}
          />
        );
        setActionModalContent(modalContent);
        setActionModalOpen(true);
        break;
      case "upload":
        modalContent = (
          <MissingDocumentsUpload
            subtitle={"Bank Statements"}
            documentType={MediaPurpose.BANK_STATEMENT}
            requestedDocument={info}
            onSubmit={(data) => handleModalSubmit(data, info.loanApplicationId)}
            onClose={() => setActionModalOpen(false)}
          />
        );
        setActionModalContent(modalContent);
        setActionModalOpen(true);
        break;
    }
  };

  const handleActionClick = async (info: AdditionalInfo) => {
    await update({ currentLoanApplicationId: info.loanApplicationId });
    let modalContent = null;
    let subtitle = "";
    let documentType: MediaPurpose | null = null;
    switch (info.type) {
      case AdditionalInfoType.DOC_VAT:
        subtitle = "VAT statement";
        documentType = MediaPurpose.VAT_STATEMENT;
        break;
      case AdditionalInfoType.DOC_MOA:
        subtitle = "MOA";
        documentType = MediaPurpose.MEMORANDUM_OF_ASSOCIATION;
        break;
      case AdditionalInfoType.DOC_POA:
        subtitle = "POA";
        documentType = MediaPurpose.POWER_OF_ATTORNEY;
        break;
      case AdditionalInfoType.DOC_BANK:
        modalContent = (
          <FinanceInformationSelect
            title={"Action required"}
            subtitle={
              "Please upload your bank statement or try to connect your bank for faster approval"
            }
            onSelect={(tab) => handleFinanceInformationSelect(tab, info)}
            onClose={() => setActionModalOpen(false)}
          />
        );
        break;
      case AdditionalInfoType.DOC_TLN:
        subtitle = "Trade Licence";
        documentType = MediaPurpose.TRADE_LICENSE;
        break;
      case AdditionalInfoType.DOC_OTHER:
        subtitle = info.hint!;
        documentType = MediaPurpose.GENERIC_DOCUMENT;
        break;
      case AdditionalInfoType.DOC_SALES_STATEMENT:
        subtitle = "Sales Statement";
        documentType = MediaPurpose.SALES_STATEMENT;
        break;
      case AdditionalInfoType.DOC_POS_SALES_STATEMENT:
        subtitle = "Sales Statement";
        documentType = MediaPurpose.POS_SALES_STATEMENT;
        break;
      case AdditionalInfoType.DOC_SALES_INVOICE:
        subtitle = "Sales Invoice";
        documentType = MediaPurpose.INVOICE;
        break;
      case AdditionalInfoType.SHAREHOLDERS_CONTACTS:
        modalContent = (
          <AddShareholder
            title={"Action Required"}
            shareholder={info.shareholder}
            onSubmit={(data) => handleShareholderModalSubmit(data, info)}
            onCancel={() => setActionModalOpen(false)}
            modal
          />
        );
        break;
    }
    if (modalContent) {
      setActionModalContent(modalContent);
      setActionModalOpen(true);
    } else if (subtitle && documentType) {
      modalContent = (
        <MissingDocumentsUpload
          subtitle={subtitle}
          documentType={documentType}
          requestedDocument={info}
          onSubmit={(data) => handleModalSubmit(data, info.loanApplicationId)}
          onClose={() => setActionModalOpen(false)}
        />
      );
      setActionModalContent(modalContent);
      setActionModalOpen(true);
    }
  };

  const updateMissingInfo = (
    requestedDocument: AdditionalInfo,
    values?: string[]
  ) => {
    return updateInfo({
      id: requestedDocument.loanApplicationId,
      infoType: requestedDocument.type,
      userId: requestedDocument.userId,
      values,
    });
  };

  const renderLoanActions = () => {
    if (
      loan.status == LoanApplicationStatus.ADDITIONAL_INFO_REQUESTED &&
      loan.additionalInfo
    ) {
      if (loan.additionalInfo?.length) {
        return (
          <Fragment>
            {loan.additionalInfo
              .filter((info) => !dontDisplayAdditionalInfo.includes(info.type))
              .map((info: any) => (
                <div
                  key={info.id}
                  className="cx-flex cx-w-full cx-px-6 cx-py-2 cx-justify-between cx-items-center"
                >
                  <div className="cx-text-error-dark cx-text-sm cx-font-normal cx-flex cx-gap-1">
                    <span>
                      <InfoIcon width={16} height={16} />
                    </span>
                    {getRequiredDocumentMessage(info)}
                  </div>
                  <div className="cx-text-sm">
                    <Button
                      slim
                      label="Take Action"
                      onClick={() => handleActionClick(info)}
                    />
                  </div>
                </div>
              ))}
          </Fragment>
        );
      }
    }
    return AWAITABLE_STATUSES.includes(loan.status) ? (
      <div className="cx-flex cx-w-full cx-px-6 cx-justify-center cx-items-center">
        <div className="cx-text-text-success cx-text-sm cx-font-normal cx-flex cx-gap-1">
          <div>
            <TickIcon
              className="cx-stroke-success-regular"
              width={16}
              height={16}
            />
          </div>
          Your loan application is under review
        </div>
      </div>
    ) : loan.status === LoanApplicationStatus.ACTIVE &&
      showPaymentProcessThroughDPText ? (
      <div className="cx-text-text-success cx-text-center cx-text-sm cx-font-normal">
        Your payment will be automatically processed through {dpName}
      </div>
    ) : (loan.status === LoanApplicationStatus.OFFER_ACCEPTED ||
        loan.status === LoanApplicationStatus.WAITING_FOR_DISBURSEMENT) &&
      loan.loanType === LoanProductType.POS_REVENUE_BASED_FINANCING ? (
      <div className="cx-flex cx-w-full cx-px-6 cx-items-center">
        <div className="cx-text-text-error cx-text-sm cx-font-normal cx-flex cx-gap-1">
          <div>
            <InfoIcon
              className="cx-stroke-error-regular"
              width={16}
              height={16}
            />
          </div>
          The upfront fee will be deducted from the loan amount prior to
          disbursal.
        </div>
      </div>
    ) : null;
  };

  //todo: remove always positive conditions with appropriate conditions
  return (
    <div>
      <div className="cx-w-full sm:cx-flex cx-flex-row cx-items-center cx-pl-3 cx-pr-6 cx-pt-4 cx-border-t-0.5 cx-border-neutral-light">
        <div className="cx-grow cx-flex cx-flex-row cx-items-center">
          {loan.status == LoanApplicationStatus.ACTIVE &&
            isFuture(loan?.nextPaymentDate) && (
              <>
                {/* <div className="cx-px-2 cx-cursor-pointer">
                  <InfoIcon width={16} height={16} color="#0064FF" />
                </div> */}
                <div className="cx-grow cx-text-text-secondary cx-font-medium cx-text-sm">
                  {getNextPaymentCopy(loan)}
                </div>
              </>
            )}
        </div>
        <div className="sm:cx-flex cx-flex-row cx-gap-x-4">
          {showScheduleButton && (
            <Button
              slim={true}
              label={
                <div className="cx-flex cx-flex-row cx-items-center cx-gap-2.5">
                  <CalendarIcon width={16} height={16} stroke="#fff" />
                  Payments schedule
                </div>
              }
              onClick={() => handleOpenSchedule(loan.id)}
            />
          )}
          {showPaymentHistoryButton && (
            <div className="sm:cx-flex cx-text-s cx-font-normal cx">
              <Button
                label={"Payment History"}
                type="button"
                slim={true}
                onClick={() => handleOpenPaymentHistory(loan.id)}
                outlined
                className="cx-px-1 cx-border-1.5"
              />
            </div>
          )}
          {showTransactionsHistoryButtonForPosRbf && (
            <div className="sm:cx-flex cx-text-s cx-font-normal cx">
              <Button
                label={"Transactions History"}
                type="button"
                slim={true}
                onClick={() => handleOpenTransactionsHistoryForPosRB(loan.id)}
                outlined
                className="cx-px-1 cx-border-1.5"
              />
            </div>
          )}

          {/*<Button*/}
          {/*  slim*/}
          {/*  outlined*/}
          {/*  label="Early settlement"*/}
          {/*  onClick={() => setIsOpenModal(true)}*/}
          {/*/>*/}
        </div>
      </div>
      <div>{renderLoanActions()}</div>
      <Modal isOpen={actionModalOpen} onClose={() => setActionModalOpen(false)}>
        {actionModalContent}
      </Modal>
    </div>
  );
};

export default LoanCardActions;
