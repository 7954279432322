import { CredibleLogoLight } from "../../assets/images";
import { useDpConfig } from "../../contexts/dpConfig";
import { ButtonLoaderIcon } from "../../assets/icons";
import { useMicroFlow } from "../../contexts";
import { Divider } from "../index";

type Props = {
  text: string;
};

const Footer: React.FC<Props> = ({ text }) => {
  const {
    state: {
      name,
      uiLocalizationData: { footer },
      loading,
    },
  } = useDpConfig();
  const {
    state: { footerLogo },
  } = useMicroFlow();
  const deducedLogo = (footer?.usePartnerLogo && footer?.logoUrl) || footerLogo;

  return (
    <div
      className={
        "cx-w-full cx-flex cx-flex-col cx-items-center cx-content-center"
      }
    >
      <Divider className={"cx-w-full"} />
      <div className="cx-flex cx-flex-row cx-justify-between cx-items-center cx-gap-3 cx-p-4 cx-max-w-[960px] cx-px-2 cx-w-full ">
        <div className="cx-max-h-[30px]">
          {loading && !deducedLogo ? (
            <div className="cx-animate-spin cx-w-6 cx-h-6 cx-text-brand-primary-regular">
              <ButtonLoaderIcon />
            </div>
          ) : (
            <img
              className={"cx-h-[30px]"}
              src={deducedLogo || CredibleLogoLight}
              alt={deducedLogo ? name : "CredibleX"}
            />
          )}
        </div>
        <div className="cx-max-w-md cx-text-center cx-text-text-tertiary cx-text-xs">
          {text}
        </div>
      </div>
    </div>
  );
};

export default Footer;
