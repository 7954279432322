import { useContext } from "react";
import StepStart from "../../../components/StepStart/StepStart";
import { StepperContext, useLoanApplication } from "../../../contexts";
import { FinancialInformation } from "../../../assets/images";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";
const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

const FinancialInformationStartScreen = () => {
  const { state } = useLoanApplication();

  const { disableBankStatementsUpload } = useLoanProductConfigFlags(
    state.loanType
  );
  const { nextStep } = useContext(StepperContext);
  return (
    <StepStart
      title="Company financial information"
      subtitle="Last step, where we learn more about your company financials."
      image={FinancialInformation}
      onNext={() => {
        if (state.annualRevenue < VAT_LIMIT && disableBankStatementsUpload) {
          nextStep();
          nextStep();
        } else {
          nextStep();
        }
      }}
    />
  );
};

export default FinancialInformationStartScreen;
