import { StepConfig } from "../../contexts";
import LoanCalculator from "../LoanCalculator/LoanCalculator";
import LoanUploadInvoice from "../LoanCalculator/UploadInvoice";
import {
  BusinessDetailForm,
  BusinessInformationForm,
  BusinessInformationStartScreen,
  DocumentsUpload,
} from "./BusinessInformation";
import {
  FinanceInformation,
  FinanceInformationSelect,
  FinancialInformationStartScreen,
  VATStatementsUpload,
} from "./FinancialInformation";
import {
  OTPForm,
  PhoneNumberForm,
  EmailForm,
  PreInformationForm,
} from "./Onboarding";
import {
  OwnersInformationForm,
  OwnersInformationStartScreen,
} from "./OwnersInformation";
import ApplicationReview from "./Review/ApplicationReview";
import SuccessScreen from "./Success";
import ShareholdersInformation from "./OwnersInformation/ShareholderInformation";
import GenericDocumentUpload from "./FinancialInformation/GenericDocumentUpload";
import InitialPage from "./Onboarding/InitialPage";
import SelectCompanyProfile from "./CompanyProfile/SelectCompanyProfile";
import MoreInformation from "./FinancialInformation/MoreInformation";
import OwnersKycForm from "./OwnersInformation/OwnersKycForm";
import UploadSalesStatement from "../LoanCalculator/UploadSalesStatement";
import UploadSalesInvoice from "../LoanCalculator/UploadSalesInvoice";
import ManualAccounts from "./FinancialInformation/ManualAccounts";
import CompanyInformationStartScreen from "./BusinessInformation/CompanyInformationStart";
import LoanReasons from "./Onboarding/LoanReasons";
import LoanTypeSelection from "./Onboarding/LoanTypeSelection";
import { MerchantIDInputForm } from "./MerchantIDStep/MerchantIDInputForm";
import VendorInformation from "./VendorInformation/VendorInformation";
import UploadPosSalesStatement from "../LoanCalculator/UploadPosSalesStatement";

export enum StepName {
  LOAN_INITIAL = "Loan Initial Screen",
  LOAN_REASONS = "Loan Reason",
  LOAN_TYPE = "Loan Type Selection",
  BUSINESS_INFORMATION = "Business Information",
  OWNERS_INFORMATION = "Shareholder Information",
  FINANCIAL_INFORMATION = "Financial Information",
  LOAN_INFORMATION = "Loan Information",
  UPLOAD_DOCUMENTS = "Upload Documents",
  APPLICATION_REVIEW = "Application Review",
  LOAN_CALCULATOR = "Loan Calculator",
  SUCCESS = "Success",
}

export enum SubStepName {
  SELECT_COMPANY_PROFILE = "Select Company Profile",
  INITIAL_PAGE = "Initial Page",
  LOAN_REASON = "Loan Reason",
  LOAN_TYPE = "Loan Type",
  LOAN_CALCULATOR = "Loan Calculator",
  UPLOAD_INVOICE = "Upload invoice",
  UPLOAD_SALES_STATEMENT = "Upload sales statement",
  UPLOAD_POS_SALES_STATEMENT = "Upload POS sales statement",
  UPLOAD_SALES_INVOICE = "Upload sales invoice",
  PRE_APPLICATION_INFORMATION = "Pre-application Information",
  COMPANY_INFORMATION_START_SCREEN = "Compaby Information Start Screen",
  PHONE_NUMBER = "Phone Number",
  OTP_FORM = "OTP Form",
  EMAIL_FORM = "Email Form",
  BUSINESS_INFORMATION_START = "Business Information Start Screen",
  BUSINESS_INFORMATION = "Business Information",
  COMPANY_INFORMATION_START = "Company Information Start Screen",
  BUSINESS_DETAILS = "Business Details",
  DOCUMENTS_UPLOAD = "Documents Upload",
  OWNERS_INFORMATION_START = "Owners Information Start Screen",
  OWNERS_INFORMATION = "Owners Information",
  SHAREHOLDERS_INFORMATION = "Shareholders Information",
  OWNERS_KYC = "Owners KYC",
  FINANCIAL_INFORMATION_START = "Financial Information Start Screen",
  FINANCE_INFORMATION_SELECT = "Finance Information Select",
  FINANCE_INFORMATION = "Finance Information",
  VAT_INFORMATION = "VAT Information",
  ADDITIONAL_FINANCIAL_DOCUMENT = "Additional Financial Document",
  ADDITIONAL_INFORMATION = "Additional Information",
  Manual_Accounts = "Manual Accounts",
  APPLICATION_REVIEW = "Application Review",
  SUCCESS = "Success",
  MASTERCARD_MERCHANTID = "Mastercard Merchant ID",
  VENDOR_INFORMATION = "Vendor Information",
}

export const legacyStepsConfig: StepConfig = [
  {
    name: StepName.LOAN_CALCULATOR,
    subSteps: [
      {
        name: SubStepName.SELECT_COMPANY_PROFILE,
        component: <SelectCompanyProfile />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.INITIAL_PAGE,
        component: <InitialPage />,
        metadata: {
          disableBackroundGradient: true,
          // hasIllustrations: true,
        },
      },
      {
        name: SubStepName.LOAN_REASON,
        component: <LoanReasons />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.LOAN_TYPE,
        component: <LoanTypeSelection />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.LOAN_CALCULATOR,
        // component: <LoanCalculatorWrapper />,
        component: <LoanCalculator />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: false,
        },
      },
      {
        name: SubStepName.UPLOAD_INVOICE,
        component: <LoanUploadInvoice />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.UPLOAD_SALES_STATEMENT,
        component: <UploadSalesStatement />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.UPLOAD_SALES_INVOICE,
        component: <UploadSalesInvoice />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
    ],
    hideProgress: true,
  },
  {
    name: StepName.LOAN_INFORMATION,
    subSteps: [
      {
        name: SubStepName.PRE_APPLICATION_INFORMATION,
        component: <PreInformationForm />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.PHONE_NUMBER,
        component: <PhoneNumberForm />,
      },
      {
        name: SubStepName.OTP_FORM,
        component: <OTPForm />,
      },
      {
        name: SubStepName.EMAIL_FORM,
        component: <EmailForm />,
      },
    ],
  },
  {
    name: StepName.BUSINESS_INFORMATION,
    subSteps: [
      {
        name: SubStepName.BUSINESS_INFORMATION_START,
        component: <BusinessInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.BUSINESS_INFORMATION,
        component: <BusinessInformationForm />,
      },
      {
        name: SubStepName.COMPANY_INFORMATION_START_SCREEN,
        component: <CompanyInformationStartScreen />,
        metadata: {
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.BUSINESS_DETAILS,
        component: <BusinessDetailForm />,
        metadata: {
          hasIllustrations: false,
        },
      },
      {
        name: SubStepName.DOCUMENTS_UPLOAD,
        component: <DocumentsUpload />,
        metadata: {},
      },
    ],
  },
  {
    name: StepName.OWNERS_INFORMATION,
    subSteps: [
      {
        name: SubStepName.OWNERS_INFORMATION_START,
        component: <OwnersInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.OWNERS_INFORMATION,
        component: <OwnersInformationForm />,
      },
      {
        name: SubStepName.SHAREHOLDERS_INFORMATION,
        component: <ShareholdersInformation />,
      },
      {
        name: SubStepName.OWNERS_KYC,
        component: <OwnersKycForm />,
      },
    ],
  },
  {
    name: StepName.FINANCIAL_INFORMATION,
    subSteps: [
      {
        name: SubStepName.FINANCIAL_INFORMATION_START,
        component: <FinancialInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.FINANCE_INFORMATION_SELECT,
        component: <FinanceInformationSelect />,
      },
      {
        name: SubStepName.FINANCE_INFORMATION,
        component: <FinanceInformation />,
      },
      {
        name: SubStepName.VAT_INFORMATION,
        component: <VATStatementsUpload />,
      },
      {
        name: SubStepName.ADDITIONAL_FINANCIAL_DOCUMENT,
        component: <GenericDocumentUpload />,
      },
      {
        name: SubStepName.ADDITIONAL_INFORMATION,
        component: <MoreInformation />,
      },
      {
        name: SubStepName.Manual_Accounts,
        component: <ManualAccounts />,
      },
      {
        name: SubStepName.MASTERCARD_MERCHANTID,
        component: <MerchantIDInputForm />,
      },
    ],
  },
  {
    name: StepName.UPLOAD_DOCUMENTS,
    subSteps: [
      {
        name: SubStepName.VENDOR_INFORMATION,
        component: <VendorInformation />,
      },
    ],
  },
  {
    name: StepName.APPLICATION_REVIEW,
    subSteps: [
      {
        name: SubStepName.APPLICATION_REVIEW,
        component: <ApplicationReview />,
      },
    ],
  },
  {
    name: StepName.SUCCESS,
    hideProgress: true,
    subSteps: [
      {
        name: SubStepName.SUCCESS,
        component: <SuccessScreen />,
      },
    ],
  },
];

export const locStepsConfig: StepConfig = [
  {
    name: StepName.LOAN_INFORMATION,
    subSteps: [
      {
        name: SubStepName.SELECT_COMPANY_PROFILE,
        component: <SelectCompanyProfile />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.INITIAL_PAGE,
        component: <InitialPage />,
        metadata: {
          disableBackroundGradient: true,
          // hasIllustrations: true,
        },
      },
      {
        name: "Phone Number",
        component: <PhoneNumberForm />,
      },
      {
        name: SubStepName.OTP_FORM,
        component: <OTPForm />,
      },
      {
        name: SubStepName.EMAIL_FORM,
        component: <EmailForm />,
      },
    ],
  },
  {
    name: StepName.BUSINESS_INFORMATION,
    subSteps: [
      {
        name: SubStepName.BUSINESS_INFORMATION_START,
        component: <BusinessInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.BUSINESS_INFORMATION,
        component: <BusinessInformationForm />,
      },
      {
        name: "Company Information Start Screen",
        component: <CompanyInformationStartScreen />,
        metadata: {
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.BUSINESS_DETAILS,
        component: <BusinessDetailForm />,
        metadata: {
          hasIllustrations: false,
        },
      },
      {
        name: SubStepName.DOCUMENTS_UPLOAD,
        component: <DocumentsUpload />,
        metadata: {},
      },
    ],
  },
  {
    name: StepName.LOAN_CALCULATOR,
    subSteps: [
      // {
      //   name: "Loan Reason",
      //   component: <LoanReasons />,
      //   metadata: {
      //     disableBackroundGradient: true,
      //     hasIllustrations: true,
      //   },
      // },
      // {
      //   name: "Loan Type",
      //   component: <LoanTypeSelection />,
      //   metadata: {
      //     disableBackroundGradient: true,
      //     hasIllustrations: true,
      //   },
      // },
      {
        name: "Loan Calculator",
        // component: <LoanCalculatorWrapper />,
        component: <LoanCalculator />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: false,
        },
      },
      {
        name: SubStepName.UPLOAD_INVOICE,
        component: <LoanUploadInvoice />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.UPLOAD_SALES_STATEMENT,
        component: <UploadSalesStatement />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.UPLOAD_SALES_INVOICE,
        component: <UploadSalesInvoice />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
      {
        name: SubStepName.UPLOAD_POS_SALES_STATEMENT,
        component: <UploadPosSalesStatement />,
        metadata: {
          disableBackroundGradient: true,
        },
      },
    ],
  },
  {
    name: StepName.OWNERS_INFORMATION,
    subSteps: [
      {
        name: SubStepName.OWNERS_INFORMATION_START,
        component: <OwnersInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.OWNERS_INFORMATION,
        component: <OwnersInformationForm />,
      },
      {
        name: SubStepName.SHAREHOLDERS_INFORMATION,
        component: <ShareholdersInformation />,
      },
      {
        name: SubStepName.OWNERS_KYC,
        component: <OwnersKycForm />,
      },
    ],
  },
  {
    name: StepName.FINANCIAL_INFORMATION,
    subSteps: [
      {
        name: SubStepName.FINANCIAL_INFORMATION_START,
        component: <FinancialInformationStartScreen />,
        metadata: {
          disableBackroundGradient: true,
          hasIllustrations: true,
        },
      },
      {
        name: SubStepName.FINANCE_INFORMATION_SELECT,
        component: <FinanceInformationSelect />,
      },
      {
        name: SubStepName.FINANCE_INFORMATION,
        component: <FinanceInformation />,
      },
      {
        name: SubStepName.VAT_INFORMATION,
        component: <VATStatementsUpload />,
      },
      {
        name: SubStepName.ADDITIONAL_FINANCIAL_DOCUMENT,
        component: <GenericDocumentUpload />,
      },
      {
        name: SubStepName.ADDITIONAL_INFORMATION,
        component: <MoreInformation />,
      },
      {
        name: SubStepName.Manual_Accounts,
        component: <ManualAccounts />,
      },
      {
        name: SubStepName.MASTERCARD_MERCHANTID,
        component: <MerchantIDInputForm />,
      },
    ],
  },
  {
    name: StepName.UPLOAD_DOCUMENTS,
    subSteps: [
      {
        name: SubStepName.VENDOR_INFORMATION,
        component: <VendorInformation />,
      },
    ],
  },
  {
    name: StepName.APPLICATION_REVIEW,
    subSteps: [
      {
        name: SubStepName.APPLICATION_REVIEW,
        component: <ApplicationReview />,
      },
    ],
  },
  {
    name: StepName.SUCCESS,
    hideProgress: true,
    subSteps: [
      {
        name: SubStepName.SUCCESS,
        component: <SuccessScreen />,
      },
    ],
  },
];
