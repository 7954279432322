import { LoanProductType } from "../../hooks/api/types";
import {
  LoanCalculatorReducerAction,
  LoanCalculatorState,
  RevenueBasedLoanParams,
} from "./types";

export const LoanCalculatorReducer = (
  state: LoanCalculatorState,
  action: LoanCalculatorReducerAction
): LoanCalculatorState => {
  switch (action.type) {
    case "SET_FIXED_TERM_LOAN":
      return {
        ...state,
        type: LoanProductType.FIXED_TERM,
        fixedTermLoan: action.payload,
        loading: false,
      };
    case "SET_INVOICE_DISCOUNTING_LOAN":
      return {
        ...state,
        type: LoanProductType.INVOICE_DISCOUNTING,
        invoiceDiscountingLoan: action.payload,
        loading: false,
      };
    case "SET_PAYABLE_FINANCING_LOAN":
      return {
        ...state,
        type: LoanProductType.PAYABLE_FINANCING,
        payableFinancingLoan: action.payload,
        loading: false,
      };
    case "SET_REVENUE_BASED_LOAN":
      return {
        ...state,
        type: LoanProductType.REVENUE_BASED_FINANCING,
        //effective state is undefined from the api when we land on loan calculator page
        revenueBasedLoan:
          getEffectiveRevenueBasedLoanState(action.payload) ||
          state.revenueBasedLoan,
        loading: false,
      };
    case "SET_POS_REVENUE_BASED_LOAN":
      return {
        ...state,
        type: LoanProductType.POS_REVENUE_BASED_FINANCING,
        posRevenueBasedLoan: action.payload,
        loading: false,
      };
    case "SET_BNPL_LOAN":
      return {
        ...state,
        type: LoanProductType.BNPL,
        buyNowPayLaterLoan: action.payload,
        loading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_CONSTRAINTS":
      return {
        ...state,
        constraints: action.payload,
        loading: false,
      };
    case "SET_REVENUE_BASED_LOAN_CONSTRAINTS":
      return {
        ...state,
        constraints: {
          ...state.constraints,
          revenueBasedLoan: action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};

const getEffectiveRevenueBasedLoanState = (
  payload: LoanCalculatorState["revenueBasedLoan"]
): RevenueBasedLoanParams | undefined => {
  if (
    payload.amount &&
    payload.duration &&
    payload.interestRate &&
    payload.durationType
  ) {
    return payload;
  }
};
