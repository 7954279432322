import { LoanProductType } from "../../../hooks/api/types";

export const getVendorNature = (loanType: LoanProductType): string => {
  //todo: @haider: implement this function for appropriate loan types
  return loanType === LoanProductType.PAYABLE_FINANCING ? "supplier" : "buyer";
};

export const getVendorTitle = (loanType: LoanProductType): string => {
  const vendorNature = getVendorNature(loanType);
  return vendorNature.charAt(0).toUpperCase() + vendorNature.slice(1);
};
