import { FC, useCallback } from "react";
import { Button, Modal, Tabs } from "../../../components";
import { OfficeAddressForm } from "./OfficeAddressForm";
import { TaxAndRevenueForm } from "./TaxAndRevenueForm";
import { useLoanApplication } from "../../../contexts";
import { useFormik } from "formik";
import {
  DETAIL_FORM_INITIAL_VALUES,
  DETAIL_FORM_VALIDATION_SCHEMA,
  DetailFormFields,
  INFORMATION_FORM_INITIAL_VALUES,
  INFORMATION_FORM_VALIDATION_SCHEMA,
  InformationFormFields,
} from "../BusinessInformation/service";

type BusinessInformationModalProps = {
  isOpen: boolean;
  onClose(): void;
  disableRevenueHistory: boolean;
};

export const BusinessInformationModal: FC<BusinessInformationModalProps> = ({
  isOpen = false,
  onClose = () => {},
  disableRevenueHistory,
}) => {
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const addressForm = useFormik<DetailFormFields>({
    initialValues: {
      ...DETAIL_FORM_INITIAL_VALUES,
      companyName: state.businessName,
      companyYearEstabilished: state.businessYearEstabilished,
      businessType: state.businessType,
      industryTypeId: state.industryTypeId,
      emirate: state.businessEmirate,
      area: state.businessArea,
      street: state.businessStreet,
      building: state.businessBuilding,
      floor: state.businessFloor,
      number: state.businessUnit,
    },
    validationSchema: DETAIL_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      await update({
        businessEmirate: values.emirate,
        businessArea: values.area,
        businessStreet: values.street,
        businessBuilding: values.building,
        businessFloor: values.floor,
        businessUnit: values.number,
      });
      onClose();
    },
  });

  const taxAndRevenueForm = useFormik<InformationFormFields>({
    initialValues: {
      ...INFORMATION_FORM_INITIAL_VALUES,
      tradeLicenseNumber: state.tradeLicenseNumber,
      annualRevenue: state.annualRevenue,
      taxRegistrationNumber: state.taxRegistrationNumber,
      lastYearPaidTax: state.lastYearPaidTax,
      licensingAuthorityId: state.licensingAuthorityId,
    },
    validationSchema: INFORMATION_FORM_VALIDATION_SCHEMA,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await update({
        tradeLicenseNumber: values.tradeLicenseNumber,
        annualRevenue: values.annualRevenue,
        taxRegistrationNumber: values.taxRegistrationNumber,
        lastYearPaidTax: values.lastYearPaidTax,
        licensingAuthorityId: values.licensingAuthorityId,
      });
    },
  });

  const onSubmit = useCallback(async () => {
    addressForm.handleSubmit();
    taxAndRevenueForm.handleSubmit();
  }, [addressForm, taxAndRevenueForm]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Business information">
      <div className="cx-min-w-[380px]">
        <Tabs
          startingTab={0}
          tabs={[
            {
              label: "Office address",
              component: <OfficeAddressForm form={addressForm} />,
            },
            ...(disableRevenueHistory
              ? []
              : [
                  {
                    label: "Tax & revenue",
                    component: <TaxAndRevenueForm form={taxAndRevenueForm} />,
                  },
                ]),
          ]}
        />
        <div className="cx-mt-6">
          <Button label="Confirm" fullWidth={true} onClick={onSubmit} />
          <Button
            label="Cancel"
            fullWidth={true}
            secondary={true}
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
