import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons";

interface PaginationProps {
  currentPage: number;
  setCurrentPage: any;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalPages: number;
  pageSize: number;
  offsetItems: number;
  totalItems: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  hasPreviousPage = false,
  hasNextPage,
  pageSize,
  offsetItems,
  totalPages,
  totalItems,
}) => {
  const getItemsInfo = () => {
    const itemsDisplayed = currentPage * pageSize;
    return `${offsetItems + 1}-${
      itemsDisplayed <= totalItems
        ? itemsDisplayed
        : totalItems % itemsDisplayed
    } of ${totalItems}`;
  };

  const handlePageChange = (
    page: "next" | "previous" | "newest" | "oldest"
  ): void => {
    if (page === "next" && hasNextPage) {
      setCurrentPage(currentPage + 1);
    } else if (page === "previous" && hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    } else if (page === "newest" && hasPreviousPage) {
      setCurrentPage(1);
    } else if (page === "oldest" && hasNextPage) {
      setCurrentPage(totalPages);
    }
  };

  const btnClass =
    "cx-bg-interaction-button-primary-inverse cx-text-interaction-button-text-default cx-border cx-border-stroke-primary cx-hover:bg-interaction-button-primary-hover cx-hover:text-white cx-focus:bg-interaction-button-primary-pressed cx-focus:text-white cx-focus:ring-2 cx-p-2 cx-rounded-btn";
  const disabledBtnClass =
    "cx-bg-interaction-button-primary-disabled cx-text-interaction-button-text-disabled cx-hover:bg-white cx-hover:text-[#666666]";

  return (
    <div className="cx-menu-bar cx-w-full cx-flex cx-justify-between cx-items-center">
      <div className="cx-left-section cx-w-28">
        <button
          className={`${btnClass} ${
            !hasPreviousPage ? disabledBtnClass : ""
          } cx-w-full cx-py-1.5`}
          onClick={() => handlePageChange("newest")}
        >
          <p className="cx-text-sm cx-font-medium">Newest</p>
        </button>
      </div>
      <div className="cx-middle-section cx-flex cx-items-center cx-gap-2">
        <div className="cx-left">
          <button
            className={`${btnClass} ${
              !hasPreviousPage ? disabledBtnClass : ""
            }`}
            onClick={() => handlePageChange("previous")}
          >
            <ArrowLeftIcon className="cx-h-5 cx-stroke-[4]" />
          </button>
        </div>
        <div className="cx-page-info cx-border cx-border-stroke-primary cx-rounded-btn cx-px-4 cx-py-2">
          <p className="cx-text-neutral-darker cx-font-normal cx-text-xs">
            {getItemsInfo()}
          </p>
        </div>
        <div className="cx-right">
          <button
            className={`${btnClass} ${!hasNextPage ? disabledBtnClass : ""}`}
            onClick={() => handlePageChange("next")}
          >
            <ArrowRightIcon className="cx-stroke-[2]" />
          </button>
        </div>
      </div>
      <div className="cx-right-section cx-w-28">
        <button
          className={`${btnClass} ${
            !hasNextPage ? disabledBtnClass : ""
          } cx-w-full cx-py-1.5`}
          onClick={() => handlePageChange("oldest")}
        >
          <p className="cx-text-sm cx-font-medium">Oldest</p>
        </button>
      </div>
    </div>
  );
};
