import { useContext, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { OTPInput, StepNextButton } from "../../../components";
import {
  OTPType,
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
  useSDKContext,
} from "../../../contexts";
import {
  OTP_FORM_INITIAL_VALUES,
  OTP_FORM_VALIDATION_SCHEMA,
  OTPFormFields,
} from "./service";
import {
  useIFrameEvents,
  useInitiateCopy,
  useMedia,
  useSendEmailOTP,
  useSendOTP,
  useSendOTPByLoanApplicationId,
  useStopwatch,
} from "../../../hooks";

import { usePostHog } from "posthog-js/react";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { useDpConfig } from "../../../contexts/dpConfig";
import { updateLoansStateForLegacyFlow } from "../../LoanCalculator/service";
import { datadogRum } from "@datadog/browser-rum";

const OTPForm = () => {
  const [creatingLoanApp, setCreatingLoanApp] = useState(false);
  const { previousStep, nextStep, setLoading } = useContext(StepperContext);
  const {
    state,
    actions: { create, refetch, update },
  } = useLoanApplication();
  const { state: calculatorState } = useLoanCalculator();
  const sdkContext = useSDKContext();
  const { postLoanApplicationStartedEvent } = useIFrameEvents();

  const { mutateAsync: sendOTP } = useSendOTP();
  const { mutateAsync: sendEmailOTP } = useSendEmailOTP();
  const { upload } = useMedia();
  const {
    state: { settings },
  } = useDpConfig();
  const { mutateAsync: sendOTPByLoanApplicationId } =
    useSendOTPByLoanApplicationId();
  const { mutateAsync: initiateCopy } = useInitiateCopy();

  // if we have loan app Id in state at Otp form screen then we know we are coming from InformationForm screen to verify Otp again from phone number from existing profile does not match the phone number user entered in current loan journey
  const comingFromInformationForm = !!state.id;

  const posthog = usePostHog();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik<OTPFormFields>({
    initialValues: {
      ...OTP_FORM_INITIAL_VALUES,
    },
    validationSchema: OTP_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      if (comingFromInformationForm && state.sourceId) {
        await initiateCopy({
          loanApplicationId: state.id,
          refId: state.sourceId,
        });
        if (!settings.disableLOCFlow) {
          const { loanType } = await update({
            id: state.id,
            sourceId: state.sourceId,
            stage: LoanApplicationStage.CALCULATOR,
          });

          if (loanType) {
            await update({ loanType }, { local: true });
          }
        } else {
          // we are in legacy flow
          await updateLoansStateForLegacyFlow(
            state.id,
            calculatorState,
            update,
            upload,
            state.invoiceFiles,
            state.salesStatementFiles,
            state.salesInvoiceFiles
          );
        }
        await refetch();
        return;
      }
      setCreatingLoanApp(true);
      try {
        const { id } = await create({
          user: {
            otpCode: values.otp,
            otpType: state.otpType,
            ...(state.otpType === OTPType.EMAIL
              ? { email: state.email }
              : {
                  localNumber: state.localNumber,
                  countryCode: state.countryCode,
                }),
          },
          reason: state.loanReason,
          ...(state.sourceId && { refId: state.sourceId }),
        });

        setLoading(true);

        //if we are in legacy flow mode, we update the loan application
        if (settings.disableLOCFlow) {
          await updateLoansStateForLegacyFlow(
            id,
            calculatorState,
            update,
            upload,
            state.invoiceFiles,
            state.salesStatementFiles,
            state.salesInvoiceFiles
          );
        }

        let loanApplicationData: any = null;

        if (state.sourceId) {
          // if we are in LOC flow mode, we move to CALCULATOR stage
          if (!settings.disableLOCFlow) {
            await update({ id: id, stage: LoanApplicationStage.CALCULATOR });
          }
          loanApplicationData = await refetch();
        } else if (
          sdkContext.version === "1" &&
          sdkContext.onApplicationStarted
        ) {
          sdkContext.updateSDKContext({ prefilling: true });
          await Promise.resolve(sdkContext.onApplicationStarted(id));
          loanApplicationData = await refetch();
          sdkContext.updateSDKContext({ prefilling: false });
        } else {
          postLoanApplicationStartedEvent({ loanApplicationId: id });
          loanApplicationData = await refetch();
        }

        posthog.identify(loanApplicationData?.id, {
          distributionPartnerId: loanApplicationData.distributionPartnerId,
          distributionPartnerName:
            loanApplicationData.distributionPartner?.name,
          name: loanApplicationData?.user?.name,
          email: loanApplicationData?.user?.email,
          loanApplicationId: loanApplicationData?.id,
        });
        datadogRum.setUser({
          id: loanApplicationData.id,
          loanApplicationId: loanApplicationData.id,
          distributionPartnerId: loanApplicationData.distributionPartnerId,
          distributionPartnerName:
            loanApplicationData.distributionPartner?.name,
          name: loanApplicationData?.user?.name,
          email: loanApplicationData?.user?.email,
        });
      } catch (err: any) {
        console.error("Error occurred:", err);
        setFieldError("otp", err?.message);
      } finally {
        setCreatingLoanApp(false);
        setLoading(false);
      }
    },
  });

  const { seconds, start: startWatch } = useStopwatch(0, 0, 30);

  const handleResendSMSOTP = () => {
    if (comingFromInformationForm && state.sourceId) {
      sendOTPByLoanApplicationId({
        loanApplicationId: state.sourceId,
      })
        .then(() => {
          startWatch(0, 0, 30);
        })
        .catch((err) => {
          console.log(err);
          setFieldError("otp", err.message);
        });
    } else {
      sendOTP({
        countryCode: state.countryCode,
        localNumber: state.localNumber,
      })
        .then(() => {
          startWatch(0, 0, 30);
        })
        .catch((err) => {
          console.log(err);
          setFieldError("otp", err.message);
        });
    }
  };

  const handleResendEmailOTP = () => {
    sendEmailOTP({
      email: state.email,
    })
      .then(() => {
        startWatch(0, 0, 30);
      })
      .catch((err) => {
        console.log(err);
        setFieldError("otp", err.message);
      });
  };

  const handleChangeVerify = () => {
    if (state.otpType === OTPType.SMS) {
      nextStep();
    }

    if (state.otpType === OTPType.EMAIL) {
      previousStep();
    }
  };

  const verificationMessage = useMemo(() => {
    const isSMS = state.otpType === OTPType.SMS;
    const recipient = isSMS
      ? comingFromInformationForm
        ? state.localNumber
        : state.countryCode + state.localNumber
      : state.email;
    return `We sent a verification code via ${
      isSMS ? "SMS" : "Email"
    } to ${recipient}`;
  }, [state.otpType]);

  useEffect(() => {
    startWatch();
  }, []);

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        Please verify your{" "}
        {state.otpType === OTPType.SMS
          ? "mobile phone number"
          : "email address"}
      </div>
      <div className="cx-mt-2 cx-text-text-secondary cx-text-base cx-text-center">
        {verificationMessage}
      </div>
      {!comingFromInformationForm && (
        <div
          className="cx-cursor-pointer cx-mt-1 cx-text-interaction-button-text-default cx-text-center cx-text-sm"
          onClick={state.otpType === OTPType.SMS ? previousStep : nextStep}
        >
          Change {state.otpType === OTPType.SMS ? "number" : "email address"}
        </div>
      )}
      <form
        className="cx-w-full cx-flex cx-flex-col cx-items-center cx-justify-center"
        onSubmit={handleSubmit}
      >
        <div className="cx-max-w-[400px] cx-mt-10 cx-w-full">
          <OTPInput
            length={6}
            value={values.otp}
            onChange={(val) => {
              setFieldValue("otp", val);
            }}
            error={!!touched.otp && !!errors.otp ? errors.otp : ""}
          />
          <div className="cx-mt-4 cx-text-sm cx-text-center">
            <span className="cx-text-text-tertiary cx-mr-1">
              Didn’t receive an{" "}
              {state.otpType === OTPType.SMS ? "SMS" : "Email"}?
            </span>
            {seconds > 0 ? (
              <span className="cx-text-interaction-button-text-disabled">
                Resend code in 00:{`0${seconds}`.slice(-2)}
              </span>
            ) : (
              <span
                onClick={() => {
                  if (state.otpType === OTPType.SMS) {
                    handleResendSMSOTP();
                  }

                  if (state.otpType === OTPType.EMAIL) {
                    handleResendEmailOTP();
                  }
                }}
                className="cx-text-interaction-button-primary-default cx-cursor-pointer"
              >
                Resend OTP
              </span>
            )}
            {!comingFromInformationForm && (
              <>
                <span className="cx-text-text-tertiary cx-mr-1"> or </span>
                <span
                  onClick={handleChangeVerify}
                  className="cx-text-interaction-button-primary-default cx-cursor-pointer"
                >
                  verify by {state.otpType === OTPType.SMS ? "Email" : "SMS"}
                </span>
              </>
            )}
          </div>
          {comingFromInformationForm && (
            <div className="cx-text-text-secondary cx-text-center cx-w-full cx-bg-brand-primary-lighter cx-p-10 cx-mt-10 cx-rounded-lg">
              if you did not receive a verification code, you can contact to us
              through email{" "}
              <span className="cx-text-brand-primary-regular">
                support@crediblex.io
              </span>
            </div>
          )}
          <div className="cx-w-full cx-mt-10">
            <StepNextButton
              label={comingFromInformationForm ? "Continue" : "Next"}
              loading={state.updating || creatingLoanApp}
              disabled={state.updating || creatingLoanApp}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default OTPForm;
