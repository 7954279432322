import { useLoanApplication } from "../../../../contexts";
import { LoanProductType } from "../../../../hooks/api/types";
import FixedTermLoanReviewSection from "./FixedTermLoanReviewSection";
import InvoiceDiscountingReviewSection from "./InvoiceDiscountingLoanReviewSection";
import PayableFinancingReviewSection from "./PayableFinancingLoanReviewSection";
import RevenueBasedLoanReviewSection from "./RevenueBasedLoanReviewSection";
import { useCallback } from "react";
import BuyNowPayLaterLoanReviewSection from "./BuyNowPayLaterLoanReviewSection";
import PosRevenueBasedLoanReviewSection from "./PosRevenueBasedLoanReviewSection";

const LoanInformationReviewSection = () => {
  const {
    state: { loanType },
  } = useLoanApplication();

  const getComponent = useCallback((): JSX.Element => {
    switch (loanType) {
      case LoanProductType.FIXED_TERM:
        return <FixedTermLoanReviewSection />;
      case LoanProductType.INVOICE_DISCOUNTING:
        return <InvoiceDiscountingReviewSection />;
      case LoanProductType.PAYABLE_FINANCING:
        return <PayableFinancingReviewSection />;
      case LoanProductType.REVENUE_BASED_FINANCING:
        return <RevenueBasedLoanReviewSection />;
      case LoanProductType.BNPL:
        return <BuyNowPayLaterLoanReviewSection />;
      case LoanProductType.POS_REVENUE_BASED_FINANCING:
        return <PosRevenueBasedLoanReviewSection />;
      default:
        return <></>;
    }
  }, [loanType]);

  return getComponent();
};

export default LoanInformationReviewSection;
