import { FC, useEffect, useState } from "react";
import { Button, InputField } from "../../components";
import { useLoanDashboard } from "../../contexts";
import { ButtonLoaderIcon, DownloadIcon } from "../../assets/icons";
import { InputFieldVariant } from "../../components/InputField/InputField";
import {
  useAllTransactionsExport,
  useGetAllTransactions,
  usePaymentDetailsExport,
} from "../../hooks";
import {
  PageMeta,
  PaymentTransactionsSortColumns,
  SortOrder,
  TransactionDTO,
} from "../../hooks/api/types";
import { formatISOString } from "../../utils";
import { debounce } from "lodash";
import Table, { Column } from "../../components/Table/Table";
import { DisplayFormatMapper } from "../../types";
import { Pagination } from "../../components/Pagination/Pagination";

const Loader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

const transactionTableColumns: Column<TransactionDTO>[] = [
  { header: "Transaction ID", accessor: "transactionId" },
  { header: "Date", accessor: "timestamp" },
  { header: "Debit", accessor: "paidAmount" },
];

const transactionTableFormatter: DisplayFormatMapper<TransactionDTO> = {
  timestamp: (date) => (date ? formatISOString(date, "dd MMM yyyy") : "-"),
  paidAmount: (totalAmount) =>
    totalAmount
      ? new Intl.NumberFormat("en-AE", {
          style: "currency",
          currency: "AED",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(totalAmount)
      : "-",
};

const PosRevenueBasedLoanTransactions: FC = () => {
  const {
    state: { currentLoanApplicationId, currentPaymentId },
    actions: { update },
  } = useLoanDashboard();

  const [transactions, setTransactions] = useState<TransactionDTO[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };
  const [searchKey, setSearchKey] = useState<string>("");

  const {
    data: transactionsResponse,
    isLoading: isLoadingTransactions,
    refetch: refetchTransactions,
  } = useGetAllTransactions({
    loanApplicationId: currentLoanApplicationId,
    disablePagination: false,
    page: currentPage,
    pageSize: 50,
    filters: {
      searchText: searchKey,
      sortOrder: SortOrder.DESC,
      sortColumn: PaymentTransactionsSortColumns.DATE,
    },
  });

  useEffect(() => {
    if (transactionsResponse) {
      setTransactions(transactionsResponse.data);
      setPageMeta(transactionsResponse.meta);
    }
  }, [transactionsResponse, isLoadingTransactions]);

  useEffect(() => {
    refetchTransactions();
  }, [refetchTransactions, searchInput, currentPage]);

  const { refetch: downloadCsv, isFetching } = useAllTransactionsExport({
    loanApplicationId: currentLoanApplicationId,
    disablePagination: true,
    filters: {
      searchText: searchKey,
      sortOrder: SortOrder.DESC,
      sortColumn: PaymentTransactionsSortColumns.DATE,
    },
  });

  const handleExport = async () => {
    await downloadCsv();
  };

  const filterBySearchInput = debounce((searchKey: string) => {
    setSearchKey(searchKey);
  }, 1000);

  useEffect(() => {
    filterBySearchInput(searchInput);
  }, [searchInput]);

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-background-radial-gradient cx-w-full cx-p-8">
      <div className="cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-full">
        <div className="cx-flex cx-flex-row cx-content-center cx cx-items-center cx-w-full cx-max-w-[960px] cx-py-3 cx-relative">
          <Button
            label="Back"
            arrow="left"
            invertArrowDirection={true}
            arrowClassName="cx-stroke-2 cx-h-4 cx-w-4"
            slim={true}
            onClick={() => update({ showTransactionHistory: false })}
            className="cx-text-s cx-font-semibold cx-py-2 cx-px-3 cx-h-8 cx-mr-2"
          />
          <div className="cx-text-3xl">{} Transaction history </div>
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[960px] cx-py-3 cx-flex cx-flex-row cx-justify-between">
        <InputField
          label="Search by transaction id"
          search={true}
          className="cx-max-w-xs cx-border-1.5"
          variant={InputFieldVariant.slim}
          inputProps={{
            name: "searchInput",
            value: searchInput,
            onChange: handleChange,
          }}
        ></InputField>
        <Button
          label={
            <div
              className={
                "cx-flex cx-flex-row cx-justify-center cx-items-center"
              }
            >
              {!isFetching && <DownloadIcon className="cx-mx-1" />}
              <div>Export</div>
            </div>
          }
          {...(isFetching ? { loader: "left" } : {})}
          disabled={isFetching}
          slim={true}
          onClick={handleExport}
        />
      </div>
      <div className={"cx-w-full cx-px-1 cx-py-4"}>
        <Table
          data={transactions}
          columns={transactionTableColumns}
          formatter={transactionTableFormatter}
          separateRowsByBorder={false}
        />
        {pageMeta && pageMeta?.pageCount > 1 && (
          <div className="cx-pagination-container cx-pt-4">
            {isLoadingTransactions && searchInput ? (
              <Loader />
            ) : (
              !searchInput &&
              pageMeta && (
                <Pagination
                  currentPage={pageMeta?.page}
                  setCurrentPage={setCurrentPage}
                  hasNextPage={pageMeta?.hasNextPage}
                  hasPreviousPage={pageMeta?.hasPreviousPage}
                  totalPages={pageMeta?.pageCount}
                  pageSize={pageMeta.pageSize}
                  offsetItems={pageMeta.skip}
                  totalItems={pageMeta.itemsCount}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PosRevenueBasedLoanTransactions;
