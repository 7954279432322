import { roundTo } from "../../../../utils";
import LoanReviewField from "../LoanReviewField";
import { Divider } from "../../../../components";
import { useLoanMetrics } from "../../../../hooks";
import EditButton from "../EditButton";
import { useState } from "react";
import BusinessLoanModal from "../BusinessLoanModal";
import { useDpConfig } from "../../../../contexts/dpConfig";
import { useLoanApplication } from "../../../../contexts";

const PosRevenueBasedLoanReviewSection = () => {
  const [loanCalculatorModalOpen, setLoanCalculatorModalOpen] = useState(false);

  const {
    state: { products },
  } = useDpConfig();
  const {
    factorFeeForPosRevenueBasedFinancing,
    borrowedAmount,
    duration,
    currencyUnit,
  } = useLoanMetrics();

  const {
    state: { additionalData },
  } = useLoanApplication();

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10">
      <div className="cx-flex cx-flex-row cx-place-content-between cx-w-full">
        <div className="cx-text-2xl cx-font-bold">
          POS Revenue-based Financing
        </div>
        <EditButton onClick={() => setLoanCalculatorModalOpen(true)} />
      </div>
      <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10">
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              borrowedAmount,
              2
            ).toLocaleString()}`}
            label={"Loan Amount"}
          />
          <Divider />
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              factorFeeForPosRevenueBasedFinancing,
              2
            ).toLocaleString()}`}
            label={"One time fee"}
          />
          <Divider />
          {additionalData?.disbursementAccount?.iban && (
            <LoanReviewField
              label={"Disbursement account"}
              value={additionalData.disbursementAccount.iban}
            />
          )}
          <div className="cx-flex cx-flex-col cx-grow cx-basis-0"></div>
        </div>
        <div className="cx-flex cx-flex-col cx-grow cx-basis-0">
          <LoanReviewField
            value={`${duration.value} ${duration.unit}`}
            label="Loan Duration"
          />
          <Divider />
          <Divider />
          <LoanReviewField
            value={`${currencyUnit} ${roundTo(
              borrowedAmount - factorFeeForPosRevenueBasedFinancing,
              2
            ).toLocaleString()}`}
            label={"Disbursed amount"}
          />
        </div>
        <BusinessLoanModal
          isOpen={loanCalculatorModalOpen}
          onClose={() => {
            setLoanCalculatorModalOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default PosRevenueBasedLoanReviewSection;
