import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SumsubWebSdk from "@sumsub/websdk-react";
import { Button, Checkbox } from "../../components";
import Success from "./Success";
import {
  useGetKycShareholderAccessToken,
  useGetLAForShareholderKYC,
  useTheme,
} from "../../hooks";
import {
  LOAN_PRODUCT_DISPLAY_NAME,
  LoanProductType,
  ShareholderKYCApplicationStatus,
} from "../../hooks/api/types";
import { toTitleCase } from "../../utils";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { ButtonLoaderIcon } from "../../assets/icons";
import LoanDetailsCard from "./LoanDetailsCard";
import { usePostHog } from "posthog-js/react";
import { CredibleLogoLight } from "../../assets/images";
import { useMicroFlow } from "../../contexts";
import { datadogRum } from "@datadog/browser-rum";

type Props = {};

const KYCMicroflow: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [kycInProgress, setKycInProgress] = useState<boolean>(false);
  const [kycCompleted, setKycCompleted] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState("");
  const [agreement, setAgreement] = useState(false);

  const {
    actions: { update: updateMicroflowContext },
  } = useMicroFlow();

  const { setTheme, themeLoading } = useTheme();

  const { data, refetch: getLoanApplication } = useGetLAForShareholderKYC();

  const { search } = useLocation();
  const postHog = usePostHog();

  const { refetch: refetchAccessToken } = useGetKycShareholderAccessToken(
    data ? data.shareholderId : ""
  );

  useEffect(() => {
    if (data?.dp.themeConfig) {
      setTheme(data.dp.themeConfig);
    } else if (!loading) {
      setTheme();
    }
    updateMicroflowContext({
      logo: CredibleLogoLight,
      footerLogo:
        (data?.dp?.uiLocalizationData?.footer?.usePartnerLogo &&
          data?.dp?.uiLocalizationData?.footer?.logoUrl) ||
        CredibleLogoLight,
    });
  }, [data?.dp.themeConfig]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const authCode = params.get("code");
    if (authCode) {
      localStorage.setItem(LOCALSTORAGE_KEYS.SESSION_TOKEN, authCode);
      getLoanApplication();
    }
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(false);
      if (data.status === ShareholderKYCApplicationStatus.IN_PROGRESS) {
        setKycInProgress(true);
        refetchAccessToken().then(({ data: { kycAccessToken } }) => {
          setAccessToken(kycAccessToken.token);
          setKycInProgress(false);
        });
      } else if (data.status === ShareholderKYCApplicationStatus.COMPLETED) {
        setKycCompleted(true);
      }
      if (!kycCompleted) {
        postHog.reset();
        postHog.identify(data.loanApplicationId, {
          loanApplicationId: data.loanApplicationId,
          distributionPartnerId: data.dp?.id,
          dpName: data.dp?.name,
        });
      }
      postHog.capture("Shareholder KYC Microflow: User entered", {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
      datadogRum.clearUser();
      datadogRum.setUser({
        id: data.loanApplicationId,
        loanApplicationId: data.loanApplicationId,
        distributionPartnerId: data.dp?.id,
        dpName: data.dp?.name,
      });
      datadogRum.addAction(`Shareholder KYC Microflow: User entered`, {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
    }
  }, [data]);

  useEffect(() => {
    if (kycInProgress) {
      postHog.capture("Shareholder KYC Microflow: Kyc In Progress", {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
      datadogRum.addAction(`Shareholder KYC Microflow: Kyc In Progress`, {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
    } else if (kycCompleted) {
      postHog.capture("Shareholder KYC Microflow: Kyc Completed", {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
      datadogRum.addAction(`Shareholder KYC Microflow: Kyc Completed`, {
        shareholderName: data.shareholderName,
        shareholderId: data.shareholderId,
        companyName: data.companyName,
        loanApplicationId: data.loanApplicationId,
        sessionId: data.sessionId,
        status: data.status,
      });
    }
  }, [kycInProgress, kycCompleted]);

  const handleStartVerification = async () => {
    postHog.capture("Shareholder KYC Microflow: Verification Started", {
      shareholderName: data.shareholderName,
      shareholderId: data.shareholderId,
      companyName: data.companyName,
      loanApplicationId: data.loanApplicationId,
      sessionId: data.sessionId,
      status: data.status,
    });
    datadogRum.addAction(`Shareholder KYC Microflow: Verification Started`, {
      shareholderName: data.shareholderName,
      shareholderId: data.shareholderId,
      companyName: data.companyName,
      loanApplicationId: data.loanApplicationId,
      sessionId: data.sessionId,
      status: data.status,
    });

    setKycInProgress(true);
    const {
      data: { kycAccessToken },
    } = await refetchAccessToken(data.sessionId);
    setAccessToken(kycAccessToken.token);
    setKycInProgress(false);
  };

  const handleSumsubResult = (type: any, payload: any) => {
    console.log("kyc type: ", type);
    console.log("kyc payload: ", payload);

    if (type === "idCheck.onApplicantStatusChanged") {
      setKycCompleted(true);
    }
  };

  const handleChange = () => {
    setAgreement(!agreement);
  };

  let renderKYCElement = useCallback(() => {
    if (kycInProgress) {
      return (
        <div className="cx-flex cx-items-center cx-justify-center cx-w-full cx-py-10">
          <div className="cx-animate-spin cx-w-8 cx-h-8 cx-text-brand-primary-regular">
            <ButtonLoaderIcon />
          </div>
        </div>
      );
    } else if (accessToken) {
      return (
        <div className="cx-flex cx-justify-center cx-items-center cx-w-full cx-min-w-[400px]">
          <SumsubWebSdk
            accessToken={accessToken}
            config={{
              lang: "en",
              country: "ARE",
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            expirationHandler={handleStartVerification}
            onMessage={handleSumsubResult}
            onError={(err: any) => console.log("onError", err)}
            className="cx-w-full"
          />
        </div>
      );
    } else if (data) {
      return (
        <LoanDetailsCard
          loanType={data?.loanType}
          loanConfig={data?.loanConfig}
          fixedTermLoan={data?.fixedTermLoan}
          invoiceDiscountingLoan={data?.invoiceDiscountingLoan}
          payableFinancingLoan={data?.payableFinancingLoan}
          revenueBasedLoan={data?.revenueBasedLoan}
          posRevenueBasedLoan={data?.posRevenueBasedLoan}
          buyNowPayLaterLoan={data?.buyNowPayLaterLoan}
        />
      );
    } else {
      return null;
    }
  }, [kycInProgress, accessToken, data]);

  const getTitle = useCallback(
    (data: Record<string, unknown>) => {
      return (
        LOAN_PRODUCT_DISPLAY_NAME[data?.loanType as LoanProductType] || "Loan"
      );
    },
    [data?.loanType]
  );

  if (loading || themeLoading) {
    return (
      <div className="cx-flex cx-flex-col cx-items-center cx-max-w-[960px] cx-w-full">
        <div className="cx-animate-spin cx-w-8 cx-h-8 cx-text-brand-primary-regular">
          <ButtonLoaderIcon />
        </div>
      </div>
    );
  }

  if (kycCompleted) {
    return <Success />;
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-max-w-[960px] cx-w-full">
      <div className="cx-font-bold cx-text-3xl cx-text-text-primary cx-mb-2">
        Hi {data.shareholderName}
      </div>
      <div className="cx-text-text-secondary cx-text-center">
        {`${data.applicantName} has applied for a loan on behalf of ${
          data.companyName
        }. 
        To process the application, we need to verify your identity using your Emirates ID${
          data.shareholderhasPoa
            ? " and biometric identification."
            : data.dp?.settings?.disableKYCLivenessForShareholders ?? false
            ? "."
            : " and biometric identification."
        }`}
      </div>
      <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mt-10 cx-mb-10">
        <div className="cx-text-2xl cx-font-bold">
          {toTitleCase(getTitle(data))} for {data.companyName}
        </div>
        <div className="cx-flex cx-flex-col sm:cx-flex-row cx-gap-x-10 cx-mt-6">
          {renderKYCElement()}
        </div>
      </div>

      {!accessToken && (
        <>
          <div className="cx-mt-6">
            <Checkbox
              label="By clicking Start verification, I hereby provide my consent for electronic collection of background information such as Credit Bureau data as part of the loan application process"
              inputProps={{ name: "agreeWithTerms" }}
              labelClassnames="cx-text-sm"
              checked={agreement}
              onChange={handleChange}
            />
          </div>
          <div className="cx-max-w-[400px] cx-w-full">
            <Button
              label="Start verification"
              disabled={!agreement}
              fullWidth
              onClick={() =>
                handleStartVerification().catch((err) => console.log(err))
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default KYCMicroflow;
