import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  CardList,
  Dropzone,
  StepNextButton,
} from "../../../components";
import { CardVariantType } from "../../../components/CardList/CardList";
import { StepperContext, useLoanApplication } from "../../../contexts";
import {
  LoanApplicationStage,
  MediaPurpose,
  MediaType,
} from "../../../hooks/api/types";
import {
  CustomFile,
  GENERIC_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
  GenericDocumentsUploadFormFields,
} from "./service";
import { ArrowLeftIcon, PdfIcon } from "../../../assets/icons";
import { useDpConfig } from "../../../contexts/dpConfig";
import { formatBytes } from "../../../utils";
import { useDeleteMedia, useMedia } from "../../../hooks";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";
const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

const GenericDocumentUpload = () => {
  const [genericDocuments, setGenericDocuments] = useState<CustomFile[]>([]);
  const [genericDocumentsIds, setGenericDocumentsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean[]>([]);
  const [isFailedUploadExist, setIsFailedUploadExist] =
    useState<boolean>(false);

  const {
    state,
    actions: { update },
  } = useLoanApplication();
  const { enableVendorScreen } = useLoanProductConfigFlags(state.loanType);

  const { nextStep, previousStep } = useContext(StepperContext);
  const {
    state: { settings },
  } = useDpConfig();
  const { disableBankStatementsUpload, showMerchantIdStep } =
    useLoanProductConfigFlags(state.loanType);

  const { upload, get } = useMedia();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();

  useEffect(() => {
    if (state.docGeneric.length && !state.docGenericFiles.length) {
      getMediaFromStatementIds(genericDocumentsIds);
    } else if (state.docGenericFiles.length)
      setGenericDocuments(state.docGenericFiles);
  }, [genericDocumentsIds]);

  useEffect(() => {
    let isUploadError = false;
    genericDocuments.forEach((genericDocument) => {
      if (genericDocument.error) isUploadError = true;
    });
    if (!isUploadError) setIsFailedUploadExist(false);
  }, [isFailedUploadExist, genericDocuments]);

  const { errors, touched, values, handleSubmit, validateForm, setErrors } =
    useFormik<GenericDocumentsUploadFormFields>({
      initialValues: {
        genericDocuments: [],
      },
      validationSchema: GENERIC_DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA,
      onSubmit: async () => {
        if (!values.genericDocuments.length) {
          if (genericDocuments.length) nextStep();
        }
        setUploading((prev) => {
          let next = [...prev];
          values.genericDocuments.forEach((item, index) => {
            next[index] = true;
          });
          return next;
        });
        setLoading(true);

        const genericDocumentPromises = values.genericDocuments.map((file) =>
          upload({
            file,
            type: MediaType.DOCUMENT,
            purpose: MediaPurpose.GENERIC_DOCUMENT,
            loanApplicationId: state.id,
          }).catch((err) => {
            file.error = "Upload failed";
            setIsFailedUploadExist(true);
            setLoading(false);
          })
        );

        const newGenericDocumentIds = await Promise.all(
          genericDocumentPromises
        );
        setGenericDocuments((prev) => {
          const next = prev.map((statement: any, index: number) => {
            if (!statement.id) {
              statement.id = newGenericDocumentIds[index];
            }
            return statement as CustomFile;
          });
          return next;
        });
        setUploading((prev) => {
          let next = [...prev];
          next = next.map(() => false);
          return next;
        });

        update({
          docGenericFiles: getFilesToUpdate(),
          ...(!showMerchantIdStep && {
            stage: settings.additionalData
              ? LoanApplicationStage.MORE_INFORMATION
              : enableVendorScreen
              ? LoanApplicationStage.VENDOR_INFORMATION
              : LoanApplicationStage.REVIEW,
          }),
        })
          .then(() => {
            setLoading(false);
            if (settings.additionalData && !isFailedUploadExist) {
              nextStep();
            } else {
              nextStep();
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });

  useEffect(() => {
    if (state.docGeneric) {
      setGenericDocumentsIds(state.docGeneric);
    }
  }, [state.docGeneric]);

  const getFilesToUpdate = () =>
    state.docGeneric.length
      ? [...state.docGenericFiles, ...values.genericDocuments]
      : [...genericDocuments];

  const getMediaFromStatementIds = (statementIds: string[]) => {
    const retreivedMedia: CustomFile[] = [];
    statementIds.forEach((statementId) => {
      get({ id: statementId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedMedia.push(file);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setGenericDocuments((prev) => [...retreivedMedia]);
          update({
            docGenericFiles: [...retreivedMedia],
          });
        });
    });
  };

  const handleUplaod = async (files: File[]) => {
    setErrors({});
    if (!files) return;

    const updatedGenericDocuments = [...values.genericDocuments];

    files.forEach((file: File) => {
      let allFilesUploaded = values.genericDocuments.concat(genericDocuments);
      let isFileAlreadyAdded = allFilesUploaded.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        setUploading((prev) => {
          const next = [...prev];
          next.push(false);
          return next;
        });
        updatedGenericDocuments.push(new CustomFile(file));
      }
    });
    values.genericDocuments = [...updatedGenericDocuments];
    state.docGenericFiles.length
      ? setGenericDocuments((prev) => [...updatedGenericDocuments, ...prev])
      : setGenericDocuments(updatedGenericDocuments);
  };

  const handleFileClose = async (fileName: string, id?: string) => {
    if (id) {
      deleteMediaById(id)
        .then((res) => {
          setGenericDocuments((prev) =>
            prev.filter(
              (genericDocument: CustomFile) => genericDocument.id !== id
            )
          );
          update({
            docGenericFiles: state.docGenericFiles.filter(
              (genericDocument) => genericDocument.id !== id
            ),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (fileName) {
      values.genericDocuments = values.genericDocuments.filter(
        (statement) => fileName !== statement.name
      );
      setGenericDocuments((prev) => {
        const next = prev.filter(
          (genericDocument) => fileName !== genericDocument.name
        );
        return next;
      });
      values.genericDocuments = values.genericDocuments.filter(
        (genericDocument) => fileName !== genericDocument.name
      );
      validateForm();
    }
  };

  const handleRetryUpload = (fileName: string) => {
    const file = values.genericDocuments.find(
      (genericDocument: File) => genericDocument.name === fileName
    );
    if (file) {
      let documentIndex: number;
      genericDocuments.forEach((genericDocument, index) => {
        if (genericDocument.name === fileName) documentIndex = index;
      });
      setUploading((prev) => {
        let next = [...prev];
        next = next.map((item, index) => {
          if (documentIndex === index) return true;
          else return false;
        });
        return next;
      });
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.GENERIC_DOCUMENT,
        loanApplicationId: state.id,
      })
        .then((res: string) => {
          setUploading((prev) => prev.map(() => false));
          values.genericDocuments = values.genericDocuments.filter(
            (genericDocument) => genericDocument.name !== fileName
          );
          setGenericDocuments((prev) => {
            let next = [...prev];
            next = next.map((genericDocument, index) => {
              if (documentIndex === index) {
                genericDocument.error = "";
                genericDocument.id = res;
                return genericDocument;
              } else {
                return genericDocument;
              }
            });
            return next;
          });
          update({
            docGenericFiles: [...state.docGenericFiles, file],
          });
        })
        .catch((err) => {
          setUploading((prev) => prev.map(() => false));
        });
    }
  };
  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-2">
        Upload Additional Financial Documents
      </div>
      <div className="cx-text-text-secondary cx-text-center cx-mb-10">
        You can add audit reports or other financial documents to support your
        application.
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mb-10">
          <Dropzone
            variant="simple"
            extensions={[
              "pdf",
              "png",
              "jpg",
              "jpeg",
              "Maximum file size is 50 MB",
            ]}
            options={{
              noDrag: false,
            }}
            onDrop={handleUplaod}
          />
        </div>
        <form onSubmit={handleSubmit}>
          {genericDocuments.map((genericDocument: CustomFile, index) => {
            return (
              <div key={index} className="cx-mb-2">
                <CardList
                  variant={
                    genericDocument.error
                      ? CardVariantType.UPLOAD_FAILED
                      : CardVariantType.UPLOADED
                  }
                  title={genericDocument.name}
                  description={
                    genericDocument.error
                      ? genericDocument.error
                      : touched.genericDocuments &&
                        values.genericDocuments.length &&
                        !!errors.genericDocuments?.[index]
                      ? (errors.genericDocuments[index] as string)
                      : formatBytes(genericDocument.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  handleFileClose={handleFileClose}
                  handleRetryUpload={handleRetryUpload}
                  error={
                    genericDocument.error
                      ? true
                      : touched.genericDocuments &&
                        !!errors.genericDocuments?.[index] &&
                        !!values.genericDocuments.length
                  }
                  uploading={uploading[index]}
                  documentId={genericDocument.id}
                  truncateTitleLength={35}
                  fileNewDesign={true}
                />
              </div>
            );
          })}
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-mt-10">
            <Button
              outlined
              label={
                <ArrowLeftIcon className="cx-mx-4 hover:cx-text-text-inverse" />
              }
              onClick={() => {
                if (
                  state.annualRevenue < VAT_LIMIT &&
                  disableBankStatementsUpload
                ) {
                  previousStep();
                  previousStep();
                } else {
                  previousStep();
                }
              }}
            />
            <StepNextButton
              label="Next"
              disabled={isFailedUploadExist ? true : false}
              loading={isFailedUploadExist ? false : loading}
              onClick={() => {
                if (
                  !values.genericDocuments.length &&
                  genericDocuments.length
                ) {
                }
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default GenericDocumentUpload;
