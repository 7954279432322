import countries from "world-countries";
import { CloseIcon, PenIcon } from "../../../assets/icons";
import React, { FC } from "react";
import { Vendor } from "../../../hooks/api/types";

type Props = {
  vendor: Vendor;
  onDelete: () => void;
  onEdit: () => void;
  className?: string;
};

const VendorCard: FC<Props> = ({ vendor, onDelete, onEdit, className }) => {
  return (
    <div
      key={vendor.vendorId}
      className={`cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-96 cx-max-h-[85px] cx-p-4 cx-border cx-border-stroke-primary cx-rounded-base cx-mb-4 ${className}`}
    >
      <div className={"cx-flex cx-flex-row cx-items-center"}>
        <div className={"cx-font-bold cx-text-2xl"}>
          {countries.find((country) => country.cca3 === vendor.country)?.flag}
        </div>
        <div className={"cx-flex cx-flex-col cx-ml-4 cx-justify-around"}>
          <div className={"cx-font-medium cx-text-base"}>{vendor.name}</div>
          {vendor.licenseNumber && (
            <div className={"cx-text-s cx-font-medium cx-text-text-secondary"}>
              {vendor.licenseNumber}
            </div>
          )}
          {vendor?.tradeLicenseDocumentIds?.length && (
            <div className={"cx-text-xs cx-font-medium cx-text-text-primary"}>
              View trade license
            </div>
          )}
        </div>
      </div>
      <div className={"cx-flex cx-flex-row cx-gap-3"}>
        <div
          className={
            "cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-primary cx-cursor-pointer hover:cx-bg-background-brand"
          }
          onClick={onEdit}
        >
          <PenIcon className="cx-text-text-brand group-hover:cx-text-text-inverse" />
        </div>
        <div
          className={
            "cx-group cx-w-8 cx-h-8 cx-flex cx-justify-center cx-items-center cx-rounded-full cx-border cx-border-stroke-error cx-cursor-pointer hover:cx-bg-background-error"
          }
          onClick={onDelete}
        >
          <CloseIcon className="cx-text-text-error group-hover:cx-text-text-inverse" />
        </div>
      </div>
    </div>
  );
};

export default VendorCard;
