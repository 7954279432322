import { Button, Combobox, InputField, Modal } from "../../../components";
import { FC, useCallback, useEffect, useState } from "react";
import { getVendorTitle } from "./utils";
import {
  LoanProductType,
  LoanVendorType,
  LogoDevBrand,
  MediaPurpose,
  MediaType,
  Vendor,
} from "../../../hooks/api/types";
import { useLoanApplication } from "../../../contexts";
import UploadMultipleDocs from "../BusinessInformation/UploadMultipleDocs";
import { FileObject } from "../BusinessInformation/service";
import countries from "world-countries";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useCreateVendor,
  useDeleteMedia,
  useMedia,
  useSearchBrand,
  useUpdateVendor,
} from "../../../hooks";
import { debounce } from "lodash";
import { CustomFile } from "../FinancialInformation/service";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddVendor: () => void;
  vendorState?: Vendor;
};

type VendorFormValues = {
  vendorName: string;
  country: string;
  tradeLicenseNumber?: string;
  tradeLicense?: FileObject[];
};

const VENDOR_INFORMATION_VALIDATION_SCHEMA = yup.object().shape({
  vendorName: yup.string().required("Please enter vendor name"),
  country: yup.string().required("Please select country"),
  tradeLicenseNumber: yup.string(),
});

const getCountryValue = (key: string) => {
  if (!key) return;
  const matchedCountry = countries?.find((country) => country.cca3 === key);
  return {
    value: matchedCountry?.cca3 || "",
    label: matchedCountry?.flag + " " + matchedCountry?.name.official,
  };
};

const VendorInformationModal: FC<Props> = ({
  isOpen,
  onClose,
  onAddVendor,
  vendorState,
}) => {
  const {
    state: { loanType, id: loanApplicationId },
  } = useLoanApplication();
  const { upload, get } = useMedia();
  const { mutateAsync: createVendor } = useCreateVendor();
  const { mutateAsync: updateVendor } = useUpdateVendor();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();

  const [vendorTradeLicense, setVendorTradeLicense] = useState<FileObject[]>(
    []
  );
  const [vendorList, setVendorList] = useState<Array<LogoDevBrand>>([]);
  const [vendorQuery, setVendorQuery] = useState<string>("");
  const { data, refetch } = useSearchBrand(vendorQuery);
  const [saving, setSaving] = useState(false);
  const [docsToDelete, setDocsToDelete] = useState<string[]>([]);

  const handleRemoveVendorTradeLicense = async (fileName: string) => {
    const removedDoc = vendorTradeLicense.find(
      (fileObj) => fileObj.file.name === fileName
    );
    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete([...docsToDelete, removedDoc.id]);
    }
    const updatedList = vendorTradeLicense.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setVendorTradeLicense(updatedList);
  };

  const getMediaFromDocumentIds = (documentIds: string[]) => {
    const retreivedDocs: FileObject[] = [];

    documentIds.forEach((documentId) => {
      get({ id: documentId })
        .then((res) => {
          const file = new CustomFile(
            new File([], res.media.fileName),
            res.media.id
          );
          Object.defineProperty(file, "size", {
            value: res.media.size,
          });
          retreivedDocs.push({ id: res.media.id, file, uploaded: true });
        })
        .catch((err) => {
          console.log(err);
        });
    });
    return retreivedDocs;
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    submitForm,
    resetForm,
  } = useFormik<VendorFormValues>({
    validationSchema: VENDOR_INFORMATION_VALIDATION_SCHEMA,
    initialValues: {
      vendorName: "",
      country: "",
      tradeLicenseNumber: "",
      tradeLicense: [],
    },
    onSubmit: async (values) => {
      try {
        setSaving(true);

        const docToDeletePromises = docsToDelete.map((docId) => {
          return deleteMediaById(docId);
        });

        const fileUploadPromises = vendorTradeLicense.map(async (doc) => {
          if (doc.uploaded) return doc.id;
          const id = await upload({
            file: doc.file,
            type: MediaType.DOCUMENT,
            purpose: MediaPurpose.VENDOR_TRADE_LICENSE,
            loanApplicationId: loanApplicationId,
          });
          doc.id = id;
          return id;
        });

        const savedDocs = await Promise.all(fileUploadPromises);
        await Promise.all(docToDeletePromises);

        const vendor = {
          name: values.vendorName,
          country: values.country,
          licenseNumber: values.tradeLicenseNumber,
          tradeLicenseDocumentIds: savedDocs.length ? savedDocs : undefined,
          type:
            loanType === LoanProductType.PAYABLE_FINANCING
              ? LoanVendorType.SUPPLIER
              : LoanVendorType.BUYER,
          loanApplicationId: loanApplicationId,
        };

        if (vendorState?.vendorId) {
          await updateVendor({
            ...vendor,
            loanVendorId: vendorState.loanVendorId,
          });
        } else {
          await createVendor(vendor);
        }
        onAddVendor();
        setSaving(false);
        handleClose();
      } catch (err) {
        setSaving(false);
      }
    },
  });

  const debouncedSetVendorQuery = useCallback(
    debounce((query: string) => {
      setVendorQuery(query);
    }, 1000), // Adjust debounce delay (in milliseconds) as needed
    []
  );

  useEffect(() => {
    const tradeLicenses = vendorState?.documents
      ?.filter((doc) => {
        return doc.documentPurpose === MediaPurpose.VENDOR_TRADE_LICENSE;
      })
      .map((doc) => {
        return doc.documentId;
      });

    if (tradeLicenses?.length) {
      const tradeLicenseMedia = getMediaFromDocumentIds(tradeLicenses);
      setVendorTradeLicense(tradeLicenseMedia);
    }
  }, [vendorState?.documents]);

  useEffect(() => {
    const dataArray = [...(data || [])];
    dataArray.push({ name: vendorQuery });
    setVendorList(dataArray);
  }, [data]);

  useEffect(() => {
    if (vendorQuery.trim()) {
      refetch(); // Fetch vendors based on the query
    }
  }, [vendorQuery]);

  useEffect(() => {
    if (vendorState && isOpen) {
      resetForm({
        values: {
          vendorName: vendorState.name,
          country: vendorState.country,
          tradeLicenseNumber: vendorState.licenseNumber,
          tradeLicense: [],
        },
      });
    } else {
      resetModal();
    }
  }, [vendorState, isOpen]);

  //reset all states to initial state
  const resetModal = () => {
    resetForm();
    setVendorTradeLicense([]);
    setVendorQuery("");
    setVendorList([]);
  };

  const handleClose = () => {
    resetModal();
    resetForm({
      values: {
        vendorName: "",
        country: "",
        tradeLicenseNumber: "",
        tradeLicense: [],
      },
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={`${getVendorTitle(loanType as LoanProductType)} details`}
    >
      <div className={"cx-min-w-[380px]"}>
        <form onSubmit={handleSubmit}>
          <div className={"cx-mb-6"}>
            <Combobox
              placeholder={
                getVendorTitle(loanType as LoanProductType) + " Name *"
              }
              options={vendorList.map((vendor) => {
                return {
                  value: vendor.name,
                  label: vendor.name,
                };
              })}
              error={
                touched.vendorName && errors.vendorName ? errors.vendorName : ""
              }
              onChange={handleChange("vendorName")}
              inputProps={{
                name: "vendorName",
                value: values.vendorName
                  ? {
                      value: values.vendorName,
                      label: values.vendorName,
                    }
                  : undefined,
                onInputChange: (query) => {
                  debouncedSetVendorQuery(query); // Update query for fetching options
                },
              }}
            />
          </div>
          <div className={"cx-mb-6"}>
            <Combobox
              placeholder={"Country of Registration *"}
              options={countries.map((country) => {
                return {
                  value: country.cca3,
                  label: country.flag + " " + country.name.official,
                };
              })}
              onChange={handleChange("country")}
              error={touched.country && errors.country ? errors.country : ""}
              inputProps={{
                name: "country",
                value: getCountryValue(values.country),
              }}
            />
          </div>
          <InputField
            label={"Trade license number"}
            optional={true}
            className={"cx-mb-6"}
            inputProps={{
              name: "tradeLicenseNumber",
              value: values.tradeLicenseNumber,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={
              touched.tradeLicenseNumber && errors.tradeLicenseNumber
                ? errors.tradeLicenseNumber
                : ""
            }
          />
          <UploadMultipleDocs
            label={"Trade License"}
            setDocuments={setVendorTradeLicense}
            documents={vendorTradeLicense}
            handleFileClose={handleRemoveVendorTradeLicense}
            type={MediaPurpose.VENDOR_TRADE_LICENSE}
          />
          <div className="cx-mt-6">
            <Button
              label={vendorState ? "Edit" : "Add"}
              fullWidth={true}
              type={"submit"}
              disabled={saving}
              loader={saving ? "right" : undefined}
              onClick={submitForm}
            />
            <Button
              label="Cancel"
              fullWidth={true}
              secondary={true}
              onClick={handleClose}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default VendorInformationModal;
